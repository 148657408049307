import Axios from "axios";
const CancelToken = Axios.CancelToken;
let cancelIntelApi;
const url = '/intel';

const intelApi = {
  post: (params) => {
    return Axios.post(`${url}`, params, {
      cancelToken: new CancelToken(function executor(c) {
        cancelIntelApi = c;
      })
    });
  }
};

export { cancelIntelApi, intelApi };