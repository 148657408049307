import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import FilterSection from './Subcomponents/FilterSection';
import { useSelector } from 'react-redux';
import filterArrayByKey from '../../../sharedComponents/Helper/filterArrayByKey';
import useRebates from './useRebates';

const RebatesFilters = (props) => {
  const { manufacturerGroup, categoryGroup, onClickSearch, onClickReset } = props;
  const searchM = useSelector(({ rebate }) => rebate.searchManufacturer);
  const searchC = useSelector(({ rebate }) => rebate.searchCategory);
  const checkM = useSelector(({ rebate }) => rebate.manufacturerChecked);
  const checkC = useSelector(({ rebate }) => rebate.categoryChecked);
  const { searchManufacturer, onCheckManufacturer, searchCategory, onCheckCategory } = useRebates.useFilter();
  const keysSearchM = [
    { key: 'name', search: searchM }
  ]
  const keysSearchC = [
    { key: 'name', search: searchC }
  ]
  const manufacturerOptions = filterArrayByKey(manufacturerGroup, keysSearchM).map(m => {
    return {
      id: m.id,
      value: checkM.some(mm => mm === m.id),
      label: m.name
    }
  });
  const categoryOptions = filterArrayByKey(categoryGroup, keysSearchC).map(c => {
    return {
      id: c.id,
      value: checkC.some(cc => cc === c.id),
      label: c.name
    }
  });
  const apply = () => {
    onClickSearch(checkM, checkC);
  }

  return (
    <Col className='bg-white flex-grow-1 p-0 position-relative d-flex flex-column justify-content-between --rebates-filters-height'>
      <div  className='px-4' style={{ height: 'calc(100vh - 86px)', overflowY: 'scroll' }}>
        <Row className='py-4'>
          <Col className='text-size-18 font-weight-bold line-height-38'>Filters</Col>
        </Row>
        <FilterSection title='Manufacturer' options={manufacturerOptions} search={searchManufacturer} onCheck={onCheckManufacturer} />
        <FilterSection title='Category' options={categoryOptions} search={searchCategory} onCheck={onCheckCategory} />
      </div>
      <Col className='py-4 d-flex bg-white'>
        <Col className=''>
          <Button outline block className='py-2 button-label' size='sm' color='beplMagenta' onClick={() => onClickReset()} >Reset</Button>
        </Col>
        <Col className=''>
          <Button block className='py-2 button-label' size='sm' color='beplMagenta' onClick={apply}>Apply</Button>
        </Col>
      </Col>
    </Col>
  );
};

export default RebatesFilters;