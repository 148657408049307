import { useSelector, useDispatch } from 'react-redux';
import { clearMerchants } from '../../reducers/IntelReducer/IntelActions';
import { setActiveDistributor } from '../../reducers/UserReducer/UserActions';

const useDistributor = () => {
  const dispatch = useDispatch();
  const activeDistributor = useSelector(({user}) => user.activeDistributor);
  const distributorsList = useSelector(({user}) => user.distributorsList);

  const selectActiveDistributor = (dist) => {
    dispatch(clearMerchants());
    dispatch(setActiveDistributor(dist.value));
  };

  return {
    activeDistributor: activeDistributor ? {
      label: activeDistributor.nickname,
      value: activeDistributor
    } : {
      label: 'Loading...',
      value: 0
    },
    distributorsList: distributorsList.map(d => {
      return {
        label: d.nickname,
        value: d
      };
    }),
    selectActiveDistributor,
    distributorShortName: activeDistributor ? activeDistributor.nickname.split(' ').slice(0, 1).join(' ') : ''
  };
};

export default useDistributor;