import React, {useEffect, useState} from 'react';
import { Col, Spinner } from 'reactstrap';

const REBATES_LOADING = 'Loading...'
const RebatesLoading = () => {
  let [messageStyle, setMessageStyle] = useState('translateY-100% opacity-0');
  useEffect(() => {
    let init = 'translateX-100%';
    let position = 'down';
    let interval = setInterval(() => {
      if(init !== 'translateY-0% opacity-100') {
        init = 'translateY-0% opacity-100';
      } else {
        if(position==='down'){
          position = 'up'
          init = '-translateY-100% opacity-0'
        } else {
          position = 'down'
          init = 'translateY-100% opacity-0'
        }
      }
      setMessageStyle(init);
    }, 4000)
    return () => clearInterval(interval);
  }, []);
  return (
    <Col className='text-center p-5'>
      <Spinner color='primary' />
      <Col className={`mt-3 text-center text-size-20 main-header transition-all ${messageStyle}`}>{REBATES_LOADING}</Col>
    </Col>
  )
}

export default RebatesLoading;
