import React from 'react';
import { Row, Col, Container, Button, Input } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import InputSearch from '../../../sharedComponents/InputSearch/InputSearch';
import useIntel from './useIntel';
import Select from 'react-select';
import { If, Then, Else } from 'react-if';
import { selectStyles } from '../../../config/constants';
import { MdFilterList } from 'react-icons/md';
import useDistributor from '../../../sharedComponents/useDistributor/useDistributor';
import { useSelector } from 'react-redux';
import useIntelList from './IntelList/useIntelList';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import numeral from 'numeral';
import { IoIosCodeWorking } from 'react-icons/io';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaCalendarAlt } from 'react-icons/fa'
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import AdminUserSelect from './AdminView/AdminUserSelect';

const IntelTop = () => {
  const {
    search, viewType, graphOrChartSortType, setGraphOrChartSortType, setViewType, goBackToMerchants, onApplySearch,
    goToCompetitivePurchases, goToDistributorPurchases,
    insideList, insideDetails, insideCompetitive,
    fromDate, setDateRange, toDate, sortBy, merchantName,
    goToDebugDetails
  } = useIntel.useTop();

  const {
    selectActiveDistributor, activeDistributor,
    distributorsList, distributorShortName
  } = useDistributor();

  let dateRange = moment(fromDate).format('MMM Do YYYY') + ' - ' + moment(toDate).format('MMM Do YYYY');
  const { filterMap } = useIntelList.useApi();

  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
  const intelLoaded = useSelector(({ intel }) => intel.allLoaded);
  const userId = useSelector(({ user }) => user.id);
  const userList = useSelector(({ user }) => user.userRol);
  const impersonate = useSelector(({ user }) => user.impersonateAs);

  const currentUser = userList.find((u) => u.id === userId);
  const currentUserRole = currentUser?.title;

  // Export the current view in CSV format and download it.
  // This functionality should only be available to admin accounts.
  const onExport = () => {
    const csvData = [];
    let fileName = '';

    if (!filterMap || filterMap.length === 0) {
      return;
    }

    // Header Row
    csvData.push([
      'Account Number',
      'Merchant',
      'Total Volume',
      'Competitor Volume',
      '% Competitor Volume'
    ]);

    filterMap.forEach(e => {
      csvData.push([
        e.dcn,
        e.merchant,
        numeral(e.totalVolume).format('0,0.00'),
        numeral(e.competitorVolume).format('0,0.00'),
        numeral(e.competitorVolumePerc).format('0%')
      ]);
    });

    fileName = 'Intel - For internal use only.csv';

    // Prepare the CSV data
    if (csvData.length > 0) {
      stringify(csvData, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  let distributorSelect = null;
  if (distributorsList && distributorsList.length === 1) {
    // Show a single distributor as a label
    distributorSelect =
      <Col xs={isMobile ? 12 : ''} className={isMobile ? 'p-2' : 'w-fc'}>
        <div className='main-header text-size-24'>{distributorsList[0].label}</div>
      </Col>
      ;
  }
  else {
    // Show multiple distributors as a select
    distributorSelect =
      <Col xs={isMobile ? 12 : 6} className={isMobile ? 'p-2' : ''}>
        <Select
          options={distributorsList}
          onChange={selectActiveDistributor}
          styles={selectStyles}
          value={activeDistributor}
        />
      </Col>
      ;
  }
  const setDateRangeHandler = (startDate, endDate) => {
    setDateRange({ fromDate: startDate._d, toDate: endDate._d });
    onApplySearch();
  }

  if (isMobile) {
    return (
      <Row className={'border-bottom bg-grayBg'} >
        <Col className='py-3 label-text text-size-14'>
          <If condition={!insideDetails}>
            <Then>
              <Row className='pb-2'>
                <Col className='pr-0'>
                  {distributorSelect}
                </Col>

              </Row>
            </Then>
            <Else>
              <Row className='pb-3'>
                <Col onClick={goToCompetitivePurchases} className={'border-bottom pb-3 text-center text-size-10 text-' + (insideCompetitive ? 'primary border-primary' : 'muted')}>Competitive Purchases</Col>
                <Col onClick={goToDistributorPurchases} className={'border-bottom pb-3 text-center text-size-10 text-' + (!insideCompetitive ? 'primary border-primary' : 'muted')}>{distributorShortName} Purchases</Col>
              </Row>
            </Else>
          </If>
          {currentUserRole === 'Admin' ?
            <Row className='pb-3 align-items-center'>
              <Col>
                <AdminUserSelect
                  userList={userList}
                  currentUser={currentUser}
                  impersonate={impersonate}
                />
              </Col>
            </Row> : null
          }
          <Row className='align-items-center'>
            <Col className={`pr-0 ${!insideList && !insideDetails ? 'mr-3' : ''}`}>
              <InputSearch {...search} />
            </Col>
            <If condition={insideList || insideDetails}>
              <Col className='mw-100px text-beplBlue' onClick={sortBy}>
                <MdFilterList size={20} /> SORT
              </Col>
            </If>
          </Row>
          <Row className='pt-2 align-items-center'>
            <Col xs={12} className=''>
              {/* <Row className='mr-0 align-items-center no-gutters'> */}
              <DateRangePicker
                onCallback={setDateRangeHandler}
                initialSettings={{ startDate: fromDate, endDate: toDate }}
              >
                <div className='position-relative'>
                  <Input readOnly type="text" value={dateRange} className="form-control" style={{ paddingLeft: '35px', backgroundColor: 'white' }} />
                  <FaCalendarAlt className="position-absolute-center-start" size="15px" />
                  <IoMdArrowDropdown className="position-absolute-center-end bg-white" size="25px" />
                </div>

              </DateRangePicker>
            </Col>
          </Row>
          <Row className='pt-2 align-items-center'>
            <Col xs={12} className=''>
              <Select
                options={[{ value: 'list', label: 'View: List' }, { value: 'graph', label: 'View: Graph' }, { value: 'linechart', label: 'View: Line Chart' }]}
                onChange={(type) => setViewType(type)}
                styles={selectStyles}
                value={viewType}
              />
            </Col>
          </Row>
        </Col>
      </Row >
    );
  }

  return (
    <Container fluid>
      <Row className='label-text text-size-14 d-flex align-items-center' style={{ paddingTop: 32, paddingBottom: 6 }}>
        <Col>
          <Row className='align-items-center'>
            <If condition={insideDetails}>
              <Then>
                <Col className='text-size-24 main-header'>
                  {merchantName}
                  {isInternalAdmin ? <IoIosCodeWorking className='ml-2 clickable text-size-18' onClick={goToDebugDetails} /> : null}
                </Col>
                <Col className={'w-fc border-right text-size-16 pb-1 clickable text-' + (insideCompetitive ? 'beplMagenta font-weight-bold' : 'beplBlue')} onClick={goToCompetitivePurchases}>
                  Competitive Purchases
                </Col>
                <Col className={'w-fc border-left text-size-16 pb-1 clickable text-' + (!insideCompetitive ? 'beplMagenta font-weight-bold' : 'beplBlue')} onClick={goToDistributorPurchases}>
                  {distributorShortName} Purchases
                </Col>
              </Then>
              <Else>
                <Row className='px-3 w-100'>
                  {distributorSelect}
                </Row>
              </Else>
            </If>
          </Row>
        </Col>
        <If condition={!insideDetails && isInternalAdmin}>
          <Col>
            <Button
              disabled={!intelLoaded}
              className='py-2 button-label text-right float-right'
              size='sm'
              onClick={onExport}
              color='beplMagenta'
              style={{ padding: '8px 20px' }}>
              Export Page Details
            </Button>
          </Col>
        </If>
        <If condition={insideDetails}>
          <Col>
            <Button
              className='py-2 button-label text-right float-right'
              size='sm'
              onClick={goBackToMerchants}
              color='beplMagenta'>
              Return to All Merchants
            </Button>
          </Col>
        </If>
      </Row>
      {currentUserRole === 'Admin' ?
        <Row className='label-text text-size-14 d-flex align-items-center'>
          <Col xs={3}>
            <AdminUserSelect
              userList={userList}
              currentUser={currentUser}
              impersonate={impersonate}
            />
          </Col>
        </Row> : null
      }
      <Row className='label-text text-size-14 d-flex align-items-center justify-content-between w-full' style={{ paddingTop: 6, paddingBottom: 16 }}>
        <Col xs={6} className="p-0 m-0 d-flex">
          <Col xs={viewType.value === 'graph' || viewType.value === 'linechart' ? 4 : 6} className='border-right'>
            <InputSearch {...search} />
          </Col>


          <Col xs={viewType.value === 'graph' || viewType.value === 'linechart' ? 4 : 6}>
            <DateRangePicker
              onCallback={setDateRangeHandler}
              initialSettings={{ startDate: fromDate, endDate: toDate }}
            >
              <div className='position-relative'>
                <Input readOnly type="text" value={dateRange} className="form-control" style={{ paddingLeft: '35px', backgroundColor: 'white' }} />
                <FaCalendarAlt className="position-absolute-center-start" size="15px" style={{ 'zIndex': 0 }} />
                <IoMdArrowDropdown className="position-absolute-center-end bg-white" size="25px" style={{ 'zIndex': 0 }} />
              </div>

            </DateRangePicker>
          </Col>
          <If condition={viewType.value === 'graph' || viewType.value === 'linechart'}>
            <Col xs={4}>
              <Select
                options={[{ value: 'totalVolume', label: 'Sort By: Total Volume' }, { value: 'competitorVolume', label: 'Sort By: Competitor Volume' },
                { value: 'merchant', label: 'Sort By: Customer Name' }, { value: 'dcn', label: 'Sort By: Account Number' }]}
                onChange={(type) => setGraphOrChartSortType(type)}
                styles={selectStyles}
                value={graphOrChartSortType}
              />
            </Col>
          </If>

        </Col>

        {!insideDetails &&
          <Col xs={2} style={{zIndex: 1100}}>
            <Select
              options={[{ value: 'list', label: 'View: List' }, { value: 'graph', label: 'View: Graph' }, { value: 'linechart', label: 'View: Line Chart' }]}
              onChange={(type) => setViewType(type)}
              styles={selectStyles}
              value={viewType}
            />
          </Col>

          // <Fragment>
          //   <Col className={'w-fc border-right text-right clickable text-' + (insideList ? 'beplMagenta' : 'beplBlue')} title='List View'>
          //     <BsList color='inherit' size={25} onClick={goToList} />&nbsp;
          //   </Col>
          //   <Col className={'w-fc border-left text-right clickable text-' + (insideGraph ? 'beplMagenta' : 'beplBlue')} title='Graph View'>
          //     <BsGrid3X3Gap color='inherit' size={25} onClick={goToGraph} />
          //   </Col>
          // </Fragment>
        }


      </Row>
    </Container>
  );
};

export default IntelTop;