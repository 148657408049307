import React, { useState } from 'react';
import { Row, Col, Input, Button, FormText, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import useCreatePasswordForm from './useCreatePasswordForm';
import { useHistory } from 'react-router-dom';

const LoginForm = () => {
  const { passwordRepeat, password, validate } = useCreatePasswordForm();
  const [apiError, setApiError] = useState('');
  const history = useHistory();

  const save = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      setApiError('');
    }
  };

  const goToLogin = () => {
    history.push('/login');
  };

  return (
    <Form onSubmit={save}>
      <FormGroup>
        <Label className='label-text-header' for='password'>Create Password *</Label>
        <Input {...password} type='password' />
        <FormFeedback>{password.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header' for='passwordRepeat'>Confirm Password *</Label>
        <Input {...passwordRepeat} type='password' />
        <FormFeedback>{passwordRepeat.message}</FormFeedback>
      </FormGroup>
      <Button type='submit' block color='beplMagenta' className='py-2'>SAVE</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Row className='mb-5'>
        <Col onClick={goToLogin} className='text-beplMagenta text-center clickable'>Back to Login</Col>
      </Row>
    </Form>
  );
};

export default LoginForm;