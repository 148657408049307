import React from 'react';
import { If, Then, Else } from 'react-if';
import { useSelector } from 'react-redux';
import SpinnerBounce from './SpinnerBounce';

const Spinner = () => {
  const spinner = useSelector(({ui}) => ui.spinner);

  return (
    <If condition={spinner.state > 0}>
      <Then>
        <div className="mask">
          {spinner.showGraphic ? <SpinnerBounce /> : false}
        </div>
      </Then>
      <Else>
        <div className="mask hide">
          <div className="spinner">                            
          </div>
        </div>
      </Else>
    </If>
  );
};

export default Spinner;