import React, { useEffect, useState } from 'react';
import Tooltip from 'reactstrap/lib/Tooltip';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const TopBarLinks = (props) => {
  const menuExpanded = useSelector(({ ui }) => ui.menuExpanded);
  const [text, showText] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  let Icon = props.icon1(13);

  useEffect(() => {
    if (menuExpanded) {
      setTimeout(() => {
        showText(true);
      }, 300)
    } else {
      showText(false);
    }
  }, [menuExpanded]);

  return (
    <Col id={'tooltip-' + props.id} className={'responsive-sidebar-icon m-0 d-flex align-items-center position-relative' + (props.isActive ? ' bg-beplMagenta ' : ' ') +
      (menuExpanded ? 'justify-content-start' : 'justify-content-center')}
      onClick={props.onClick} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter}>
      <div className={menuExpanded && !text ? 'disappear' : 'slide-appear'} style={{ height: '24px', display: 'flex', alignItems: 'center'}}>
        <Icon />
      </div>
      {!props.isActive ? <span ><props.icon2 className='position-absolute right-0' /></span> : null}
      {text ? <span className={`slide-appear top-nav-label ml-3  text-size-16`}>{props.title}</span> : false}
      {!menuExpanded ? <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={'tooltip-' + props.id}
        toggle={toggle}>
        {props.title}
      </Tooltip> : false }
    </Col>
  );
};

TopBarLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon1: PropTypes.func.isRequired,
  icon2: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  showSeparation: PropTypes.bool,
};

TopBarLinks.defaultProps = {
  isActive: false,
  showSeparation: false,
};

export default TopBarLinks;