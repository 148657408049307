import React from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, CardBody } from 'reactstrap';

const NoDataRow = ({message}) => {
  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='text-capitalize font-weight-bold text-center'>
            {message || 'No Data Found'}
          </Col>
        </Row>
      </CardBody>
    );
  }

  return (
    <CardBody className='border-top'>
      <Row className='align-items-center'>
        <Col className='text-capitalize text-center text-gray'>
          {message || 'No Data Found'}
        </Col>
      </Row>
    </CardBody>
  )
};

export default NoDataRow;