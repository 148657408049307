import React, {useState} from 'react';
import useModal from '../../../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Input, Spinner} from 'reactstrap';
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';
import matchApi from '../../../../../api/matchApi';

const MatchFeedbackModal = ({ id, feedbackType, min, badMatchMin }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });
  const maxHeight = (isMobileOnly ? '450px' : (isTablet ? '800px' : '400px'));
  const badMatch = feedbackType === 'badMatch';
  const [comment, setComment] = useState('');
  const [recommendedMin, setRecommendedMin] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');

  const sendFeedback = async () => {
    let params = comment.length > 0 ? {
      min,
      badMatchMin,
      comment,
    } : 
    {
      min,
      recommendedMin
    }
    setSending(true);
    try {
      await matchApi.feedback.post(params);
      setSending(false);
      modalProps.toggle();
    } catch(err) {
      console.log(err);
      setSending(false);
      setError('Failed to send feedback.')
    }
  }

  return (
    <Modal  {...modalProps}>
      <ModalHeader {...headerProps} >Match Feedback</ModalHeader>
      <ModalBody className={isMobile ? 'px-2' : ''} >
        <Col className="p-0 text-gray text-size-14" style={{ maxHeight: maxHeight, overflowX: 'hidden', overflowY: 'auto' }}>
          {badMatch ?
            "Help us provide better suggestions by letting us know why this isn't a good match." :
            "To recommend a different or new match, add the manufacturer ID and click \"Send\"."}
          {badMatch ?
            <Input value={comment} onChange={(e) => setComment(e.target.value)} placeholder={'What\'s wrong with this match?'}
              className="mt-2" type={'textarea'} /> :
            <Input value={recommendedMin} onChange={(e) => setRecommendedMin(e.target.value)} placeholder={'Manufacturer ID'}
              className="mt-2" type={'text'} />}
              {error.length > 0 ? <div className='text-danger text-size-12'>{error}</div> : false}
        </Col>
      </ModalBody>
      <ModalFooter className='justify-content-end'>
        <Row >
            <Button className='py-2 button-label mr-2 w-60px' size='sm' onClick={sendFeedback} color='beplMagenta' >
              {sending ? <Spinner size="sm" /> : 'Send'}
            </Button>
          <Button onClick={modalProps.toggle} className='py-2 button-label' size='sm' color='beplMagenta'>Cancel</Button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default MatchFeedbackModal;