
export const clearSearch = () => {
  return {
    type: 'CLEAR_REBATE_SEARCH',
  };
};

export const clearRebateData = () => {
  return {
    type: 'CLEAR_REBATE_DATA',
  };
};

export const setSearch = (search) => {
  return {
    type: 'SET_REBATE_SEARCH',
    payload: search
  };
};

export const setRebateData = (data) => {
  return {
    type: 'SET_REBATE_DATA',
    payload: {
      data: data
    }
  }
};

export const clearSearchManufacturer = () => {
  return {
    type: 'CLEAR_SEARCH_MANUFACTURER',
  };
};

export const setSearchManufacturer = (search) => {
  return {
    type: 'SET_SEARCH_MANUFACTURER',
    payload: search
  };
};

export const setManufacturerData = (data) => {
  return {
    type: 'SET_MANUFACTURER_DATA',
    payload: {
      data: data
    }
  }
};

export const setManufacturerChecked = (id) => {
  return {
    type: 'SET_MANUFACTURER_CHECKED',
    payload: id
  };
};

export const clearSearchCategory = () => {
  return {
    type: 'CLEAR_SEARCH_CATEGORY',
  };
};

export const setSearchCategory = (search) => {
  return {
    type: 'SET_SEARCH_CATEGORY',
    payload: search
  };
};

export const setCategoryData = (data) => {
  return {
    type: 'SET_CATEGORY_DATA',
    payload: {
      data: data
    }
  }
};

export const setCategoryChecked = (id) => {
  return {
    type: 'SET_CATEGORY_CHECKED',
    payload: id
  };
};

export const resetRebateFilters = () => {
  return {
    type: 'RESET_REBATE_FILTERS',
  };
};

export const clearRebateView = () => {
  return {
    type: 'CLEAR_REBATE_VIEW',
  };
};

export const setRebateView = (data) => {
  return {
    type: 'SET_REBATE_VIEW',
    payload: {
      data: data
    }
  }
};

export const setFiltersLoaded = (data) => {
  return {
    type: 'SET_FILTERS_LOADED',
    payload: {
      data: data
    }
  }
};

export const clearFilters = () => {
  return {
    type: 'CLEAR_FILTERS'
  };
}

export const clearAllRebatesData = () => {
  return {
    type: 'CLEAR_ALL_REBATES_DATA'
  }
}

export const setRebateListSortBy = (key, order) => {
  return {
    type: 'SET_REBATE_LIST_SORT_BY',
    payload: {
      key: key,
      order
    }
  };
}

export const itemMatchesLoading = (bool) => {
  return {
    type: 'ITEM_MATCHES_LOADING',
    payload: bool
  };
}

export const setItemMatchesForAllItems = (min, manufacturerPlatformId, data) => {
  return {
    type: 'ITEM_MATCHES_FOR_ALL_ITEMS',
    payload: {
      min: min,
      manufacturerPlatformId: manufacturerPlatformId,
      data: data
    }
  };
}

export const itemMatchesLoadingComplete = () => {
  return {
    type: 'ITEM_MATCHES_LOADING_COMPLETE'
  };
}

export const filtersLoadFailed = () => {
  return {
    type: 'FILTERS_LOAD_FAILED'
  }
}

export const setRebateViewType = (type) => {
  return {
    type: 'SET_REBATE_VIEW_TYPE',
    payload: type
  }
}

export const setAllRebateData = (data) => {
  return {
    type: 'SET_ALL_REBATE_DATA',
    payload: {
      data: data
    }
  }
};

export const setAllRebateLoading = (bool) => {
  return {
    type: 'SET_ALL_REBATE_LOADING',
    payload: bool
  }
};


export const clearAllRebateData = () => {
  return {
    type: 'CLEAR_ALL_REBATE_DATA',
  };
};


export const setFilteredSortedItemMatches = (payload) => {
  return {
    type: 'SET_FILTERED_SORTED_ITEM_MATCHES',
    payload
  };
}

export const setItemMatches = (payload) => {
  return {
    type: 'SET_ITEM_MATCHES',
    payload
  }
}


export const setItemMatchesSort = (key, order) => {
  return {
    type: 'SET_ITEM_MATCHES_SORT',
    payload: {
      key: key,
      order
    }
  };
}

export const setScrollYPos = (pos) => {
  return {
    type: 'SET_Y_SCROLL_POS',
    payload: pos
  }
}

export const setRebateLoadStatus = (status) => {
  return {
    type: 'SET_REBATE_LOAD_STATUS',
    payload: status
  }
}

export const setAllRebateLoadStatus = (status) => {
  return {
    type: 'SET_ALL_REBATE_LOAD_STATUS',
    payload: status
  }
}

export const setAllRebatePageCount = (page) => {
  return {
    type: 'SET_ALL_REBATE_PAGE_COUNT',
    payload: page
  }
}