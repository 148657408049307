import Axios from "axios";

const url='/user';

const userApi = {
  get: () => {
    return Axios.get(`${url}/all`);
  },
  changePassword: {
    post: (params) => {
      return Axios.post(`${url}/change-password`, params);
    }
  },
  merchantBatch: {
    patch: (id, params) => {
      return Axios.patch(`${url}/merchant-batch/${id}`, params);
    },
  },
  patch: (body) => {
    return Axios.patch(`${url}`, body);
  },
  group: {
    get: (parent_id) => {
      return Axios.get(`${url}/group/${parent_id}`);
    }
  },
  userInvite: {
    get: () => {
      return Axios.get(`${url}`);
    },
    post: (params) => {
      return Axios.post(`${url}/invite`, params);
    },
    patch: (id, params) => {
      return Axios.patch(`${url}/${id}`, params);
    },
    delete: (id) => {
      return Axios.delete(`${url}/${id}`);
    }
  },
  currentDistributor: {
    get: () => {
      return Axios.get(`${url}/current-distributor`);
    }
  },
  activeDistributor: (distributorId) => {
    const post = (params) => {
      return Axios.post(`${url}/active-distributor/${distributorId}`, params);
    };

    return {
      post
    };
  }
};

export default userApi