import React, { useEffect } from "react";
import { Card, Row, Spinner, Col, CardBody, Button, Input } from 'reactstrap';
import { If } from 'react-if'
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { showModal } from "../../../../../reducers/ModalsReducer/ModalsActions";
import MatchFeedbackModal from "../Modals/ItemMatchesFeedback";
import CompetitiveInsightItemMatchesSortHeader from "./CompetitiveInsightItemMatchesSortHeader";
import CompetitiveInsightItemMatchesRow from "./CompetitiveInsightItemMatchesRow";
import useItemMatchesData from "./useItemMatchesData";
import { BsSearch } from "react-icons/bs";
import { colors } from "../../../../../config/constants";

const CompetitiveInsightItemMatchesView = () => {
  const itemMatches = useSelector(({ intel }) => intel.filteredAndSortedItemMatches);
  const itemMatchesLoading = useSelector(({ intel }) => intel.itemMatchesLoading);
  const { getItemMatches, search, setSearch } = useItemMatchesData.useApi();
  const { exportItemMatchesList } = useItemMatchesData.useCsvExport();
  const dispatch = useDispatch();
  const { min, merchantId, manufacturerPlatId, itemName } = useParams();
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);

  useEffect(() => {
    getItemMatches(min, manufacturerPlatId, merchantId, itemName);
    // eslint-disable-next-line
  }, []);

 

  const showMatchFeedbackModal = () => {
    dispatch(showModal(MatchFeedbackModal, { feedbackType: 'recommendation', min }));
  }

  const itemMatchesMap = itemMatches.length > 0 ? itemMatches.map((match, ind) => {
    return <CompetitiveInsightItemMatchesRow key={match.min + ind} match={match} />
  }) : <Row className="py-5 d-flex justify-content-center">There are no similar items for this item</Row>

  if (itemMatchesLoading === 'complete') {
    return (
      <>
        <CardBody className="px-3 pt-1">
          <Row className={`pl-0 main-header text-size-20 d-flex align-items-center`} style={{ marginTop: 16, paddingBottom: 12, paddingLeft: 15 }}>
            {itemName ? 'Similar Items to ' + itemName : ''}
          </Row>
          <Row>
            <Col className={`text-size-18  align-items-end justify-content-between px-0 ${isMobile ? '' : 'd-flex flex-row'}`}>
              <Col className={`px-0`} xs={isMobile ? 12 : 3}>
                <Input value={search} disabled={!itemMatchesLoading === 'loading'} onChange={(e) => setSearch(e.target.value)} placeholder="Search Similar Items" />
              </Col>
              <Col className={`px-0 justify-content-end ${isMobile ? 'mt-2' : 'd-flex'}`}>
                <Button type="button" disabled={!itemMatchesLoading === 'loading'} onClick={showMatchFeedbackModal} color="beplMagenta" className={`${isMobile ? 'w-100 mb-2' : ''} mr-2 py-2 px-2 btn btn-beplMagenta btn-sm button-label`}>+ Recommend Similar Item</Button>
                <If condition={isInternalAdmin}>
                  <Button type="button" disabled={!itemMatchesLoading === 'loading'} onClick={exportItemMatchesList} color="beplMagenta" className="py-2 px-2 btn btn-beplMagenta btn-sm button-label" style={{ float: 'right' }}>Export Page Details</Button>
                </If>
              </Col>
            </Col>
          </Row>
        </CardBody>
        {!isMobile && <CompetitiveInsightItemMatchesSortHeader />}
        <Card className="mb-5">
          {itemMatchesMap}
        </Card>
      </>
    );
  } else if (itemMatchesLoading === 'failed') {
    return (
      <Row style={{ position: 'relative' }}>
      <Col xs={12} className='text-center p-5'>
        <BsSearch color={colors.beplMagenta} size={45} />
        <div className='mt-3 main-header text-center'>Failed to load,</div>
        <div className='mt-3 main-header text-center'>Try Refreshing.</div>
      </Col>
    </Row>
    )

  } else {
    return (
      <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>
    )
  }

}

export default CompetitiveInsightItemMatchesView;