import { useDispatch } from 'react-redux';
import { showModal } from '../../../../reducers/ModalsReducer/ModalsActions';
import { spinner } from '../../../../reducers/UIReducer/UIActions';
import Joi from '@hapi/joi';
import userApi from '../../../../api/userApi';
import useForm from '../../../../sharedComponents/useForm/useForm';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import AlertModal from '../../../../sharedComponents/AlertModal/AlertModal';

const initialState = {
  currentPassword: '',
  password: '',
  passwordRepeat: ''
};
const reducer = (state, action) => {
  return {
    ...state,
    [action.field] : action.value
  };
};

const useApi = () => {
  const dispatch = useDispatch();
  const changePassword = (oPassword) => {
    const errors = oPassword.validate();
    if (!errors) {
      const params = {
        password: oPassword.currentPassword,
        newPassword: oPassword.password,
        repeatPassword: oPassword.passwordRepeat
      };
      dispatch(spinner.add());
      userApi.changePassword.post(params).then(({data}) => {
        dispatch(spinner.subtract());
        dispatch(showModal(AlertModal, { title: `Confirmation` , message: `Password changed successfully`, buttonText: `GOT IT`}));
      })
      .catch(err => { 
        dispatch(spinner.subtract());
        let msg = `Something went wrong`
        if (err) {
          if (err.data && err.data.message) {
            msg = err.data.message;
          }
          dispatch(showModal(AlertModal, {title: msg }));
          msg = err.data.message;
        }
      })
    }
  }

  const clickChangePassword = () => {
    dispatch(showModal(ChangePasswordModal, ''));
  }

   return { changePassword, clickChangePassword }
}

const schema = Joi.object({
  currentPassword: Joi.string().min(3).max(20).required().label('Current Password'),
  password: Joi.string().min(3).max(20).required().label('Password'),
  passwordRepeat: Joi.string().valid(Joi.ref('password')).messages({'any.only': `Passwords don't match`})
});

const useFormChangePassword = () => {
  const { form, validate } = useForm(reducer, initialState, schema);
  return {
    ...form,
    validate
  }
}

export default {
  useApi,
  useFormChangePassword
} 
