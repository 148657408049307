import Axios from 'axios';

const url = '/analytics';

const analyticsApi = {
  userSessions: {
    post: (params) => {
      return Axios.post(`${url}/user-sessions`, params);
    },
    get: (params) => {
      return Axios.get(`${url}/user-sessions`, { params });
    }
  }
}

export default analyticsApi;
