import React, {useEffect, useState} from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { showModal } from "../../reducers/ModalsReducer/ModalsActions";
import { useDispatch } from 'react-redux';
import useAdminPanel from './useAdminPanel';
import AdminPanelUserForm from "./AdminPanelUserForm";
import AnnouncementModal from "./AnnouncementModal";
import { useHistory } from "react-router-dom";
import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";
// Usage:
// 1.) Log in as admin
// 2.) On Admin Login screen, manually navigate to /admin-panel
// 3.) Select a user to edit (see AdminLogin/AdminLoginForm)
// 4.) Show user information
// 5.) Update user information (email, contact name, password)
const AdminPanel = () => {
  const history = useHistory();
  const adminLoginFormProps = useAdminPanel();
  const dispatch = useDispatch();
  const [testingEnabled, setTestingEnabled] = useState(false)
  const onEdit = () => {
    dispatch(showModal(AnnouncementModal, {}));
  }

  const enableOrDisableTesting = () => {
    let currentAdminData = LocalStorage.adminUserData.get();
    setTestingEnabled(currentAdminData.testingEnabled ? false : true)
    currentAdminData.testingEnabled = currentAdminData.testingEnabled ? false : true;
    LocalStorage.adminUserData.set(currentAdminData);
  }
  
  useEffect(() => {
    let currentAdminData = LocalStorage.adminUserData.get();
    if(currentAdminData) {
      if(currentAdminData.testingEnabled) {
        setTestingEnabled(true);
      }
    }
  }, [])

  return (
    <Container fluid className='flex-grow-1'>
      <Row className='justify-content-center'>
        <Col style={{ maxWidth: '400px' }}>
          <Row className='py-5'>
            <Col className='text-center text-size-24 main-header'>
              Platform Distributor Portal Admin Panel
            </Col>
          </Row>
          <Row className='pb-3'>
            <Col>
              <Button
                outline
                onClick={onEdit}
                block
                color='beplMagenta'
                className='py-2 button-label'>
                Edit Announcements
              </Button>
            </Col>
          </Row>
          <Row className='pb-3'>
            <Col>
              <Button
                outline
                onClick={enableOrDisableTesting}
                block
                color='beplMagenta'
                className='py-2 button-label'>
                {testingEnabled ? 'Disable Testing' : 'Enable Testing'}
              </Button>
            </Col>
          </Row>
          <Row className='pb-3'>
            <Col>
              <Button
                outline
                onClick={() => history.push('/admin-login')}
                block
                color='beplMagenta'
                className='py-2 button-label'>
                Back To Login
              </Button>
            </Col>
          </Row>
          <Row className='pb-3'>
            <Col>
              <Button
                outline
                onClick={adminLoginFormProps.onLogout}
                block
                color='beplMagenta'
                className='py-2 button-label'>
                Log Out
              </Button>
            </Col>
          </Row>
          <AdminPanelUserForm {...adminLoginFormProps} />
        </Col>
      </Row>
    </Container>
  );
}

export default AdminPanel;