import React from "react";
import { Form, FormGroup, Label, Input, Button, CustomInput, Col, Row } from 'reactstrap';
import Select from 'react-select';
import { selectStyles } from '../../config/constants';
import secondsToReadableFormat from "../../sharedComponents/Helper/secondsToReadableFormat";


const AdminPanelUserForm = ({ selectProps, editUser, patchUser, changeUserPassword, setUserEmail, setUserContactName, 
  setUserPassword, toggleUserDisplayInvoice, toggleUserMultiHouse, toggleGroupUser, groupUsers, setSelectedUsersForGroup, selectedUsersForGroup, analytics }) => {
  const userEditForm = editUser ?
    <>
      <Form className="text-size-14" onSubmit={patchUser}>
        <FormGroup>
          <Label for="userEmail">Email</Label>
          <Input
            type="email"
            name="email"
            id="userEmail"
            value={editUser.email}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="userContactName">Contact Name</Label>
          <Input
            type="text"
            name="contactName"
            id="userContactName"
            value={editUser.contactName}
            onChange={(e) => setUserContactName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          Note: The user must log out & in again to see changes from below options.
          <CustomInput
            id="userDisplayInvoice"
            label="Display Invoice Tab"
            type='checkbox'
            checked={editUser.displayInvoice}
            onChange={() => toggleUserDisplayInvoice()}
          />
          <CustomInput
            id="userMultiHouse"
            label="Multi House"
            type='checkbox'
            checked={editUser.isMultiHouse}
            onChange={() => toggleUserMultiHouse()}
          />
          <CustomInput
            id="groupUser"
            label="Group User"
            type='checkbox'
            checked={editUser.isGroup}
            onChange={() => toggleGroupUser()}
          />
          {editUser && editUser.isGroup ?
            <Select
              // isOptionDisabled={(option) => disableOption(option)}
              isMulti
              placeholder='All Users'
              options={groupUsers}
              onChange={setSelectedUsersForGroup}
              styles={selectStyles}
              value={selectedUsersForGroup}
              className={'w-100 mt-1'}
            />
            : false}
            {analytics ? <Col className="m-0 p-0">
            <Row className="m-0 p-0">{`Sessions (last 30 days): ${analytics.numOfSessions}`}</Row>
            <Row className="m-0 p-0">{`Total Time Online: ${secondsToReadableFormat(analytics.totalTime)}`}</Row>
            </Col>: false}
         
        </FormGroup>
        <Button
          type='submit'
          block
          color='beplMagenta'
          className='py-2 button-label'
        >
          Update User
        </Button>
      </Form>
      <Form className="text-size-14" onSubmit={changeUserPassword}>
        <FormGroup className='pt-4'>
          <Label for="userPassword">Password</Label>
          <Input
            type="text"
            name="password"
            id="userPassword"
            value={editUser.password}
            onChange={(e) => setUserPassword(e.target.value)}
          />
        </FormGroup>
        <Button
          type='submit'
          block
          color='beplMagenta'
          className='py-2 button-label'
        >
          Reset Password
        </Button>
      </Form>
    </>
    :
    null;

  return (
    <>
      <Form className="text-size-14">
        <FormGroup>
          <Select name='user' styles={selectStyles} {...selectProps} />
        </FormGroup>
      </Form>
      {userEditForm}
    </>
  );
}

export default AdminPanelUserForm;