
export const setMerchants = (merchants) => {
  return {
    type: 'SET_MERCHANTS',
    payload: merchants
  }
}

export const setMerchantsWithChecks = (merchants) => {
  return {
    type: 'SET_MERCHANTS_WITH_CHECKS',
    payload: merchants
  }
}


export const setSortBy = (view, sortBy, order) => {
  return {
    type: 'SET_REBATE_CHECKS_SORT',
    payload: { view, sortBy, order }
  }
}


export const setCheckTimeframe = (timeframe) => {
  return {
    type: 'SET_TIMEFRAME',
    payload: timeframe
  }
}


export const setCheck = (check) => {
  return {
    type: 'SET_CHECK',
    payload: check
  }
}



export const setCheckLoading = (loading, status) => {
  return {
    type: 'SET_CHECK_LOADING',
    payload: {
      loading,
      status
    }
  }
}

export const setMerchantsLoading = (loading, status) => {
  return {
    type: 'SET_MERCHANTS_LOADING',
    payload: {
      loading,
      status
    }
  }
}

export const setSearch = (keywords) => {
  return {
    type: 'SET_SEARCH',
    payload: keywords
  }
}

export const setRebateChecksFilter = (type, value) => {
  return {
    type: 'SET_REBATE_CHECKS_FILTERS',
    payload: { type, value }
  }
}

export const clearAllRebateChecksData = () => {
  return {
    type: 'CLEAR_REBATE_CHECKS_DATA'
  }
}
