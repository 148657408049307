export default function reducer(state = {
  notifications: []
}, action) {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      let uiUpdateNotif = state.notifications.find(n => n.props.type === 'ui-update');
      return {
        ...state,
        notifications: !uiUpdateNotif ? [
          ...state.notifications,
          action.payload
        ] : state.notifications
      };

    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter(n => n.props.id !== action.payload)
      };
    default:
      return state;
  }
};