import moment from "moment";


const fromDate = new Date(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00');
const toDate = new Date(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T00:00:00');
// const date = new Date();

// let orderHistoryCalendarDate = date.setFullYear(date.getMonth() === 0 ? date.getFullYear() - 1  : date.getFullYear());
// orderHistoryCalendarDate = new Date(date.setMonth(date.getMonth() === 0 ? 11 : date.getMonth() - 1));

// show previous month as current month will most likely have no data
let init = {
  merchants: [],
  merchantsList: [],
  filteredCompMerchantsList: [],
  filteredCompItemsList: [],
  merchantsLoaded: false,
  fromDate: fromDate,
  toDate: toDate,
  fromDateApply: fromDate,
  toDateApply: toDate,
  search: '',
  searchApply: '',
  allLoaded: false,
  intelVisible: false,
  gridOptions: {
    page: 1
  },
  listOptions: {
    page: 1,
    sortBy: 'totalVolume',
    sortDesc: true
  },
  detailOptions: {
    distributorSort: {
      sortBy: 'quantity',
      sortDesc: true
    },
    competitiveSort: {
      sortBy: 'quantity',
      sortDesc: true
    },
    competitiveInsightMerchantSort: {
      sortBy: 'competitorVolume',
      sortDesc: true
    },
    competitiveInsightItemSort: {
      sortBy: 'purchase',
      sortDesc: true
    },
    competitiveInsightItemMatchesSort: {
      sortBy: 'inCatalog',
      sortDesc: true
    },
    competitiveInsightOrderItemsSort: {
      sortBy: 'description',
      sortDesc: false
    },
    uploadedInvoicesSort: {
      sortBy: 'createdAt',
      sortDesc: true
    }
  },
  competitiveInsightCategories: [],
  competitiveInsightItems: [],
  competitiveInsightData: [],
  summaryVolume: 0,
  itemMatches: [],
  filteredAndSortedItemMatches: [],
  itemMatchesLoading: null,
  itemMatchesForAllItems: [],
  itemRelatedToMatches: null,
  competitiveOrderHistoryPurchases: [],
  orderItems: [],
  orderHistoryCalendarDate: fromDate,
  capturedOrderHistoryMonths: {},
  loadingOrderHistory: false
}

export default function reducer(state = init, action) {
  switch (action.type) {
    case 'SET_DETAIL_SORT':
      return {
        ...state,
        detailOptions: {
          ...state.detailOptions,
          [action.payload.view]: {
            sortBy: action.payload.key,
            sortDesc: action.payload.desc === undefined ? (action.payload.key === state.detailOptions[action.payload.view].sortBy ? !state.detailOptions[action.payload.view].sortDesc : true) : action.payload.desc
          }
        }
      }
    case 'APPLY_INTEL_SEARCH':
      return {
        ...state,
        searchApply: state.search,
        fromDateApply: state.fromDate,
        toDateApply: state.toDate,
        gridOptions: {
          page: 1
        },
        listOptions: {
          ...state.listOptions,
          page: 1
        }
      };
    case 'SET_GRID_PAGE':
      return {
        ...state,
        gridOptions: {
          page: action.payload
        }
      };
    case 'SET_LIST_PAGE':
      return {
        ...state,
        listOptions: {
          ...state.listOptions,
          page: action.payload
        }
      };
    case 'SET_LIST_SORT_BY':
      return {
        ...state,
        listOptions: {
          ...state.listOptions,
          sortBy: action.payload,
          sortDesc: action.payload === state.listOptions.sortBy ? !state.listOptions.sortDesc : true
        },
      };
    case 'SET_LIST_SORT_BY_DESC':
      return {
        ...state,
        listOptions: {
          ...state.listOptions,
          sortBy: action.payload.key,
          sortDesc: action.payload.desc
        },
      };
    case 'CLEAR_INTEL_SEARCH':
      return {
        ...state,
        search: '',
        searchApply: '',
        gridOptions: state.searchApply !== '' ? {
          page: 1
        } : state.gridOptions,
        listOptions: state.searchApply !== '' ? {
          ...state.listOptions,
          page: 1
        } : state.listOptions
      };
    case 'SET_INTEL_SEARCH':
      return {
        ...state,
        search: action.payload,
      };
    case 'SET_INTEL_SEARCHES_ONLY':
      return {
        ...state,
        search: action.payload,
        searchApply: action.payload
      };
    case 'CLEAR_INTEL_MERCHANTS':
      return {
        ...state,
        merchantsLoaded: false,
        merchants: [],
        allLoaded: false
      };
    case 'SET_INTEL_MERCHANTS':
      return {
        ...state,
        merchantsLoaded: true,
        merchantsList: action.payload,
        merchants: action.payload.map(m => {
          return {
            ...m,
            loading: true,
            data: [],
            noData: true,
            totalVolume: 0,
            competitorVolume: 0,
            competitorVolumePerc: 0,
            distributorVolume: 0,
            distributorVolumePerc: 0,
            dcn: ''
          }
        })
      };
    // We received an array of merchants with data to update
    case 'SET_INTEL_MERCHANT_DATA':
      return {
        ...state,
        allLoaded: action.payload.finish,
        merchants: state.merchants.map(m => {

          // Only update those merchants in the state that match the IDs we get
          const merchant = action.payload.data.find(e => e.id === m.id);
          if (!merchant) return m;

          // Build volume statistics for this merchant
          const totalVolume = merchant.data.reduce((prev, cur) => { return prev + cur.volume }, 0);
          const competitorVolume = merchant.data
            .filter(d => d.competitor === true)
            .reduce((prev, cur) => { return prev + cur.volume }, 0);
          const competitorVolumePerc = merchant.data
            .filter(d => d.competitor === true)
            .reduce((prev, cur) => { return prev + cur.percentage / 100 }, 0);
          const distributorVolume = merchant.data
            .filter(d => d.competitor === false)
            .reduce((prev, cur) => { return prev + cur.volume }, 0);
          const distributorVolumePerc = merchant.data
            .filter(d => d.competitor === false)
            .reduce((prev, cur) => { return prev + cur.percentage / 100 }, 0);
          const preferredDist = merchant.data.find(d => !d.competitor);
          const dcn = preferredDist ? preferredDist.dcn : '';
          const noData = merchant.data.length === 0 || merchant.data.every(d => d.volume === 0);

          // Update this merchant with the data received,
          // along with the statistics calculated here
          return {
            ...m,
            loading: false,
            data: merchant.data,
            noData,
            totalVolume,
            competitorVolume,
            competitorVolumePerc,
            distributorVolume,
            distributorVolumePerc,
            dcn
          }
        })
      };
    case 'CLEAR_INTEL_MERCHANT_DATA':
      return {
        ...state,
        allLoaded: false,
        gridOptions: {
          page: 1
        },
        listOptions: {
          ...state.listOptions,
          page: 1
        },
        merchants: state.merchants.map(m => {
          return {
            ...m,
            loading: true,
            totalVolume: 0,
            competitorVolume: 0,
            competitorVolumePerc: 0,
            distributorVolume: 0,
            distributorVolumePerc: 0,
            data: [],
            noData: true,
            dcn: ''
          };
        })
      };
    case 'SET_INTEL_TO_DATE':
      return {
        ...state,
        toDate: action.payload
      };
    case 'SET_INTEL_FROM_DATE':
      return {
        ...state,
        fromDate: action.payload
      };
    case 'SET_INTEL_DATE_RANGE':
      return {
        ...state,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate
      }
    case 'SET_INTEL_VISIBLE':
      return {
        ...state,
        intelVisible: action.payload
      };
    case 'SET_COMPETITIVE_INSIGHT_CATEGORIES':
      return {
        ...state,
        competitiveInsightCategories: action.payload
      };
    case 'SET_COMPETITIVE_INSIGHT_ITEMS':
      return {
        ...state,
        competitiveInsightItems: action.payload
      };
    case 'SET_COMPETITIVE_INSIGHT_DATA':
      return {
        ...state,
        competitiveInsightData: action.payload
      };
    case 'SET_COMPETITIVE_FILTERED_MERCHANTS':
      return {
        ...state,
        filteredCompMerchantsList: action.payload
      };
    case 'SET_COMPETITIVE_FILTERED_ITEMS':
      return {
        ...state,
        filteredCompItemsList: action.payload
      };
    case 'CLEAR_ALL_INTEL_DATA':
      return init
    case 'ITEM_MATCHES_DATA':
      if (state.itemMatchesForAllItems.findIndex(
        m => m.min === action.payload.min &&
          m.manufacturerPlatformId === action.payload.manufacturerPlatformId) < 0) {

        // add matches for this min/platformID to our state, if we don't have matches for it already
        return {
          ...state,
          itemMatchesForAllItems: [...state.itemMatchesForAllItems, {
            min: action.payload.min,
            manufacturerPlatformId: action.payload.manufacturerPlatformId,
            matches: action.payload.data,
            visible: false
          }]
        };
      }
      else {
        return state;
      }
    case 'SET_COMP_INSIGHTS_ITEM_MATCHES':
      return {
        ...state,
        itemMatches: action.payload,
      }
    case 'SET_COMP_INSIGHTS_FILTERED_AND_SORTED_ITEM_MATCHES':
      return {
        ...state,
        filteredAndSortedItemMatches: action.payload,
      }
    case 'COMP_INSIGHT_ITEM_MATCHES_LOADING':
      return {
        ...state,
        itemMatchesLoading: action.payload
      }
    case 'HEADER_SUMMARY_DATA':
      return {
        ...state,
        summaryVolume: action.payload.totalVolume
      }
    case 'SET_COMPETITIVE_ORDER_HISTORY_PURCHASES':
      return {
        ...state,
        competitiveOrderHistoryPurchases: [...state.competitiveOrderHistoryPurchases, ...action.payload]
      }
    case 'SET_COMP_INSIGHTS_ORDER_ITEMS':
      return {
        ...state,
        orderItems: action.payload,
      }
    case 'SET_ORDER_HISTORY_CALENDAR_DATE':
      return {
        ...state,
        orderHistoryCalendarDate: action.payload
      }
    case 'CLEAR_COMPETITIVE_ORDER_HISTORY_PURCHASES':
      return {
        ...state,
        competitiveOrderHistoryPurchases: []
      }
    case 'CAPTURE_ORDER_HISTORY_MONTH':
      let merchantId = action.payload.merchantId;
      let month = action.payload.month;
      return {
        ...state,
        capturedOrderHistoryMonths: {
          ...state.capturedOrderHistoryMonths, [merchantId]: state.capturedOrderHistoryMonths[merchantId] ?
            [...state.capturedOrderHistoryMonths[merchantId], month] : [month]
        }
      }
    // case 'CAPTURE_ORDER_HISTORY_MONTH':
    //   return {
    //     ...state,
    //     capturedOrderHistoryMonths: !state.capturedOrderHistoryMonths.includes(...action.payload) ? 
    //     [...action.payload, ...state.capturedOrderHistoryMonths] : state.capturedOrderHistoryMonths
    //   }
    case 'CLEAR_ORDER_HISTORY_MONTHS':
      return {
        ...state,
        capturedOrderHistoryMonths: {}
      }
    case 'SET_LOADING_ORDER_HISTORY':
      return {
        ...state,
        loadingOrderHistory: action.payload
      }
    default:
      return state;
  };
};