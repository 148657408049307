import userApi from "../../api/userApi";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { spinner } from "../../reducers/UIReducer/UIActions";
import { useReducer } from "react";
import useLogin from "../../sharedComponents/useLogin/useLogin";
import { useHistory } from "react-router-dom";
import LocalStorage from "../../sharedComponents/LocalStorage/LocalStorage";

const reducer = (state, action) => {
  switch(action.type) {
    case 'SET_USER_LIST':
      return {
        ...state,
        users: action.payload.map(u => {
          return {
            label: `${u.contactName || u.name} ${u.email}`,
            value: {
              ...u,
            }
          };
        })
      }
    case 'SELECT_OPTION':
      return {
        ...state,
        selectedUser: action.payload
      }
    default:
      return state;
  }
};

const useGroupLogin = () => {
  const [{users, selectedUser}, set] = useReducer(reducer, {users: [], selectedUser: null});
  const dispatch = useDispatch();
  const history = useHistory();
  const { login } = useLogin();
  const groupUserActive = LocalStorage.groupUserActive.get();
  useEffect(() => {
    dispatch(spinner.add());
    if(groupUserActive) {
      userApi.group.get(groupUserActive.id).then(({data}) => {
        dispatch(spinner.subtract());
        set({type: 'SET_USER_LIST', payload: [groupUserActive, ...data]});
      });
    }
    // eslint-disable-next-line
  }, []);

  const onChange = (opt) => {
    set({type: 'SELECT_OPTION', payload: opt})
  };

  const onLogin = (e) => {
    e.preventDefault();
    login({...selectedUser.value, partOfGroup: true, internalGroup: true});
  };

  const onLogout = () => {
    history.push('/logout');
  };

  return {
    selectProps: {
      onChange,
      value: selectedUser,
      options: users
    },
    onLogin,
    onLogout
  };
};

export default useGroupLogin;