let init = {
  totalVolume: 0,
  competitorVolume: 0,
  volumeByMonth: [],
  volumeByMerchant: [],
  volumeByMerchantLoaded: false,
  totalRebates: 0,
  annualizedRebates: 0,
  lostItemCategories: [],
  lostItemDescriptions: [],
  itemDrilldown: [],
  categoryDrilldown: [],
  isLoading: true,
  loadComplete: false,
  loadFailed: false,
  sortBy: 'volume',
  redirectFromDashboardToItemDetails: false,
  selectedMerchant: null,
  volumeLoading: false,
  volumeChartPeriod: { label: '3 months', value: 3 },
  volumeLoadFailed: false
  }
export default function reducer(state = init, action) {
    switch(action.type) {
      case 'SET_DASHBOARD_DATA':
        return {
          ...state,
          totalVolume: action.payload.totalVolume,
          competitorVolume: action.payload.competitorVolume,
          totalRebates: action.payload.totalRebates,
          annualizedRebates: action.payload.annualizedRebates,
          lostItemCategories: action.payload.lostItemCategories,
          lostItemDescriptions: action.payload.lostItemDescriptions,
          isLoading: false,
          loadComplete: true,
          loadFailed: false
        }
      case 'CLEAR_DASHBOARD_DATA':
        return {
          ...state,
          totalVolume: 0,
          competitorVolume: 0,
          volumeByMonth: [],
          totalRebates: 0,
          annualizedRebates: 0,
          lostItemCategories: [],
          lostItemDescriptions: [],
          loadComplete: false,
          sortBy: 'volume'
        }
      case 'LOADING':
        return {
          ...state,
          isLoading: action.payload
        }
      case 'SET_CATEGORY_DRILLDOWN':
        return {
          ...state,
          categoryDrilldown: action.payload,
        }
      case 'SET_ITEM_DRILLDOWN':
        return {
          ...state,
          itemDrilldown: action.payload,
        }
      case 'SET_CATEGORY_DRILLDOWN_DASH':
        // drilldowns clicked on the dashboard should be mutually exclusive
        // with regard to categories and items
        return {
          ...state,
          categoryDrilldown: action.payload,
          itemDrilldown: []
        }
      case 'SET_ITEM_DRILLDOWN_DASH':
        return {
          ...state,
          itemDrilldown: action.payload,
          categoryDrilldown: []
        }
      case 'CLEAR_ALL_DASHBOARD_DATA':
        return init
      case 'DASHBOARD_LOAD_FAILED':
        return {
          ...state,
          loadComplete: true,
          loadFailed: true,
          isLoading: false
        }
      case 'UPDATE_LOST_ITEMS':
        return {
          ...state,
          lostItemDescriptions: action.payload.items,
          sortBy: action.payload.sortBy
        }
      case 'REDIRECT_FROM_DASHBOARD_TO_ITEM_DETAILS':
        return {
          ...state,
          redirectFromDashboardToItemDetails: action.payload
        }
      case 'SELECT_MERCHANT':
        return {
          ...state,
          selectedMerchant: action.payload
        }
      case 'SET_VOLUME_DATA':
        return {
          ...state,
          volumeByMonth: action.payload,
          volumeLoading: false,
          volumeLoadFailed: false
        }
      case 'VOLUME_LOADING':
        return {
          ...state,
          volumeLoading: action.payload
        }
      case 'SET_VOLUME_CHART_PERIOD':
        return {
          ...state,
          volumeChartPeriod: action.payload
        }
      case 'VOLUME_LOAD_FAILED':
        return {
          ...state,
          volumeLoadFailed: true,
          volumeLoading: false
        }
      case 'SET_VOLUME_BY_MERCHANT':
        return {
          ...state,
          volumeByMerchant: action.payload,
          volumeByMerchantLoaded: true
        }
      case 'CLEAR_VOLUME_BY_MERCHANT':
        return {
          ...state,
          volumeByMerchant: [],
          volumeByMerchantLoaded: false
        }
      default:
        return state;
    };
};
