import Joi from '@hapi/joi';
import useForm from "../../sharedComponents/useForm/useForm";

const initialState = {
  email: ''
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field] : action.value
  };
};

const schema = Joi.object({
  email: Joi.string().email({ tlds: {allow: false} }).label('Email').required().trim(),
});

const useForgotPasswordForm = () => {
  const { form, validate } = useForm(reducer, initialState, schema);

  return {
    ...form,
    validate
  };
};

export default useForgotPasswordForm;