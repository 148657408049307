import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { BsSearch } from 'react-icons/bs';
import { colors } from '../../../../config/constants';
import { MdMultilineChart } from 'react-icons/md';
import Select from 'react-select';
import { selectStyles } from '../../../../config/constants';
import useIntelLineChart from './useIntelLineChart';

const MAX_SERIES = 6; // Max of 1 distributor + all competitors + top 4 competitors
const LOG_TOGGLE = "Switch to Logarithmic View";
const LIN_TOGGLE = "Switch to Linear View";

const chartRanges = [
  {
    label: '3 months',
    value: 3
  },
  {
    label: '6 months',
    value: 6
  },
  {
    label: '9 months',
    value: 9
  },
  {
    label: '12 months',
    value: 12
  }
];

const IntelMonthlyVolume = ({ merchantName, merchantId }) => {
  const { 
    volumeByMonth, 
    volumeChartPeriod, 
    onChangeChartPeriod, 
    volumeLoading, 
    logarithmic, 
    onLogarithmicToggle, 
    volumeLoadFailed 
  } = useIntelLineChart.useDashboardMonthlyVolume(merchantId);

  // Convert volume data to series data usable by chart
  const dist = [];
  const allComps = [];
  const comps = [];
  let distVolume = 0;

  if (volumeByMonth?.length > 0) {
    // Set up initial empty series
    dist.push({ name: 'You', data: [] });
    allComps.push({ name: 'All Competitors', data: [] })
    for (let i = 0; i < volumeByMonth[0].competitors?.length; i++) {
      comps.push({ data: [], totalVolume: 0 });
    }

    // Populate series with monthly data
    for (let i = 0; i < volumeByMonth.length; i++) {
      dist[0].data.push(volumeByMonth[i].distributor);
      distVolume = distVolume + volumeByMonth[i].distributor;
      allComps[0].data.push(volumeByMonth[i].allCompVolume);
      for (let c = 0; c < volumeByMonth[0].competitors?.length; c++) {
        comps[c].totalVolume = comps[c].totalVolume + volumeByMonth[i].competitors[c].volume;
        comps[c].data.push(volumeByMonth[i].competitors[c].volume);
      }
    }

    // No point in showing All Competitors if there are 0 or 1 competitors
    if (comps.length < 2) {
      allComps.pop();
    }

    // Don't show distributor series if they have no volume
    if (distVolume === 0) {
      dist.pop();
    }

    // Sort competitors by total volume, descending
    comps.sort((a, b) => b.totalVolume - a.totalVolume);

    for (let i = 0; i < comps.length; i++) {
      comps[i].name = `Distributor ${i + 1}`;
    }
  }
  
  // Save only top 5 series, if there are more than that
  const data = [...dist, ...allComps, ...comps].slice(0, MAX_SERIES);
  
  const startDateMoment = new Date(moment().subtract(volumeChartPeriod.value, 'month').startOf('month'));
  const chartStartDate = Date.UTC(
    startDateMoment.getUTCFullYear(), 
    startDateMoment.getUTCMonth(), 
    startDateMoment.getUTCDate()
  );

  const { chartOptions } = useIntelLineChart.useLineChart(volumeLoading ? null : data, chartStartDate, logarithmic);

  return (
    <Col className={'w-100 py-4 bg-white rounded'}>
      { volumeLoading ? 
        <Row className='text-center d-flex flex-column'>
          <Col><Spinner color='primary' /></Col>
        </Row>
        :
        <>
          <Row>
            <Col className='intel-graph-header text-size-20 py-3'>
              {merchantName}
            </Col>
            <Col className='w-fc px-3'>
              <MdMultilineChart 
                className='clickable'
                size={38}
                color={logarithmic ? colors.beplMagenta : colors.beplDarkBlue}
                title={logarithmic ? LIN_TOGGLE : LOG_TOGGLE}
                onClick={onLogarithmicToggle}
              />
            </Col>
            <Col className='mmw-175px'>
              <Select
                options={chartRanges}
                onChange={onChangeChartPeriod}
                styles={selectStyles}
                value={volumeChartPeriod}
              />
            </Col>
          </Row>
          <Row className='align-items-center'>
            { !volumeLoadFailed ? 
            <Col>
              <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: 'highcharts-parent h-300px' }} /> 
            </Col>
            :
            <Col>
              <Row className='text-center justify-content-center p-5'>
                <BsSearch color={colors.beplMagenta} size={45} />
              </Row>
              <Row xs={12} className='text-center justify-content-center'>
                Chart data not available
              </Row>
            </Col>
            }
          </Row>
        </>
      }
    </Col>
  );
}

export default IntelMonthlyVolume;