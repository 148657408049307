import React from 'react';
import { Row, Col, CardBody, 
  ButtonGroup, Button 
} from 'reactstrap';
import { If, Else, Then } from 'react-if';
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from 'react-icons/io';


const RebateChecksSortHeader = ({ sortState, sortBy, selectAll, allSelected }) => {
  const sortableColumn = (shortName, longName, colSize) => {
    return (
      <Col xs={colSize} onClick={() => sortBy(shortName)}
        className={'clickable d-flex align-items-center' + (sortState.sortBy === shortName ? ' text-primary' : '')}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
          <Then>
            <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }


  return (
    <CardBody className='py-2 bg-white border-top border-left border-right'>
      <Row className='text-muted text-size-10 line-height-11 text-uppercase'>
        {/* <div className='px-3'></div> */}
        <ButtonGroup>
          <Button
            color="primary p-2"
            outline
            style={{marginLeft: '13px'}}
            onClick={selectAll}
            active={allSelected}
          >
          </Button>
        </ButtonGroup>
        {sortableColumn('platformclientid', 'Platform Client ID', 1)}
        {sortableColumn('merchant', 'Customer Name', 2)}
        {sortableColumn('distributordistrict', 'Distributor District', 2)}
        {sortableColumn('checkAmount', 'Amount', 2)}

        {/* {sortableColumn('date', 'Date', 3)} */}
        {/* <Col xs={1}></Col>
        <Col xs={1}></Col> */}
        <Col xs={4} className="d-flex justify-content-end align-items-center">Ach Details</Col>
      </Row>
    </CardBody>
  );
}

export default RebateChecksSortHeader;