import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';

const LOADING_HEADER = "Loading...";

const DashboardLoading = () => {
  let [messageStyle, setMessageStyle] = useState('translateY-100% opacity-0');
  useEffect(() => {
    let init = 'translateX-100%';
    let position = 'down';
    let interval = setInterval(() => {
      if(init !== 'translateY-0% opacity-100') {
        init = 'translateY-0% opacity-100';
      } else {
        if(position==='down'){
          position = 'up'
          init = '-translateY-100% opacity-0'
        } else {
          position = 'down'
          init = 'translateY-100% opacity-0'
        }
      }
      setMessageStyle(init);
    }, 4000)
    return () => clearInterval(interval);
  }, []);

  return (
    <Container style={{ height: '30rem' }}>
      <Row className='text-center text-size-20 main-header p-5'>
        <Col>
          <div className='position-relative overflow-hidden'>          
            <div className={`${messageStyle} transition-all `}>{LOADING_HEADER}</div>
          </div>
        </Col>
      </Row>
      <Row className='text-center d-flex flex-column'>
        <Col><Spinner color='primary' /></Col>
      </Row>
    </Container>
  );
}

export default DashboardLoading;