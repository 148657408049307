import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { showModal } from '../../../../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../../../../sharedComponents/AlertModal/AlertModal';

const ItemTable = (props) => {
    const { data } = props;
    const dispatch = useDispatch();

    const logItem = (item) => {
        dispatch(showModal(AlertModal, {title: `Full details for ${item.description} have been sent to the console.`}));
    }

    let itemTable = [];
    for (let i = 0; i < data.length; i++) {
        const { distributor, items } = data[i];
        let itemArray = [];
        for (let n = 0; n < items.length; n++) {
            const { description, deviation, deviationValue, manufacturer, rebate, rebateType, rebateValue } = items[n];
            itemArray[n] = (
                <tr key={`${n}-${description}`}>
                    <td>{description}</td>
                    <td>{deviation}</td>
                    <td>{String(deviationValue)}</td>
                    <td>{manufacturer}</td>
                    <td>{rebate}</td>
                    <td>{rebateType}</td>
                    <td>{rebateValue}</td>
                    <td>
                        <Col className='text-beplMagenta clickable font-weight-bold' onClick={() => logItem(items[n])}>
                            Send
                        </Col>
                    </td>
                </tr>
            );
        }

        itemTable[i] = (
            <Col key={`${distributor}-entry-${i}`}>
                <Row className='main-header text-size-24 text-beplBlue d-flex justify-content-center pt-2'>
                    {distributor} Items
                </Row>
                <table className='table table-bordered table-striped'>
                    <thead>
                        <tr>
                            <th scope='col'>Description</th>
                            <th scope='col'>Deviation</th>
                            <th scope='col'>Deviation Value</th>
                            <th scope='col'>Manufacturer</th>
                            <th scope='col'>Rebate</th>
                            <th scope='col'>Rebate Type</th>
                            <th scope='col'>Rebate Value</th>
                            <th scope='col'>Send Item to Console</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemArray}
                    </tbody>
                </table>
            </Col>
        );
    }
    return itemTable;
}

export default ItemTable;