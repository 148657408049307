import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import InviteOrEditUser from './InviteOrEditUser';
import { Label, Input, Alert, Tooltip } from 'reactstrap';
import { IoIosInformationCircle } from 'react-icons/io';

const MERCHANTS_LIST_UPLOAD_TOOLTIP = "This only accepts xlsx files and required following fields (platformclientid, email)";

const UsersRoles = (props) => {
  const { userInvite, userMap, uploadMerchantsToUsersList, error, setError } = props;
  const editUsersRoleView = useSelector(({ user }) => user.editUsersRoleView);

  const [tooltipOpen, setTooltipOpen] = useState({
    merchantsListUploadBtn: false
  });

  const toggleTooltipMerchantsList = () => {
    setTooltipOpen({ ...tooltipOpen, merchantsListUploadBtn: !tooltipOpen.merchantsListUploadBtn });
  }



  return (
    <>
      <Alert className="text-center mx-3" color="danger" isOpen={error !== false} toggle={() => setError(false)}>{error}</Alert>
      <If condition={editUsersRoleView}>
        <Then>
          <InviteOrEditUser />
        </Then>
        <Else>
          <Container fluid className={isMobile ? '' : 'px-4'}>
            {!isMobile &&
              <Row className='py-2 text-size-12 line-height-14 text-uppercase text-beplMagenta font-weight-bold border-bottom'>
                <Col xs={2}>
                  Name
                </Col>
                <Col xs={3}>
                  Email
                </Col>
                <Col xs={2}>
                  Role
                </Col>
                <Col className="d-flex justify-content-center" xs={1}>
                  Edit
                </Col>
                <Col className="d-flex justify-content-center" xs={1}>
                  Delete
                </Col>
              </Row>
            }
            {userMap}
            <Row className='pt-4 py-3 pl-2'>
              {/* <Col className={isMobile ? 'col-12' : 'py-4'} > */}
              <Button color='beplMagenta' className='py-2 button-label' onClick={() => userInvite(false)}>Invite User</Button>
              {/* </Col> */}
              {/* <Col className={isMobile ? 'col-12' : 'py-4'}> */}
              <Label className='button-label btn btn-beplMagenta py-2 button-label mb-0 ml-2' htmlFor="file-input">
                <Input id="file-input" type="file" accept=".xlsx, .xls" onChange={uploadMerchantsToUsersList} hidden />
                Upload Merchants List
                <span id="idTooltipMerchantsListUploadBtn">
                  <IoIosInformationCircle className="ml-2" color='white' />
                </span>
                <Tooltip placement='top'
                  isOpen={tooltipOpen.merchantsListUploadBtn}
                  target='idTooltipMerchantsListUploadBtn'
                  toggle={toggleTooltipMerchantsList}>
                  {MERCHANTS_LIST_UPLOAD_TOOLTIP}
                </Tooltip>
              </Label>
              {/* </Col> */}
            </Row>
          </Container>
        </Else>
      </If>
    </>



  )
}

export default UsersRoles;
