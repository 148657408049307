import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col } from 'reactstrap';
import useModal from '../useModal/useModal';
import { If, Then, Else } from 'react-if';

const AlertModal = ({id, ...props}) => {
  const { modalProps, headerProps, bodyProps, toggle } = useModal(id, {hideClose: true, header: {color: 'black', background: 'white'}});
  const {
    title, message, showTwoButtons,
    buttonColor, buttonClick, buttonText,
    buttonLeftColor, buttonLeftClick, buttonLeftText, buttonLeftOutline,
    buttonRightColor, buttonRightClick, buttonRightText, buttonRightOutline
  } = props;

  bodyProps.className += ' text-center';

  const onClick = () => {
    if (buttonClick) {
      buttonClick();
    }
    toggle();
  };

  const onLeftClick = () => {
    if (buttonLeftClick) {
      buttonLeftClick();
    }
    toggle();
  };

  const onRightClick = () => {
    if (buttonRightClick) {
      buttonRightClick();
    }
    toggle();
  };

  return (
    <Modal {...modalProps}>
      <If condition={title !== null}>
        <ModalHeader {...headerProps}>
          {title}
        </ModalHeader>
      </If>
      <If condition={message !== null}>
        <ModalBody {...bodyProps}>
          {message}
        </ModalBody>
      </If>
      <ModalFooter className='justify-content-center'>
        <If condition={showTwoButtons}>
          <Then>
            <Col className='pl-0'>
              <Button onClick={onLeftClick} block outline={buttonLeftOutline} className='py-2' color={buttonLeftColor}>{buttonLeftText}</Button>
            </Col>
            <Col className='pr-0'>
              <Button onClick={onRightClick} block outline={buttonRightOutline} className='py-2' color={buttonRightColor}>{buttonRightText}</Button>
            </Col>
          </Then>
          <Else>
            <Button onClick={onClick} block className='py-2 col-6' color={buttonColor}>{buttonText}</Button>
          </Else>
        </If>
      </ModalFooter>
    </Modal>
  );
};

AlertModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonClick: PropTypes.func,
  buttonText: PropTypes.any,
  buttonColor: PropTypes.string,
  buttonLeftClick: PropTypes.func,
  buttonLeftText: PropTypes.any,
  buttonLeftColor: PropTypes.string,
  buttonRightClick: PropTypes.func,
  buttonRightText: PropTypes.any,
  buttonRightColor: PropTypes.string,
  showTwoButtons: PropTypes.bool,
  buttonLeftOutline: PropTypes.bool,
  buttonRightOutline: PropTypes.bool
};

AlertModal.defaultProps = {
  title: null,
  message: null,
  showTwoButtons: false,
  buttonText: 'Ok',
  buttonColor: 'primary',
  buttonLeftText: 'Ok',
  buttonLeftColor: 'primary',
  buttonRightText: 'Ok',
  buttonRightColor: 'primary',
  buttonLeftOutline: false,
  buttonRightOutline: false
};

export default AlertModal;