import LocalStorage from '../LocalStorage/LocalStorage';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../reducers/UserReducer/UserActions';

const useLogin = () => {
  const dispatch = useDispatch();

  const login = (data) => {
    const { authkey, authKey, passwordResetToken, ...userData } = data;
    const groupUserActive = LocalStorage.groupUserActive.get();
    LocalStorage.authKey.set(authkey || authKey);
    LocalStorage.resetKey.set(passwordResetToken);
    LocalStorage.userData.set(userData);

    if (data.initLogin) {
      if (data.isGroup) {
        delete data.initLogin;
        LocalStorage.groupUserActive.set(data);
        data.choosingUserAsGroupUser = true;
      }
    } else if (data.isGroup) {
      LocalStorage.groupUserActive.set(data);
      data.choosingUserAsGroupUser = true;
    } 
    
    if (groupUserActive) {
      data.choosingUserAsGroupUser = false;
    }

    if(!data.isGroup && !data.partOfGroup) {
      LocalStorage.groupUserActive.delete();
    }
    dispatch(setUserData(data));
  };

  return {
    login
  };
};

export default useLogin;