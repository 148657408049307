import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IntelDetailDistributorRow from './IntelDetailDistributorRow';
import sortArrayByKey from '../../../../../sharedComponents/Helper/sortArrayByKey';
import { setDetailSortBy } from '../../../../../reducers/IntelReducer/IntelActions';

const useApi = (id) => {
  const dispatch = useDispatch();
  const search = useSelector(({intel}) => intel.searchApply);
  const sortState = useSelector(({intel}) => intel.detailOptions.distributorSort);
  const merchant = useSelector(({intel}) => intel.merchants.filter(m => m.id === id)[0]);

  const sortBy = (key) => {
    dispatch(setDetailSortBy('distributorSort', key));
  };

  const distributorDistributors = merchant ? merchant.data.filter(d => d.competitor === false) : [];

  const distributorData = distributorDistributors
    .map(d => d.items)
    .reduce((prev, cur) => { 
      return cur ? [...prev, ...cur] : [...prev];
    }, []);

  const totalCases = distributorData.reduce((prev, cur) => { return prev + cur.quantity}, 0);
    
  const detailsListMap = sortArrayByKey(distributorData, sortState.sortBy, sortState.sortBy === 'uom' ? !sortState.sortDesc : sortState.sortDesc)
  .filter(d => d.description.toLowerCase().search(search.toLowerCase()) !== -1 || d.manufacturer.toLowerCase().search(search.toLowerCase()) !== -1).map((d, i) => {
    return (
      <IntelDetailDistributorRow key={`competitive_data_${i}`} {...d} />
    );
  });

  return {
    sortBy, sortState,
    merchant,
    loading: merchant ? merchant.loading : true,
    distributorVolume: merchant ? merchant.distributorVolume : 0,
    distributorVolumePerc: merchant ? merchant.distributorVolumePerc : 0,
    totalCases: totalCases,
    detailsListMap,
    distributorData
  };
};

export default {
  useApi
};