import React from "react";
import { Container, Row, Col, Button, Input, Label, Spinner } from "reactstrap";
import Select from 'react-select';
import { selectStyles } from "../../config/constants";
import useBusinessPanel from "./useBusinessPanel";
import CheckableList from "../../sharedComponents/CheckableList/CheckableList";
import { BsFillTrashFill } from 'react-icons/bs';
import { FaPlusCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";



const BusinessPanel = () => {

  const { loadingState, showExportOptions, setShowExportOptions, showSalesRepsForm, setShowSalesRepsForm, error,
    XlToPdf, distributorsOps, distributorSalesRepsOps, salesRep, setSalesRep, distributorsAssociatedWithRepOps,
    addDistributorToRep, removeDistributorFromRep, saveDSRChanges, loading, complete, deleteDSR, confirmOp, setConfirmOp,
    role, setRole, roles, addNewRep, setAddNewRep, newRepEmail, setNewRepEmail, newRepName, setNewRepName,
    distributorSearch, setDistributorSearch
  } = useBusinessPanel();



  return (
    <Container fluid className='flex-grow-1'>
      <Row className='justify-content-center'>
        <Col style={{ maxWidth: '800px' }}>
          <Row className='py-3'>
            <Col className='text-center text-size-24 main-header px-1'>
              Platform Distributor Portal Business Panel
            </Col>
          </Row>
          {!showExportOptions ?
            <Col className="px-1">
              <Button
                outline
                onClick={() => setShowExportOptions(true)}
                block
                color='beplMagenta'
                className='py-2 button-label border-2'>
                Checks Data
              </Button>
            </Col>
            : <Row>
              <Col className="d-flex justify-content-center align-items-center">
                {loadingState.checks ? <Spinner></Spinner> :
                  <Label className='button-label btn py-2 border-beplMagenta border-2 text-beplMagenta w-full w-100' htmlFor="rb-file-input1">
                    Checks
                    <Input
                      onChange={(e) => XlToPdf(e, 'checks')}
                      id="rb-file-input1"
                      type="file"
                      accept=".xlsx, .xls"
                      hidden
                      color='beplMagenta'
                      className='py-2 button-label'>
                    </Input>
                  </Label>}
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                {loadingState.details ? <Spinner></Spinner> : <Label className='button-label btn py-2 border-beplMagenta border-2 text-beplMagenta w-full w-100' htmlFor="rb-file-input2">
                  Checks Details
                  <Input
                    onChange={(e) => XlToPdf(e, 'details')}
                    id="rb-file-input2"
                    type="file"
                    accept=".xlsx, .xls"
                    hidden
                    color='beplMagenta'
                    className='py-2 button-label'>
                  </Input>
                </Label>}
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                {loadingState.both ? <Spinner></Spinner> : <Label className='button-label btn py-2 border-beplMagenta border-2 text-beplMagenta w-full w-100' htmlFor="rb-file-input3">
                  Both
                  <Input
                    onChange={(e) => XlToPdf(e, 'both')}
                    id="rb-file-input3"
                    type="file"
                    accept=".xlsx, .xls"
                    hidden
                    color='beplMagenta'
                    className='py-2 button-label'>
                  </Input>
                </Label>}

              </Col>
            </Row>}


          {!showSalesRepsForm ? <Col className="px-1">
            <Button
              outline
              onClick={() => setShowSalesRepsForm(true)}
              block
              color='beplMagenta'
              className='py-2 button-label border-2 mt-2'>
              Add Sales Reps
            </Button>
          </Col> :
            <Col className="px-0">
              <Row className="m-0 p-0 mt-2">
                <Col className="pl-1 pr-0 position-relative" xs={11}>
                  {addNewRep ?

                    <><Input onChange={(e) => setNewRepEmail(e.target.value)} value={newRepEmail} placeholder="New Rep Email" />
                      <Input className="mt-2" onChange={(e) => setNewRepName(e.target.value)} value={newRepName} placeholder="New Rep Name" />
                    </> :
                    <>
                      <Select
                        onChange={(op) => { setSalesRep(op) }}
                        options={distributorSalesRepsOps}
                        styles={selectStyles}
                        value={salesRep.value ? salesRep : ''}
                        placeholder="Sales Reps"

                      />
                      {salesRep.value && salesRep.value.length > 0 ? <BsFillTrashFill style={{ right: 45, top: 12, cursor: 'pointer' }} className="position-absolute text-yellowDark"
                        onClick={() => setConfirmOp(() => deleteDSR)} size="15" /> : false}
                    </>
                  }


                </Col>
                {addNewRep ?
                  <Col xs={1} onClick={() => setAddNewRep(false)} style={{ cursor: 'pointer' }} className="d-flex justify-content-center align-items-center text-beplMagenta">
                    <RiCloseCircleFill color="danger" size={23} />
                  </Col> :
                  <Col xs={1} onClick={() => setAddNewRep(true)} style={{ cursor: 'pointer' }} className="d-flex justify-content-center align-items-center text-beplMagenta">
                    <FaPlusCircle color="beplMagenta" size={23} />
                  </Col>
                }

              </Row>

              <Row className="mt-2 px-0 mx-0">
                <Col className="px-1 position-relative" xs={6}>
                  <Select
                    onChange={(op) => { setRole(op) }}
                    options={roles}
                    styles={selectStyles}
                    value={role.value ? role : ''}
                    placeholder="Role"

                  />

                </Col>

                <Col xs={6} className="px-1">
                  <Select
                    onChange={addDistributorToRep}
                    options={distributorsOps}
                    styles={selectStyles}
                    // value={''}
                    placeholder="Distributors"
                  />
                </Col>
              </Row>
              <Row className="mx-0 mt-2">
                <Col className="px-1">
                  <Input placeholder="Search..." value={distributorSearch} onChange={(e) => setDistributorSearch(e.target.value)} />
                </Col>
              </Row>
              <Col className="px-1 mt-2">
                <CheckableList checkItem={removeDistributorFromRep} title="BPanelDists" list={distributorsAssociatedWithRepOps} itemsChecked={distributorsAssociatedWithRepOps} color="white" />
              </Col>
              {loading ? <Row className="d-flex justify-content-center align-items-center py-3"><Spinner size="md" color='primary' /></Row> :
                complete ? <Row className="text-green text-size-20 py-3 d-flex justify-content-center align-items-center">Success!</Row> :
                  confirmOp ?
                    <Col className="p-0 m-0">
                      <Row className="m-0 text-size-20 py-2 text-center d-flex align-items-center justify-content-center">Are you sure?</Row>
                      <Row className="px-1 m-0">
                        <Col xs={6} className="pl-0 pr-1">
                          <Button
                            outline
                            onClick={confirmOp}
                            block
                            color='green'
                            className='py-2 button-label border-2 mt-2'>
                            Yes
                          </Button>
                        </Col>
                        <Col xs={6} className="pr-0 pl-2">
                          <Button
                            outline
                            onClick={() => setConfirmOp(false)}
                            block
                            color='danger'
                            className='py-2 button-label border-2 mt-2'>
                            No
                          </Button>
                        </Col>
                      </Row></Col>

                    :
                    <Row className="px-1 m-0">
                      <Col xs={6} className="pl-0 pr-1">
                        <Button
                          outline
                          onClick={saveDSRChanges}
                          block
                          color='beplMagenta'
                          className='py-2 button-label border-2 mt-2'>
                          Save
                        </Button>
                      </Col>
                      <Col xs={6} className="pr-0 pl-2">
                        <Button
                          outline
                          onClick={() => setShowSalesRepsForm(false)}
                          block
                          color='beplMagenta'
                          className='py-2 button-label border-2 mt-2'>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
              }

            </Col>
          }
          <Row>
          </Row>
          <Row className="d-flex justify-content-center text-red mt-2">{error}</Row>
        </Col>
      </Row>
    </Container>
  );
}

export default BusinessPanel;
