import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, CustomInput } from 'reactstrap';
import { If } from 'react-if';
import InputSearch from '../../../../sharedComponents/InputSearch/InputSearch';
import { isMobile } from 'react-device-detect';

const FilterSection = ({title, showSearch, options, search, onCheck }) => {
  const optionsMap = options.map(o => {
    return (
      <Row key={`${title}_${o.id}`} className='mr-0 pt-2'>
        <Col>
          <CustomInput id={`${title}_${o.id}`} label={o.label} checked={o.value} type='checkbox' onChange={()=> onCheck(o)} />
        </Col>
      </Row>
    )
  });

  return (
    <Fragment>
      <Row>
        <Col className='font-weight-bold'>{title}</Col>
      </Row>
      <If condition={showSearch}>
        <Row className='pt-4'>
          <Col>
            <InputSearch {...search} />
          </Col>
        </Row>
      </If>
      <Row className={'mt-1 mb-3 mr-0 text-size-14 line-height-16'+( isMobile ? '' : ' overflow-auto')}>
        <Col className='pr-0' style={ isMobile ? {maxHeight: '320px', overflowX:'hidden', overflowY:'auto'} : {maxHeight: '160px'}}>
          {optionsMap}
        </Col>
        { options.length === 0 && 
          <Col xs={12} className='py-2 text-center text-gray'>
            No Data found
          </Col>
        }
      </Row>
      <Row className='pb-4 mx-0'>
        <Col>
          <Row className='border-top '></Row>
        </Col>
      </Row>
    </Fragment>
  );
};

FilterSection.propTypes = {
  title: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  options: PropTypes.array.isRequired
};

FilterSection.defaultProps = {
  showSearch: true
};

export default FilterSection;