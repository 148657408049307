import Axios from "axios";

const url = '/distributor';

const distributorApi = {
  get: () => {
    return Axios.get(`${url}`);
  },
  getAll: () => {
    return Axios.get(`${url}/all`);
  }
};

export default distributorApi;