import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import toTitleCase from '../../../../sharedComponents/Helper/toTitleCase'
import truncateAfter96chars from '../../../../sharedComponents/Helper/truncateAfter96chars';

const UploadedInvoiceItemsRow = ({
  description, brand, pack, totalPrice,
  quantity, itemCode, uom,
}) => {
  description = toTitleCase(description);
  description = truncateAfter96chars(description);
 
  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='text-left font-weight-bold'>
            {description}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Manufacturer</Col>
          <Col className='text-right'>
            {brand}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Packsize</Col>
          <Col className='text-right'>
            {pack}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Total Price</Col>
          <Col className='text-right'>
            {numeral(totalPrice).format('$0,0.00')}
          </Col>
        </Row>


        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Quantity</Col>
          <Col className='text-right'>
            {numeral(quantity).format('0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Item Code</Col>
          <Col className='text-right'>
            {itemCode}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>UOM</Col>
          <Col className='text-right'>
            {uom}
          </Col>
        </Row>
      </CardBody>
    )
  } else {
    return (
      <CardBody className='border-top py-2 px-3 hover-row'>
        <Row className='align-items-center'>
          <Col xs={3} className='text-size-14'>
            {description}
          </Col>
          <Col xs={3} className='text-size-14'>
            {brand}
          </Col>
          <Col xs={1} className='text-size-14'>
            {pack}
          </Col>
          <Col xs={2} className='text-size-14'>
            {numeral(totalPrice).format('$0,0.00')}
          </Col>

          <Col xs={1} className='text-size-14'>
            {numeral(quantity).format('0,0.00')}
          </Col>
          <Col xs={1} className='text-size-14'>
            {itemCode}
          </Col>
          <Col xs={1} className='text-size-14'>
            {uom}
          </Col>
        </Row>
      </CardBody>
    );
  }
}

export default UploadedInvoiceItemsRow;