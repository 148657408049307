import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, CustomInput, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { selectStyles } from '../../../../config/constants';
import { useSelector } from 'react-redux';
import filterArrayByKey from '../../../../sharedComponents/Helper/filterArrayByKey';
import useModal from '../../../../sharedComponents/useModal/useModal';
import InviteUserToLocationsList from '../UsersRoles/InviteUserToLocationsList';
import InputSearch from '../../../../sharedComponents/InputSearch/InputSearch';
import useUsersRoles from '../UsersRoles/useUsersRoles';
import Select from 'react-select';

const InviteUserModal = ({ id, ...props }) => {
  const { modalProps, headerProps, bodyProps, toggle } = useModal(id, { isMenu: true, isFullscreen: true, header: { background: isMobile ? 'beplBlue' : 'white', color: isMobile ? 'white' : 'black' } });
  const { contactName, email, rol, validate } = useUsersRoles.useUserForm(props.isEdit, props.user);
  const { userSave, checkLocationAll, checkAll, onCheckAllowIntel, checkIntel, searchInviteLocations, selectRol, ...usr } = useUsersRoles.useUser(props);
  const locationChecked = useSelector(({ user }) => user.locationChecked);
  const locationList = useSelector(({ user }) => user.locationList);
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);

  const keysSearch = [{ key: 'label', search: searchInviteLocations.value }];
  let locationFilter = filterArrayByKey(props.locations, keysSearch);
  const userParams = {
    id: props.user.id,
    isEdit: props.isEdit,
    contactName: contactName.value,
    email: email.value,
    rol: rol.value.label,
    checkIntel,
    validate,
    toggle
  }

  const options = [
    { value: 1, label: 'Read Only' },
    { value: 2, label: 'Owner' },
    { value: 3, label: 'Manager' },
    { value: 4, label: 'Sales Rep' },
    { value: 5, label: 'President' },
    { value: 6, label: 'Vice President' }
  ];

  // Only BEPL distributor admins should see this option.
  if (isInternalAdmin) {
    options.push({ value: 7, label: 'Admin' });
  }

  return (
    <Modal {...modalProps}>
      <ModalHeader {...headerProps}>{props.title}</ModalHeader>
      <ModalBody {...bodyProps}>
        <Form className={isMobile ? '' : 'px-4'}>
          <FormGroup >
            <Label className='label-text-header text-size-13 line-height-14'>Name</Label>
            <Input {...contactName} />
          </FormGroup>
          <FormGroup>
            <Label className='label-text-header text-size-13 line-height-14'>Email</Label>
            <Input  {...email} disabled={props.isEdit} />
          </FormGroup>
          <FormGroup>
            <Label className='label-text-header text-size-13 line-height-14'>Role</Label>
            <Select options={options} onChange={rol.onChange} value={rol.value || ''} styles={selectStyles} />
          </FormGroup>
          <FormGroup>
            <Col xs={12} className='px-0 pt-2'>
              <CustomInput id={2} label={'Allow User to see Intel'} type='checkbox' checked={checkIntel} onChange={() => onCheckAllowIntel(checkIntel)} />
            </Col>
          </FormGroup>
          <Row className='text-size-16 line-height-18 mt-4'>
            <Col className="font-weight-bold" xs={12}>
              Invite To
            </Col>
            <Col xs={12} className='pt-3'>
              <CustomInput id={1} label={'All locations'} type='checkbox' checked={checkAll} onChange={() => checkLocationAll(checkAll, locationList)} />
            </Col>
          </Row>
          <Row className='pt-2'>
            <Col>
              <InputSearch {...searchInviteLocations} />
            </Col>
          </Row>
          <FormGroup className='px-3 pt-0'>
            <Row className="mt-2">
              <InviteUserToLocationsList options={locationFilter} locations={locationFilter} {...usr} />
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className='justify-content-center'>
        <Col className={isMobile ? 'px-1' : 'px-4'}>
          <Button onClick={() => userSave(userParams, locationChecked)} block className='p-2 button-label' color='beplMagenta'>Invite</Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default InviteUserModal;
