import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { setIntelSearchAndSearchApply } from '../../../../reducers/IntelReducer/IntelActions';

const useIntelDetail = (id) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const merchant = useSelector(({intel}) => intel.merchants.filter(m => m.id === id)[0]);
  const merchantsLoaded = useSelector(({intel}) => intel.merchantsLoaded);

  useEffect(() => {
    if (!merchant && merchantsLoaded) {
      history.push('/intel');
    } else {
      if (merchant) {
        if (!merchant.loading) {
          dispatch(setIntelSearchAndSearchApply(''));
          if (merchant.noData) {
            history.push('/intel');
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [merchantsLoaded, id, merchant]);

  return {
    merchantsLoaded
  }
};

export default {
  useIntelDetail
};