import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Row, Spinner } from 'reactstrap';
import CompetitiveInsightMerchantSortHeader from "./CompetitiveInsightMerchantSortHeader";
import { setCompetitiveInsightData } from "../../../../../reducers/IntelReducer/IntelActions";
import useMerchantData from './useMerchantData';
import { isMobile } from "react-device-detect";

const CompetitiveInsightMerchantView = ({ categoryDrilldown, itemDrilldown }) => {
  const { merchantMap, compItems, sortState, sortBy } = useMerchantData.useApi(categoryDrilldown, itemDrilldown);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCompetitiveInsightData(compItems));
  }, [dispatch, compItems]);

  return (
    <>

      {merchantMap.length === 0 ?
        <Row className="d-flex justify-content-center align-items-center h-25vh w-100"><Spinner color='primary' /></Row>
        :
        <>
          {!isMobile && <CompetitiveInsightMerchantSortHeader sortState={sortState} sortBy={sortBy} />}
          <Card>
            {merchantMap}
          </Card>
        </>}
    </>
  );
}

export default CompetitiveInsightMerchantView;