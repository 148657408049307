import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import RebatesMobile from './RebatesMobile';
import useRebates from './useRebates';
import RebatesTop from './RebatesTop';
import TableHeader from './Subcomponents/TableHeader';
import RebatesDesktop from './RebatesDesktop';
import { Switch, Route, Redirect } from 'react-router-dom';
import RebatesItemMatchesView from './ItemMatches/RebatesItemMatchesView';
import RebatesEmpty from './RebatesEmpty';
import RebatesFilters from './RebatesFilters';
import RebatesFiltersLoading from './RebatesFiltersLoading';

let RebatesMain = (rebateProps) => {
  rebateProps = rebateProps.rebateProps;
  const filtersLoaded = useSelector(({ rebate }) => rebate.filtersLoaded);
  const filtersLoadFailed = useSelector(({ rebate }) => rebate.filtersLoadFailed);
  const manufacturerGroup = useSelector(({ rebate }) => rebate.manufacturerGroup);
  const categoryGroup = useSelector(({ rebate }) => rebate.categoryGroup);
  const [filtersOnSmallerDesktops, showFiltersOnSmallerDesktops] = useState(false);
  const showFilters = () => {
    showFiltersOnSmallerDesktops(!filtersOnSmallerDesktops);
  }
  rebateProps.showFiltersOnSmallerDesktops = showFilters;

  return (
    <>
      <If condition={isMobile}>
        <Then>
          <Col className='flex-grow-1 bg-grayBg d-flex flex-column'>
            <RebatesMobile {...rebateProps} />
          </Col>
        </Then>
        <Else>
          <Row className='m-0 p-0 w-100 bg-grayBg'>
            <div className={`pl-4 pr-2 smaller-screen-rebates-list`} >
              <RebatesTop {...rebateProps} />
              <TableHeader {...rebateProps} />
              <RebatesDesktop {...rebateProps } />

            </div>
            <Col style={{right: 0, zIndex: '1100'}} className={`${filtersOnSmallerDesktops ? 'popout-rebate-filters-open' : 'popout-rebate-filters-closed'}   position-fixed pr-0 pl-3`} >
              {filtersLoadFailed ? <Col ><RebatesEmpty reason="Couldn't load filters, try refreshing" /></Col> :
                (manufacturerGroup.length > 0 || categoryGroup.length > 0) &&
                <Col className='bg-white p-1'>
                  <RebatesFilters {...rebateProps} />
                </Col>
              }
              {!filtersLoadFailed && !filtersLoaded &&
                <Col>
                  <RebatesFiltersLoading />
                </Col>
              }
            </Col>
          </Row>

        </Else>
      </If>
    </>
  )
}
const Rebates = () => {
  const { setFilterModal, onClickSearch, onClickReset, getRebates, getAllRebates, allRebateList, rebateList,
    allRebateMap, rebateMap, hasMore, hasMoreAllRebates, rebateLoadStatus, allRebateLoadStatus, sortBy, sortState, mobileRebateSortOptions, searchRebate,
    manufacturerChecked, categoryChecked, tryReloadingAllRebates } = useRebates.useApi();
  const manufacturerGroup = useSelector(({ rebate }) => rebate.manufacturerGroup);
  const categoryGroup = useSelector(({ rebate }) => rebate.categoryGroup);
  const rebateProps = {
    setFilterModal,
    onClickSearch,
    onClickReset,
    getRebates,
    rebateList,
    rebateMap,
    hasMore,
    rebateLoadStatus,
    allRebateLoadStatus,
    manufacturerGroup,
    categoryGroup,
    sortBy,
    sortState,
    mobileRebateSortOptions,
    hasMoreAllRebates,
    getAllRebates,
    allRebateList,
    allRebateMap,
    categoryChecked,
    manufacturerChecked,
    searchRebate,
    tryReloadingAllRebates
  }
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);

  return (
    <>
      <Switch>
        <Route exact path='/rebates/list' render={() => <RebatesMain rebateProps={rebateProps} />} />
        <Route exact path='/rebates/matches/:itemName/:min/:manPlatId/:merchId' component={RebatesItemMatchesView} />
        <Route>
          <Redirect to='/rebates/list' />
        </Route>
      </Switch>
    </>

  );
};

export default Rebates;
