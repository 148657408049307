import React, { createRef, useEffect, useState } from 'react';
import { Row, Input, Button, Label, Form, FormGroup, FormFeedback } from 'reactstrap';
import InputGroup from 'reactstrap/lib/InputGroup';
import useChangePassword from './useChangePassword';

const ChangePassword = () => {
  const { changePassword } = useChangePassword.useApi();
  const { currentPassword, password, passwordRepeat, validate } = useChangePassword.useFormChangePassword();
  const currentPasswordInput = createRef();
  const newPasswordInput = createRef();
  const confirmPasswordInput = createRef();
  const passwordParams = {
    currentPassword: currentPassword.value,
    password: password.value,
    passwordRepeat: passwordRepeat.value,
    validate
  }
  const [passwordVisibilityState, setState] = useState({
    toggledPassword: '',
    currentPasswordToggle: 'Show',
    newPasswordToggle: 'Show',
    confirmPasswordToggle: 'Show',
    currentPasswordInputType: 'password',
    newPasswordInputType: 'password',
    confirmPasswordInputType: 'password'
  });

  useEffect(() => {
    if (passwordVisibilityState.toggledPassword === 'currentPassword' && currentPasswordInput.current !== null) {
      let position = currentPasswordInput.current.selectionStart;
      currentPasswordInput.current.focus();
      currentPasswordInput.current.selectionStart = position;
      currentPasswordInput.current.selectionEnd = position;
    } else if (passwordVisibilityState.toggledPassword === 'newPassword' && newPasswordInput.current !== null) {
      let position = newPasswordInput.current.selectionStart;
      newPasswordInput.current.focus();
      newPasswordInput.current.selectionStart = position;
      newPasswordInput.current.selectionEnd = position;
    } else if (passwordVisibilityState.toggledPassword === 'confirmPassword' && confirmPasswordInput.current !== null) {
      let position = confirmPasswordInput.current.selectionStart;
      confirmPasswordInput.current.focus();
      confirmPasswordInput.current.selectionStart = position;
      confirmPasswordInput.current.selectionEnd = position;
    }
    // eslint-disable-next-line
  }, [passwordVisibilityState])

  const togglePassword = (type) => {
    let stateClone = JSON.parse(JSON.stringify(passwordVisibilityState));
    stateClone[type + 'InputType'] = stateClone[type + 'InputType'] === 'password' ? 'text' : 'password';
    stateClone[type + 'Toggle'] = stateClone[type + 'Toggle'] === 'Show' ? 'Hide' : 'Show';
    stateClone.toggledPassword = type;
    setState(stateClone);
  }

  return (
    <Form className='px-4 text-size-12 line-height-14' >
      <FormGroup className="position-relative">
        <Label className='label-text-header'>Current Password</Label>
        <InputGroup>
          <Input className='form-control' innerRef={currentPasswordInput} type={passwordVisibilityState.currentPasswordInputType} {...currentPassword} />
          <div onClick={() => togglePassword('currentPassword')} className="font-weight-bold font-sm text-black-50 clickable position-absolute-top-end mt-.75">
            {passwordVisibilityState.currentPasswordToggle}
          </div>
          <FormFeedback>{currentPassword.message}</FormFeedback>
        </InputGroup>
      </FormGroup>

      <FormGroup className="position-relative">
        <Label className='label-text-header'>New Password</Label>
        <InputGroup>
          <Input className='form-control' innerRef={newPasswordInput} type={passwordVisibilityState.newPasswordInputType} {...password} />
          <div onClick={() => togglePassword('newPassword')} className="font-weight-bold font-sm text-black-50 clickable position-absolute-top-end mt-.75">
            {passwordVisibilityState.newPasswordToggle}
          </div>
          <FormFeedback>{password.message}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <FormGroup className="position-relative">
        <Label className='label-text-header'>Confirm Password</Label>
        <InputGroup>
          <Input className='form-control' innerRef={confirmPasswordInput} type={passwordVisibilityState.confirmPasswordInputType} {...passwordRepeat} />
          <div onClick={() => togglePassword('confirmPassword')} className="font-weight-bold font-sm text-black-50 clickable position-absolute-top-end mt-.75">
            {passwordVisibilityState.confirmPasswordToggle}
          </div>
          <FormFeedback>{passwordRepeat.message}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <Row className='py-3'>
        <Button onClick={() => changePassword(passwordParams)} block color='beplMagenta' className='py-2 button-label'>Save</Button>
      </Row>
    </Form>
  )
}

export default ChangePassword;

