import React from 'react';
import { Row, Col } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { colors } from '../../../../config/constants';

const DashboardEmpty = () => {
  return (
    <Row style={{position:'relative', top:'90px'}}>
      <Col xs={12} className='text-center p-5'>
        <BsSearch color={colors.beplMagenta}  size={45} />
      </Col>
      <Col xs={12} className='text-center'>
        No results found
      </Col>
    </Row>
  )
}

export default DashboardEmpty;
