import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardText, Tooltip } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { FaDesktop, FaChartBar } from 'react-icons/fa';
import { IoIosInformationCircle } from 'react-icons/io';
import { colors } from '../../../../config/constants';

const RECENT_LOGINS_TOOLTIP = 'Logins over the last 30 days';

const AdminUserDetails = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState({
    recentLogins: false
  });

  const toggleTooltipRecentLogins = () => {
    setTooltipOpen({...tooltipOpen, recentLogins: !tooltipOpen.recentLogins});
  }

  // Collect relevant user stats
  const impersonatedUser = props.userList?.find((u) => u.id === props.impersonate);
  const lastLogin = impersonatedUser ? impersonatedUser.lastLogin : props.currentUser.lastLogin;
  const recentLogins = impersonatedUser ? impersonatedUser?.recentLogins : props.currentUser.recentLogins;
  const loginDate = lastLogin ? new Date(lastLogin).toLocaleDateString() : "Never";

  if (props.showLarge) {
    return (
      <>
        <Col xs={isMobile ? 12 : 3} className={isMobile ? 'p-1' : 'px-1'}>
          <Card>
            <CardBody className='py-1'>
              <CardTitle className='text-size-24'>
                Last Login
              </CardTitle>
              <CardText className='font-weight-bold text-size-36'>
                <FaDesktop color={colors.beplDarkBlue} /> {loginDate}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col xs={isMobile ? 12 : 3} className={isMobile ? 'p-1' : 'px-1'}>
          <Card>
            <CardBody className='py-1'>
              <CardTitle className='text-size-24'>
                Recent Logins <span id='idTooltipRecentLogins'>
                  <IoIosInformationCircle color={colors.beplMagenta} />
                </span>
                <Tooltip placement='top' 
                  isOpen={tooltipOpen.recentLogins} 
                  target='idTooltipRecentLogins' 
                  toggle={toggleTooltipRecentLogins}>
                  {RECENT_LOGINS_TOOLTIP}
                </Tooltip>
              </CardTitle>
              <CardText className='font-weight-bold text-size-36'>
                <FaChartBar color={colors.beplDarkBlue} /> {recentLogins}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
  else {
    return (
      <>
        <Col xs={isMobile ? 12 : 3} className={isMobile ? 'p-1' : 'px-1'}>
          <Card>
            <CardBody className='py-1'>
              <Row>
                <Col xs={8} className='text-size-24'>
                  Last Login
                </Col>
                <Col className='font-weight-bold d-flex justify-content-end align-items-center text-size-16'>
                  {loginDate}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs={isMobile ? 12 : 3} className={isMobile ? 'p-1' : 'px-1'}>
          <Card>
            <CardBody className='py-1'>
              <Row>
                <Col xs={8} className='text-size-24'>
                  Recent Logins <span id='idTooltipRecentLogins'>
                    <IoIosInformationCircle color={colors.beplMagenta} />
                  </span>
                  <Tooltip placement='top' 
                    isOpen={tooltipOpen.recentLogins} 
                    target='idTooltipRecentLogins' 
                    toggle={toggleTooltipRecentLogins}>
                    {RECENT_LOGINS_TOOLTIP}
                  </Tooltip>
                </Col>
                <Col className='font-weight-bold d-flex justify-content-end align-items-center text-size-16'>
                  {recentLogins}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default AdminUserDetails;