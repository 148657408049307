import React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { CardBody, Row, Col } from 'reactstrap';
import { If, Then, Else } from 'react-if';

const UploadedInvoiceItemsSortHeader = ({ sortState, sortBy }) => {
  const sortableColumn = (shortName, longName, xs) => {
    return (
      <Col onClick={() => sortBy(shortName)}
        className={'clickable' + (sortState.sortBy === shortName ? ' text-primary' : '')}
        xs={xs}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
          <Then>
            <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }

  return (
    <CardBody className='py-2 bg-white border-top border-left border-right position-sticky' style={{ top: '0px', zIndex: 50 }}>
      <Row className='text-muted text-size-10 line-height-11 text-uppercase'>
        {sortableColumn('description', 'Item Description', 3)}
        {sortableColumn('brand', 'Brand', 3)}
        {sortableColumn('pack', 'Packsize', 1)}
        {sortableColumn('totalPrice', 'Total Price', 2)}
        {sortableColumn('quantity', 'Quantity', 1)}
        {sortableColumn('itemCode', 'Item Code', 1)}
        {sortableColumn('uom', 'UOM', 1)}
      </Row>
    </CardBody>
  );
};

export default UploadedInvoiceItemsSortHeader;