export const colors = {
  //Blue
  blue: '#0071CE',
  blueDark: '#23408F',
  blueBasic: '#006DBA',
  blueSec: '#334F9A',
  //White
  white: '#FFFFFF',
  //Black
  black: '#202020',
  shadowLight: '#00001A',
  //Gray
  gray: '#8D8D90',
  grayLight: '#D7D8D9',
  grayLighter: '#EAEAEA',
  grayDark: '#909090',
  grayDarker: '#7F8083',
  grayNew: '#F5F5F5',
  grayText : '#76777B',
  grayBg: '#F0F0F2',
  //Yellow
  yellow: '#FA9016',
  yellowDark: '#F18C18',
  //Purple
  purple: '#766ED7',
  //Green
  green: "#00C283",
  //Red
  red: '#DC3545',
  redLight: "#FC745A",
  // Buyers Edge Platform colors
  beplBlue: "#314175",
  beplDarkBlue: "#1D1B4C",
  beplMagenta: "#CF3F7C"
};

export const selectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: 2,
      borderColor: state.isFocused ? '#4fafff' : '#ced4da',
      ':hover': {
        borderColor: '#4fafff'
      },
      boxShadow: 'none'
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2
    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      padding: '0.375rem 0.75rem'
    };
  },
  input: (provided, state) => {
    return {
      ...provided,
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      marginLeft: 0,
      marginRight: 0,
      fontSize: 16
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: '#76777B',
      marginLeft: 0,
      marginRight: 0,
      fontSize: 16
    };
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'none'
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused ? '#4fafff' : '#76777B',
      ':hover': {
        color: '#4fafff'
      }
    };
  },
};

export const randomColors = [
  '#263238', '#b71c1c', '#78909c', '#ef5350',
  '#424242', '#c2185b', '#212121', '#f06292',
  '#4e342e', '#7b1fa2', '#795548', '#ba68c8',
  '#bf360c', '#5e35b1', '#ff7043', '#7c4dff',
  '#ff9800', '#283593', '#ff6d00', '#ffab00',
  '#304ffe', '#ffca28', '#2962ff', '#ffd600',
  '#2196f3', '#ffea00', '#0091ea', '#03a9f4',
  '#00b8d4', '#00acc1', '#009688', '#00695c',
  '#00c853', '#388e3c', '#2e7d32', '#558b2f',
  '#33691e', '#827717', '#37474f', '#455a64',
  '#546e7a', '#607d8b', '#616161', '#757575',
  '#3e2723', '#5d4037', '#6d4c41', '#8d6e63',
  '#a1887f', '#dd2c00', '#ff3d00', '#d84315',
  '#e64a19', '#f4511e', '#ff5722', '#1b5e20',
  '#43a047', '#4caf50', '#00897b', '#00796b',
  '#004d40', '#006064', '#00838f', '#0097a7',
  '#ff1744', '#00bcd4', '#039be5', '#0288d1',
  '#0277bd', '#01579b', '#1e88e5', '#1976d2',
  '#1565c0', '#0d47a1', '#448aff', '#2979ff',
  '#7986cb', '#ff4081', '#5c6bc0', '#3f51b5',
  '#3949ab', '#536dfe'
];