import React from 'react';
import useIntelList from './useIntelList';
import { Row, Col, CardBody, Spinner } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { If, Then, Else } from 'react-if';
import numeral from 'numeral';

const IntelListRow = (props) => {
  let { name, loading, totalVolume, competitorVolume, competitorVolumePerc, dcn, noData } = props;
  const { goToSeeDetails } = useIntelList.useRow(props);

  if (dcn === '') {
    dcn = '--';
  }

  if(isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='font-weight-bold'>
            {loading ? <Spinner color='primary' /> : name}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>
            Total Volume
          </Col>
          <Col className='text-right'>
            {loading ? <Spinner color='primary' /> : numeral(totalVolume).format('$0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='text-muted'>
            Competitor Volume
          </Col>
          <Col className='w-fc text-right'>
            {loading ? <Spinner color='primary' /> : numeral(competitorVolume).format('$0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='text-muted'>
            % Competitor Volume
          </Col>
          <Col className='w-fc text-right'>
            {loading ? <Spinner color='primary' /> : numeral(competitorVolumePerc).format('0%')}
          </Col>
        </Row>
        <Row className='py-2'>
          <Col className={'see-details-label text-right text-' + (noData ? 'muted' : 'beplMagenta')} onClick={noData ? null : goToSeeDetails}>
            See Details
          </Col>
        </Row>
      </CardBody>
    );
  }
  return (
    <CardBody className='border-top py-2 px-3 hover-row'>
      <Row xs={6}>
        <Col>
          {dcn}
        </Col>
        <Col>
          {name}
        </Col>
        <If condition={loading}>
          <Then>
            <Col className='text-center'>
              { loading ? <Spinner color='primary' /> : null}
            </Col>
          </Then>
          <Else>
            <Col>
              {numeral(totalVolume).format('$0,0.00')}
            </Col>
            <Col>
              {numeral(competitorVolume).format('$0,0.00')}
            </Col>
            <Col>
              {numeral(competitorVolumePerc).format('0%')}
            </Col>
            <Col 
              className={'see-details-label w-fc text-right text-' + (noData ? 'muted' : 'beplMagenta clickable')} 
              onClick={noData ? null : goToSeeDetails}>
              See Details
            </Col>
          </Else>
        </If>
      </Row>
    </CardBody>
  );
};

export default IntelListRow;
