import React, { useEffect } from 'react';
import { Container, Row, Spinner, Col, Card, Button, ButtonGroup } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import InputSearch from '../../../sharedComponents/InputSearch/InputSearch';
import RebateChecksSortHeader from './Subcomponents/RebateChecksSortHeader';
import useRebateChecks from './useRebateChecks';
import { colors } from '../../../config/constants';
import { BsSearch } from 'react-icons/bs';
import { calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack } from '../../../sharedComponents/Helper/calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack';
import ReactSelect from 'react-select';
import RebateChecksRow from './Subcomponents/RebateChecksRow';
import numeral from 'numeral';


const RebateChecksList = () => {
  const { sortState, sortBy, merchantsWithChecks, search, setMerchantsSearch, merchantsLoadStatus, merchantsLoading,
    timeframe, setTimeframe, setFilter, downloadCheck, paymentRoutingOptions, generatePayNoPayReport, setRowsSelected, rowsSelected, selectAll, allSelected, triggerBulkExport,
    downloadVouchers, downloadCheckDetails, setDownloadCheckDetails, setDownloadVouchers, bulkExportDownloading, triggerBulkPrint, bulkPrintDownloading, downloadingPayNoPaySummary } = useRebateChecks.useChecks();
  const { exportCheckData } = useRebateChecks.useExportData();

  const customersMap = merchantsWithChecks.map((merchant, ind) => {
    return <RebateChecksRow selectRow={setRowsSelected} rowsSelected={rowsSelected} exportCheckData={exportCheckData} downloadCheck={downloadCheck} key={ind} {...merchant} />
  });

  const totalPayments = merchantsWithChecks.length;
  const totalAmount = merchantsWithChecks.reduce((a, b) => a + b.check.amount, 0);
  let totalAchAmount = 0;
  let totalCheckAmount = 0;

  merchantsWithChecks.forEach(m => {
    let check = m.check;
    if (check.paymentmethod === 'Check') {
      totalCheckAmount += check.amount;
    } else {
      totalAchAmount += check.amount;
    }
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);


  const removeQtrsBefore23Q2 = (options) => {
    let removed = options.filter(op => {
      return op.value !== '22Q4' && op.value !== '23Q1'
    })
    return removed;
  }




  let quarterOptions = calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack(6);
  quarterOptions = removeQtrsBefore23Q2(quarterOptions);

  return (
    <Container fluid className='bg-grayBg flex-grow-1 px-4 m-0'>
      <Row className={`d-flex main-header text-size-20 m-0 mt-3`}>Rebate Vouchers</Row>
      <Row className="m-0 align-items-center mt-2 position-relative" style={{ zIndex: 30 }}>
        <Col className={`p-0 ${isMobile ? '' : 'pb-3 pt-2'}`} xs={isMobile ? 12 : 3}>
          <InputSearch clearValue={() => setMerchantsSearch('')} value={search} onChange={(e) => setMerchantsSearch(e.target.value)} />
        </Col>
        <Col className={`${isMobile ? 'mt-2' : 'ml-2 pb-3 pt-2'} m-0 p-0  z-1040`} xs={isMobile ? 12 : 2} >
          <ReactSelect defaultValue={{ label: quarterOptions.find(op => op.value === timeframe).label, value: timeframe }} options={quarterOptions} onChange={setTimeframe} />
        </Col>
        <Col className={`${isMobile ? 'mt-2' : 'ml-2 pb-3 pt-2'} m-0 p-0 z-1030`} xs={isMobile ? 12 : 2}>
          <ReactSelect defaultValue={{ label: 'All', value: 'all' }} options={paymentRoutingOptions} onChange={setFilter} />
        </Col>
        <div className={`${isMobile ? 'p-0 mt-2' : 'mx-2 pb-3 pt-2'}`}>
          <Button onClick={() => {return !downloadingPayNoPaySummary ? generatePayNoPayReport() : () => {}}} className='py-2 button-label' size='sm' color='beplMagenta'>
            {downloadingPayNoPaySummary ?<div style={{width: '162px'}}><Spinner  size="sm"></Spinner> </div> : 'Pay / No Pay Summary'}
          </Button>
        </div>
        {Object.keys(rowsSelected).length > 0 ?
          <Col style={{ marginTop: '-5px' }} className={`p-0 mx-0 d-flex justify-content-start flex-column ${isMobile ? 'mt-2 ml-2' : ''}`}>
            <Row className="p-0 m-0">
              <ButtonGroup className="d-flex align-items-center">
                Details
                <Button
                  color="primary p-2 ml-2"
                  outline
                  style={{ height: '10px', width: '10px' }}
                  onClick={() => setDownloadCheckDetails(downloadCheckDetails ? false : true)}
                  active={downloadCheckDetails}
                >
                </Button>

              </ButtonGroup>
              <ButtonGroup className="d-flex align-items-center ml-2">
                Vouchers
                <Button
                  color="primary p-2 ml-2"
                  outline
                  style={{ height: '10px', width: '10px' }}
                  onClick={() => setDownloadVouchers(downloadVouchers ? false : true)}
                  active={downloadVouchers}
                >
                </Button>

              </ButtonGroup>
            </Row>

            <Row className={`m-0 p-0 font-weight-bold text-primary `}>
              {bulkExportDownloading ?
               <div className='d-flex justify-content-center align-items-center' style={{width: '80px'}}><Spinner size="sm"></Spinner></div> 
                : <div onClick={triggerBulkExport} className='clickable'>Download</div>}

              <div className='mx-2 text-beplMagenta'>|</div>
              {bulkPrintDownloading ? 
              <div className='d-flex justify-content-center align-items-center' style={{width: '80px'}}><Spinner size="sm"></Spinner></div>
                : <div onClick={triggerBulkPrint} className='clickable'>Print</div>}
            </Row>


          </Col>
          : false}
      </Row>
      <Row className={`${isMobile ? 'd-flex flex-column' : 'd-flex flex-row'} text-size-15 m-0 mt-3 p-0`}>
        <Col className="d-flex">
          <Col className='d-flex p-0'>
            <div className='mr-2 font-weight-bold text-size-17'>Total Payments:</div>
            <div>{totalPayments}</div>
          </Col>
          <Col className='d-flex p-0'>
            <div className='mr-2 font-weight-bold text-size-17'>Total Amount:</div>
            <div>{numeral(totalAmount).format('$0,0.00')}</div>
          </Col>
        </Col>
        <Col className='d-flex'>
          <Col className='d-flex p-0'>
            <div className='mr-2 font-weight-bold text-size-17'>Total Check:</div>
            <div>{numeral(totalCheckAmount).format('$0,0.00')}</div>
          </Col>
          <Col className='d-flex p-0'>
            <div className='mr-2 font-weight-bold text-size-17'>Total ACH:</div>
            <div>{numeral(totalAchAmount).format('$0,0.00')}</div>
          </Col>
        </Col>

      </Row>
      {merchantsWithChecks.length > 0 && isMobile ? <Row className="m-0 p-0 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            color="primary p-2"
            outline
            style={{ marginRight: '16px' }}
            onClick={selectAll}
            active={allSelected}
          >
          </Button>
        </ButtonGroup>
      </Row> : false}


      {merchantsLoadStatus === 'success' && customersMap.length > 0 ?
        <>
          {!isMobile ? <Col className="mt-3 w-100 p-0 position-sticky" style={{ top: 0, zIndex: 20 }}>
            <RebateChecksSortHeader sortBy={sortBy} sortState={sortState} selectAll={selectAll} allSelected={allSelected} />
          </Col> : false}
          <Card className={`${isMobile ? 'mt-3' : ''}`}>
            {customersMap}
          </Card>
        </> :
        (merchantsLoading) ?
          <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row> :
          (merchantsLoadStatus === 'success' && customersMap.length === 0) ?
            <Row style={{ position: 'relative', top: '90px' }}>
              <Col xs={12} className='text-center p-3'>
                <BsSearch color={colors.beplMagenta} size={45} />
              </Col>
              <Col xs={12} className='text-center font-weight-bold'>
                No Checks For This Quarter
              </Col>
            </Row> : <Row style={{ position: 'relative', top: '90px' }}>
              <Col xs={12} className='text-center p-3'>
                <BsSearch color={colors.beplMagenta} size={45} />
              </Col>
              <Col xs={12} className='text-center font-weight-bold'>
                Server Error
              </Col>
            </Row>

      }
    </Container>
  )
}

export default RebateChecksList;