import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { IoIosOptions } from 'react-icons/io';
import { MdFilterList } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../reducers/ModalsReducer/ModalsActions';
import RebatesFilterModal from './Modals/RebatesFilterModal';
import RebatesSortByModal from './Modals/RebatesSortByModal';
import InputSearch from '../../../sharedComponents/InputSearch/InputSearch';
import RebatesContent from './RebatesContent';
import RebatesEmpty from './RebatesEmpty';
import useRebates from './useRebates';
import RebatesLoading from './RebatesLoading';
import { If } from 'react-if';

const RebatesMobile = (props) => {
  const { setFilterModal, onClickSearch, onClickReset, rebateList, rebateLoadStatus, mobileRebateSortOptions, ...rest } = props;
  const dispatch = useDispatch();
  const { search } = useRebates.useTop(props);

  const showFilters = () => {
    const dispatchThis = showModal(RebatesFilterModal, { onClickSearch, onClickReset: onClickReset, ...rest });
    setFilterModal(dispatchThis.payload.props.id);
    dispatch(dispatchThis);
  };

  const showSortByModal = () => {
    const modalProps = {
      title: 'Sort By',
      options: mobileRebateSortOptions,
      sortBy: props.sortBy
    };
    const dispatchThis = showModal(RebatesSortByModal, modalProps);
    dispatch(dispatchThis);
  };

  return (
    <Fragment>
      <Row className='py-3 align-items-center'>
        <Col>
          <InputSearch placeholder='Search for MIN, description or manufacturer...'  {...search} onEnter={onClickSearch} />
        </Col>
        <Col className='w-fc pl-0 text-beplBlue'>
          <IoIosOptions onClick={showFilters} />
        </Col>
        <Col className='w-fc pl-0 text-beplBlue'>
          <MdFilterList size={20} onClick={showSortByModal} />
        </Col>
      </Row>
      <If condition={rebateLoadStatus === 'loading'}>
        <RebatesLoading />
      </If>
      <If condition={rebateLoadStatus === 'complete' && rebateList.length !== 0}>
        <RebatesContent {...rest} />
      </If>
      <If condition={rebateLoadStatus === 'complete' && rebateList.length === 0}>
        <RebatesEmpty reason={"No results found"} />
      </If>
      <If condition={rebateLoadStatus === 'failed'}>
        <RebatesEmpty reason={"Couldn't load rebates, try refreshing"} />
      </If>
    </Fragment>
  );
};

export default RebatesMobile;
