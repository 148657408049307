import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import HeaderLogo from '../../../sharedComponents/Header/HeaderLogo';

const Maintenance = () => {
    return (
        <Fragment>
            <HeaderLogo logo={process.env.REACT_APP_COMPANY_LOGO} />
            <Row className='d-flex justify-content-center align-items-center'>
                <img src='/maintenance_cat.jpg' alt='Cat Playing with Wires' />
                <Row className='text-size-36 main-header text-beplMagenta'>
                    Our Portal is Currently Down for Maintenance. Please Check Back Later.
                </Row>
            </Row>
        </Fragment>
    );
}

export default Maintenance;