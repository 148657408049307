import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from '../../reducers/NotificationsReducer/NotificationsActions';

const useNotification = (id) => {
  const dispatch = useDispatch();
  const remove = () => {
    dispatch(removeNotification(id));
  };
  const notifications = useSelector(({ notifications }) => notifications.notifications);

  useEffect(() => {
    setLimitedNotifications(notifications.slice(0, 4));

  }, [notifications])
  const [limitedNotifications, setLimitedNotifications] = useState([]);


  return {
    remove,
    limitedNotifications,
    notifications
  };
};

export default useNotification;