import useForm from "../../sharedComponents/useForm/useForm"
import Joi from "@hapi/joi";

const initialState = {
  platformId: '',
  partnerName: '',
  contactName: '',
  email: '',
  password: '',
  passwordRepeat: ''
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field]: action.value
  };
};

const platformIdValidation = (value, helpers) => {
  const pattern = /^CP-[0-9]{6}/g;
  const test = pattern.test(value);

  if (!test) {
    return helpers.error('any.invalid');
  }

  return value;
};

const schema = Joi.object({
  platformId: Joi.string().custom(platformIdValidation).required().label('Partner Platform Id'),
  partnerName: Joi.string().min(3).required().label('Partner Name'),
  contactName: Joi.string().min(3).required().label('Contact Name'),
  email: Joi.string().email({tlds: {allow: false}}).required().trim().label('Email'),
  password: Joi.string().min(6).max(20).required().label('Create Password'),
  passwordRepeat:  Joi.string().valid(Joi.ref('password')).messages({'any.only': `Passwords don't match`}),
});

const useSignUpForm = () => {
  let { form, validate } = useForm(reducer, initialState, schema);

  return {
    ...form,
    validate
  };
};

export default useSignUpForm;