import { useEffect } from "react";
import authApi from "../../api/authApi";
import { showModal } from "../../reducers/ModalsReducer/ModalsActions";
import AlertModal from "../../sharedComponents/AlertModal/AlertModal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { spinner } from "../../reducers/UIReducer/UIActions";

const useActivate = (token) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(spinner.add());
    authApi.activate(token).post().then(res => {
      dispatch(spinner.subtract());
      history.push({
        pathname: '/login',
        state: {
          activate: true
        }
      });
    }).catch(err => {
      dispatch(spinner.subtract());
      let msg = `Something went wrong`
      if (err) {
        if (err.data && err.data.message) {
          msg = err.data.message;
        }
      }
      const modalProps = {
        title: msg,
        buttonClick: () => {
          history.push('/login');
        }
      };
      dispatch(showModal(AlertModal, modalProps));
    });
    //eslint-disable-next-line
  }, []);
};

export default useActivate;