import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import HeaderLogo from '../../sharedComponents/Header/HeaderLogo';
import CreatePasswordForm from './CreatePasswordForm';

const CreatePassword = () => {
  return (
    <Fragment>
      <HeaderLogo logo={process.env.REACT_APP_COMPANY_LOGO} />
      <Container fluid className='flex-grow-1'>
        <Row className='justify-content-center'>
          <Col style={{maxWidth: '400px'}}>
            <Row className='py-5'>
              <Col className='text-center text-size-20 font-weight-bold'>Create Password</Col>
            </Row>
            <CreatePasswordForm />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CreatePassword;