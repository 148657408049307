import Axios from "axios";

const url = '/auth';

const authApi = {
  login: {
    post: (params) => {
      return Axios.post(`${url}/login`, params);
    }
  },
  mslogin: {
    post: (params) => {
      return Axios.post(`${url}/microsoft`, params);
    }
  },
  session: {
    post: (params) => {
      return Axios.post(`${url}/session`, params);
    }
  },
  signUp: {
    post: (params) => {
      return Axios.post(`${url}/sign-up`, params);
    }
  },
  activate: (token) => {
    const post = (params) => {
      return Axios.post(`${url}/activate/${token}`, params);
    };
    return {
      post
    };
  },
  requestPasswordReset: {
    post: (params) => {
      return Axios.post(`${url}/request-password-reset`, params);
    }
  },
  resetPassword: (token) => {
    return {
      post: (params) => {
        return Axios.post(`${url}/reset-password/${token}`, params);
      }
    }
  }
};

export default authApi;