import React from 'react';
import { Col } from 'reactstrap';

const DataTable = (props) => {
    const { data } = props;

    let trArray = [];
    for (let i = 0; i < data.length; i++) {
        const { dcn, distributor, distributorPlatformId, volume, competitor, isPreferredDistributor, percentage } = data[i];
        let tr = (
        <tr key={`${dcn}-${distributor}-${volume}-${i}`}>
            <td>{dcn}</td>
            <td>{distributor}</td>
            <td>{distributorPlatformId}</td>
            <td>{volume}</td>
            <td>{competitor.toString()}</td>
            <td>{isPreferredDistributor}</td>
            <td>{percentage}</td>
        </tr>);
        trArray[i] = tr;
    }

    return (
        <Col className='pt-2 text-size-16 text-center'>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        <th scope='col'>DCN</th>
                        <th scope='col'>Distributor</th>
                        <th scope='col'>Distributor Platform ID</th>
                        <th scope='col'>Volume</th>
                        <th scope='col'>Competitor</th>
                        <th scope='col'>Is Preferred Distributor</th>
                        <th scope='col'>Percentage</th>
                    </tr>
                </thead>
                <tbody>
                    {trArray}
                </tbody>
            </table>
        </Col>
    );
}

export default DataTable;