import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntelTop from './IntelTop';
import IntelGraph from './IntelGraph/IntelGraph';
import IntelLineChart from './IntelLineChart/IntelLineChart';
import IntelList from './IntelList/IntelList';
import IntelDetail from './IntelDetail/IntelDetail';
import { intelVisible } from '../../../reducers/IntelReducer/IntelActions';
import { setLastViewedIntelUrl } from '../../../reducers/UIReducer/UIActions';
import LoadingMerchantsModal from '../../../sharedComponents/LoadingMerchantsModal.js/LoadingMerchantsModal';

const Intel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const lastViewedUrl = useSelector(({ ui }) => ui.intelLastViewedUrl);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (lastViewedUrl !== '/intel/list') {
      history.push(lastViewedUrl);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(intelVisible(true));

    // Clean up; runs on Intel unmount
    return () => {
      dispatch(intelVisible(false));
    };
  }, [dispatch]);


  useEffect(() => {
    dispatch(setLastViewedIntelUrl(location.pathname));
    // eslint-disable-next-line
  }, [location]);



  return (
    <Container fluid className='bg-grayBg flex-grow-1'>
      <IntelTop />
      <Switch>
        <Route exact path='/intel/linechart' component={IntelLineChart} />
        <Route exact path='/intel/graph' component={IntelGraph} />
        <Route exact path='/intel/list' component={IntelList} />
        <Route exact path='/intel/detail/:id/:view' component={IntelDetail} />
        <Route>
          <Redirect to='/intel/list' />
        </Route>
      </Switch>
      <LoadingMerchantsModal />
    </Container>
  );
};

export default Intel;