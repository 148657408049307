import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

const NoDataRows = () => {
  return (
    <Fragment>
      <Row className=''>
        <Col>
          <div className='rounded-pill mb-3 w-50 bg-grayBg h-16px' />
        </Col>
        <Col xs={3}>
          <div className='rounded-pill mb-3 w-100 bg-grayBg h-16px' />
        </Col>
      </Row>
      <Row className=''>
        <Col>
          <div className='rounded-pill mb-3 w-75 bg-grayBg h-16px' />
        </Col>
        <Col xs={3}>
          <div className='rounded-pill mb-3 w-100 bg-grayBg h-16px' />
        </Col>
      </Row>
      <Row className=''>
        <Col>
          <div className='rounded-pill mb-3 w-75 bg-grayBg h-16px' />
        </Col>
        <Col xs={3}>
          <div className='rounded-pill mb-3 w-100 bg-grayBg h-16px' />
        </Col>
      </Row>
      <Row className=''>
        <Col>
          <div className='rounded-pill mb-3 w-50 bg-grayBg h-16px' />
        </Col>
        <Col xs={3}>
          <div className='rounded-pill mb-3 w-100 bg-grayBg h-16px' />
        </Col>
      </Row>
    </Fragment>
  );
};

export default NoDataRows;