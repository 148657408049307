import React from "react";
import { Row, Spinner, Col, Button, Container } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { showModal } from "../../../reducers/ModalsReducer/ModalsActions";
import { AiFillCheckCircle, AiFillExclamationCircle } from 'react-icons/ai';
import { BsSearch, BsFillTrashFill } from 'react-icons/bs';
import { colors } from "../../../config/constants";
import InvoiceUploadModal from "./Subcomponents/InvoiceUploadModal";
import numeral from "numeral";
import useInvoice from "./useInvoice";
import DeleteConfirmationModal from "./Subcomponents/DeleteConfirmationModal";
import ViewInvoiceImageModal from "./Subcomponents/ViewInvoiceImageModal";


const UploadedInvoice = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const invoiceImages = useSelector(({ invoices }) => invoices.uploadedInvoiceImages);
  const { invoiceInfo, error, breadCrumbs, imagesLoadError, imagesLoaded, deleteInvoiceImage } = useInvoice.useUploadedInvoice(id);

  const showInvoiceUploadModal = () => {
    dispatch(showModal(InvoiceUploadModal, {
      reupload: true, invoiceId: id, invoiceNameProp: invoiceInfo.description,
      invoiceLocationProp: { value: invoiceInfo.restaurantName || invoiceInfo.merchant_name, label: invoiceInfo.restaurantName || invoiceInfo.merchant_name }
    }));
  }

  const showDeleteConfirmationModal = (imageId) => {
    dispatch(showModal(DeleteConfirmationModal, { deleteImage: { imageId, invoiceId: id, deleteInvoiceImage } }))
  }

  const showViewInvoiceImageModal = (imageSrc) => {
    dispatch(showModal(ViewInvoiceImageModal, { imageSrc }))
  }



  const images = () => {
    if (imagesLoaded && invoiceImages.length !== 0) {
      return invoiceImages.map(image => {
        return (
          <Col xs={isMobile ? 12 : 3} key={image.attachment_id} className="p-4 text-size-14 clickable">
            <img onClick={() => showViewInvoiceImageModal(image.src)} className="w-100" alt={"InvoiceImage: " + image.filename} src={image.src} />
            <div className="d-flex justify-content-between mt-2">
              <div className="">{image.filename.substring(0, 20) + '...'}</div>
                 
              <BsFillTrashFill 
               onMouseOver={({ target }) => target.style.color = "#DD3444"}
               onMouseOut={({ target }) => target.style.color = "grey"}
              onClick={() => showDeleteConfirmationModal(image.attachment_id)} className="clickable" size="17" color="grey" />
            </div>
          </Col>
        )
      })
    } else if (imagesLoaded && invoiceImages.length === 0) {
      return (
        <Row className='w-100' style={{ position: 'relative' }}>
          <Col xs={12} className='text-center p-5'>
            <BsSearch color={colors.beplMagenta} size={45} />
            <div className='mt-3 main-header text-center'>No Images</div>
          </Col>
        </Row>
      )
    } else if (imagesLoaded && imagesLoadError) {
      return (
        <Row style={{ position: 'relative' }}>
          <Col xs={12} className='text-center p-5'>
            <BsSearch color={colors.beplMagenta} size={45} />
            <div className='mt-3 main-header text-center'>{imagesLoadError}</div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row className="d-flex justify-content-center align-items-center h-25vh w-100"><Spinner color='primary' /></Row>
      )
    }
  }

  if (invoiceInfo && invoiceInfo.status) {
    return (
      <Container fluid className='bg-grayBg flex-grow-1 p-0 px-3 pl-4'>
        {id ? <Row className={`pl-3 py-3`}>{breadCrumbs()}</Row> : false}
        <Row className={`m-0 mt-2 pl-0 main-header text-size-20 d-flex align-items-center justify-content-between ${isMobile ? 'flex-column' : ''}`}>
          <Col className="p-0 d-flex align-items-center">
            {invoiceInfo.description ? <div style={{marginTop: '3px'}}>{invoiceInfo.description}</div>: false}
            {invoiceInfo.status ?
              <>
                {invoiceInfo.status.toLowerCase() === 'done' ? <AiFillCheckCircle className="ml-3" size="20" color="green" /> :
                  invoiceInfo.status.toLowerCase() === 'inprocessing' ? <AiFillExclamationCircle className="ml-3" size="20" color="yellow" /> :
                    <AiFillExclamationCircle className="ml-3" size="20" color="#DD3444" />}</>

              : false}
          </Col>
          <Col className={`d-flex p-0 ${isMobile ? '' : 'justify-content-end'}`}>
            {isMobile ? <Button onClick={showInvoiceUploadModal} type="button" color="beplMagenta" className={`${isMobile ? 'w-50 mb-2 mt-2' : ''} mr-2 py-2 px-2 btn btn-beplMagenta btn-sm button-label`}>Add Images</Button>
              : false}
            <Button type="button" color="beplMagenta" onClick={() => history.push('/invoice/' + id + '/items')} className={`${isMobile ? 'w-50 mb-2 mt-2' : ''} py-2 px-2 btn btn-beplMagenta btn-sm button-label`}>View Items</Button>
          </Col>

        </Row>
        {/* <Row>
          <Col className={`text-size-18 m-0 mt-2 align-items-end justify-content-between ${isMobile ? '' : 'd-flex flex-row'}`}>
      
            <Col className={`px-0 justify-content-end ${isMobile ? 'mt-2' : 'd-flex'}`}>
              <Button type="button" color="beplMagenta" onClick={() => history.push('/invoice/' + id + '/items')} className={`${isMobile ? 'w-100 mb-2' : ''} mr-2 py-2 px-2 btn btn-beplMagenta btn-sm button-label`}>View Items</Button>
              <If condition={isExportUser}>
                <Button type="button" color="beplMagenta" className="py-2 px-2 btn btn-beplMagenta btn-sm button-label" style={{ float: 'right' }}>Export Page Details</Button>
              </If>
            </Col>
          </Col>
        </Row> */}
        <Row className={`mt-3 border-top label-text text-size-14 d-flex border-bottom ${isMobile ? 'flex-column' : ''}`}>
          <Col className={`px-0 border-right ${isMobile ? 'w-100' : 'w-50'}`}>
            <Col className='p-0 py-3 px-3 w-100 border-bottom'>
              <div className='main-header text-size-20 align-self-top'>{'General Info'}</div>
            </Col>
            <Col className={`text-size-18 ${isMobile ? 'px-3' : 'p-3 px-5'}`}>
              <Row className="my-3">
                <Col xs={6}>
                  Merchant
                </Col>
                <Col className="d-flex justify-content-end" xs={6}>
                  {invoiceInfo.merchant_name}
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs={6}>
                  Brand
                </Col>
                <Col className="d-flex justify-content-end" xs={6}>
                  {invoiceInfo.contents ? invoiceInfo.contents.brand : 'Not Provided'}
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs={6}>
                  Date
                </Col>
                <Col className="d-flex justify-content-end" xs={6}>
                  {invoiceInfo.createdAt ? new Date(invoiceInfo.createdAt).toDateString() : 'Not Provided'}
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs={6}>
                  Delivery Date
                </Col>
                <Col className="d-flex justify-content-end" xs={6}>
                  {invoiceInfo.deliveryDate ? new Date(invoiceInfo.deliveryDate).toDateString() : 'Not Provided'}
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs={6}>
                  Tax Amount
                </Col>
                <Col className="d-flex justify-content-end" xs={6}>
                  {numeral(invoiceInfo.taxAmount).format('0,0.00')}
                </Col>
              </Row>

            </Col>
          </Col>

          <Col className={`px-0 border-right ${isMobile ? 'w-100' : 'w-50'}`}>
            <Col className='p-0 py-3 px-3 w-100 border-bottom'>
              <div className='main-header text-size-20 align-self-top'>{'Status'}</div>
            </Col>
            <Col className={`text-size-18 ${isMobile ? 'px-3' : 'p-3 px-5'}`}>
              <Row className="my-3">
                <Col xs={6}>
                  Status
                </Col>
                <Col className="d-flex justify-content-end" xs={6}>
                  {invoiceInfo.status}
                </Col>
              </Row>
              <Row className="my-3">
                <Col xs={6}>
                  Status Reason
                </Col>
                <Col className="d-flex justify-content-end" xs={6}>
                  {invoiceInfo.reason}
                </Col>
              </Row>

            </Col>
          </Col>
        </Row>
        <Row className="mb-3  label-text text-size-14 d-flex  border-bottom">
          <Col className='px-0 w-100 border-right'>
            <Col className='p-0 py-3 px-3 w-100 border-bottom d-flex justify-content-between align-items-center'>
              <div className='main-header text-size-20 align-self-top'>{'Images'}</div>
              {!isMobile ? <Button onClick={showInvoiceUploadModal} type="button" color="beplMagenta" className={`${isMobile ? 'w-100 mb-2' : ''} mr-2 py-2 px-2 btn btn-beplMagenta btn-sm button-label`}>Add Images</Button> : false}
            </Col>
            <div className="text-size-18 p-3 px-5 d-flex flex-wrap">
              {images()}
            </div>
          </Col>
        </Row>
      </Container >
    );

  } else if (error) {
    return (
      <Row style={{ position: 'relative' }}>
        <Col xs={12} className='text-center p-5'>
          <BsSearch color={colors.beplMagenta} size={45} />
          <div className='mt-3 main-header text-center'>{error}</div>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>

    )
  }


}

export default UploadedInvoice;