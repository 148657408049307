import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { If, Else, Then } from 'react-if';
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from 'react-icons/io';


const UploadedInvoiceSortHeader = ({ sortState, sortBy }) => {
  const sortableColumn = (shortName, longName, colSize) => {
    return (
      <Col xs={colSize} onClick={() => sortBy(shortName)}
        className={'clickable' + (sortState.sortBy === shortName ? ' text-primary' : '')}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
          <Then>
            <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }


  return (
    <CardBody className='py-2 bg-white border-top border-left border-right position-sticky' style={{ top: '0px', zIndex: 50 }}>
      <Row className='text-muted text-size-10 line-height-11 text-uppercase'>
        {sortableColumn('description', 'Invoice Name', 3)}
        {sortableColumn('merchant', 'Location', 3)}
        {sortableColumn('createdAt', 'Upload Date', 2)}
        {sortableColumn('status', 'Status', 2)}
      </Row>
    </CardBody>
  );
}

export default UploadedInvoiceSortHeader;