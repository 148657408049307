import React from 'react';
import useModal from '../../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const FaqVideoModal = ({ id, url, title }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });

  if(!isMobile) {
    modalProps.className = 'faq-upload-modal-wrapper';
    modalProps.contentClassName = 'faq-upload-modal';
  }


  return (
    <Modal  {...modalProps}>
      <ModalHeader {...headerProps} >{`${title}`}</ModalHeader>
      <ModalBody className={`${isMobile ? 'px-2' : ''} pb-0`} >
        <iframe title={title} style={{ width: '100%', height: '100%' }}
          src={url}>
        </iframe>
      </ModalBody>
      <ModalFooter className='justify-content-end'>

      </ModalFooter>
    </Modal>
  )
}

export default FaqVideoModal;