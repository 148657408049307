import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, CardBody, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { MdOutlineTag, MdFitnessCenter } from 'react-icons/md';
import { FaPercent } from 'react-icons/fa';
import { IoIosPricetags } from 'react-icons/io'

const TableRow = ({ din, min, description, manufacturer, manufacturerPlatformId, rebateType, rebateValue, isReveal, deviationValue, product_id, clickReveal, pickItemForItemMatches, matchLoading, matchData, locationPlatformId }) => {
  let rebateTypeIcon;
  //renders a different symbol for each rebate type; defaults to N/A if there is no rebate type
  switch (rebateType) {
    case 'FIXED_AMOUNT_PER_QTY':
      rebateTypeIcon = <MdOutlineTag title='FIXED_AMOUNT_PER_QTY' />;
      break;
    case 'FIXED_AMOUNT_PER_WEIGHT':
      rebateTypeIcon = <MdFitnessCenter title='FIXED_AMOUNT_PER_WEIGHT' />;
      break;
    case 'PERCENT_OF_COST':
      rebateTypeIcon = <FaPercent className='text-size-10' title='PERCENT_OF_COST' />;
      break;
    case 'PERCENT_OF_PRICE':
      rebateTypeIcon = <IoIosPricetags title='PERCENT_OF_PRICE' />;
      break;
    default:
      rebateTypeIcon = 'N/A';
      break;
  }
  const id = product_id;
  const rebateFormat = (rebateValue ? rebateValue : 0).toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  const deviationFormat = (deviationValue ? deviationValue : 0).toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  const rebateTypeReveal = isReveal ? rebateTypeIcon : (rebateType ? 'Y' : 'N');
  const rebateTypeSymbol = rebateType ? rebateType.toString().split('_') : '';
  const symbolPercentDollar = rebateTypeSymbol ? (rebateTypeSymbol.includes('PERCENT') ? '%' : '$') : '';
  const rebateRevealWithSymbol = symbolPercentDollar === '$' ? symbolPercentDollar + rebateFormat : rebateFormat + symbolPercentDollar;
  const rebateReveal = isReveal ? rebateRevealWithSymbol : (rebateValue ? 'Y' : 'N');
  const deviationReveal = isReveal ? '$' + deviationFormat : (deviationValue ? 'Y' : 'N');


  let matchLabel = 'Similar Items';

  if (isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='font-weight-bold text-capitalize'>
            {description ? description.toLowerCase() : description}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>DIN</Col>
          <Col className='text-right'>
            {din}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>MIN</Col>
          <Col className='text-right'>
            {min}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Manufacturer</Col>
          <Col className='text-right'>
            {manufacturer}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Rebate Type</Col>
          <Col className='text-right text-size-13'>
            {rebateTypeReveal}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Rebate</Col>
          <Col className='text-right'>
            {rebateReveal}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Deviated Price</Col>
          <Col className='text-right'>
            {deviationReveal}
          </Col>
        </Row>
        <Row className='p-2 d-flex justify-content-end'>
          <Button className='border-0 text-white bg-beplMagenta text-right reveal-label' onClick={() => clickReveal(id)}>
            Reveal
          </Button>
        </Row>
        <Row className='p-2 d-flex justify-content-end'>
          <Button disabled={!min} className='border-0 text-white bg-beplMagenta text-right reveal-label' onClick={() => pickItemForItemMatches(min, manufacturerPlatformId, locationPlatformId, description)}>
            {matchLabel}
          </Button>
        </Row>
      </CardBody>
    )
  }
  return (
    <CardBody className='border-top pb-2 pt-2 px-3 hover-row bg-white'>
      <Row className='text-size-13 line-height-16 '>
        <Col xs={1} className='text-break'>
          {din}
        </Col>
        <Col xs={1} className='text-break'>
          {min}
        </Col>
        <Col xs={2} className='text-capitalize text-break'>
          {description ? description.toLowerCase() : description}
        </Col>
        <Col xs={2} className='text-break'>
          {manufacturer}
        </Col>
        <Col xs={2} className={'text-break pl-3 ' + (isReveal ? 'text-size-12' : 'text-size-13')}>
          {rebateTypeReveal}
        </Col>
        <Col xs={1} className='text-break'>
          {rebateReveal}
        </Col>
        <Col xs={1} className='text-break'>
          {deviationReveal}
        </Col>
        <div className='text-break d-flex align-items-center pl-0 text-beplMagenta text-right clickable reveal-label reveal-row' onClick={() => clickReveal(id)}>
          {!isReveal ? 'Reveal' : 'Hide'}
        </div>
        <Col xs={1} className="text-break py-0 pl-0 pr-2">
          <Button xs={1} disabled={!min} className='text-size-13 bg-transparent border-0 p-0 text-beplMagenta text-right clickable reveal-label' onClick={() => pickItemForItemMatches(min, manufacturerPlatformId, locationPlatformId, description)}>
            {matchLabel}
          </Button>
        </Col>
      </Row>
    </CardBody>
  );
};

TableRow.protoTypes = {
  min: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  manufacturer: PropTypes.string.isRequired,
  rebate: PropTypes.string.isRequired,
  deviatedPrice: PropTypes.string.isRequired
};

export default TableRow;