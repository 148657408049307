import * as XLSX from 'xlsx';
import { useEffect, useState } from "react";
import rebateChecksApi from '../../api/rebateChecksApi';
import distributorApi from '../../api/distributorApi';
import sortArrayByKey from '../../sharedComponents/Helper/sortArrayByKey';

const useBusinessPanel = () => {

  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showSalesRepsForm, setShowSalesRepsForm] = useState(false);
  const [salesRep, setSalesRep] = useState({});
  const [distributors, setDistributors] = useState([]);
  const [distributorSalesReps, setDistributorSalesReps] = useState([]);
  const [distributorsAssociatedWithRep, setDistributorsAssociatedWithRep] = useState([])
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [confirmOp, setConfirmOp] = useState(false);
  const [role, setRole] = useState({});
  const [addNewRep, setAddNewRep] = useState(false);
  const [newRepEmail, setNewRepEmail] = useState('');
  const [newRepName, setNewRepName] = useState('');
  const [distributorSearch, setDistributorSearch] = useState('');

  const roles = [
    {
      label: 'Director',
      value: 'director',
    },
    {
      label: 'Manager',
      value: 'manager',
    },
    {
      label: 'Auditor',
      value: 'auditor',
    },

  ]

  const [loadingState, setLoadingState] = useState({
    checks: false,
    details: false,
    both: false
  })


  const getDSRs = async () => {
    try {
      let reps = await rebateChecksApi.getDistributorSalesReps();
      setDistributorSalesReps(reps.data);
    } catch (err) {
      setError('Failed to fetch list of sales reps');
    }
  }


  const getDistributors = async () => {
    try {
      const distributors = await distributorApi.getAll();
      setDistributors(distributors.data);
    } catch (err) {
      setError('Failed to fetch list of distributors');
    }
  }

  const getDistributorsAssociatedWithRep = async () => {
    try {
      let salesRepEmail = salesRep.value;
      const { data } = await rebateChecksApi.getDistributorsPerSalesRep({ email: salesRepEmail });
      setDistributorsAssociatedWithRep(data.map(d => d.distributor_id));
    } catch (err) {
      console.log(err)
      setError('Failed to fetch list of distributors associated with sales rep.');
    }
  }

  const addDistributorToRep = async (dist) => {
    setDistributorsAssociatedWithRep([...distributorsAssociatedWithRep, dist.value])
  }


  // const dsrsOnChange = (e) => {
  //   if (e.key === 'Enter') {
  //     e.target.blur();
  //   }
  //   console.log(e.target.value)
  //   setSalesRep({ value: e.target.value, label: e.target.value });
  // }

  const removeDistributorFromRep = async (dist) => {
    let distsAssociatedWithRep = distributorsAssociatedWithRep.slice(0);
    let index = distsAssociatedWithRep.findIndex(d => d === dist.value)
    distsAssociatedWithRep.splice(index, 1);
    setDistributorsAssociatedWithRep(distsAssociatedWithRep);
  }

  const saveDSRChanges = async () => {
    try {
      if (newRepEmail || (salesRep.value.length > 0 && distributors.length > 0)) {
        if(addNewRep) {
          if(newRepName.length < 3 || newRepEmail.length < 7 || distributorsAssociatedWithRep.length === 0 || !role.value) {
            return setError('Missing Fields. Please make sure to fill out all the fields.')
          }
        }
        setLoading(true);
        const payload = {
          email: newRepEmail ? newRepEmail : salesRep.value,
          role: role.value,
          distributors: distributorsAssociatedWithRep,
          name: newRepName
        }
        await rebateChecksApi.updateDistributorsToSalesRep(payload);
        if (addNewRep) {
          setDistributorSalesReps([...distributorSalesReps, { email: newRepEmail, role: role.value }]);
          setSalesRep({label: newRepEmail, value: newRepEmail})
          setAddNewRep(false);
          setNewRepEmail('');
        }
        setLoading(false);
        setComplete(true);
        setTimeout(() => {
          setComplete(false);
        }, 1000)
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setComplete(false)
      setError('Failed to update.');
    }
  }

  const deleteDSR = async () => {
    if (salesRep.value.length > 0) {
      try {
        setLoading(true);
        await rebateChecksApi.deleteDistributorsSalesRep({ email: salesRep.value });
        let distSalesReps = distributorSalesReps.slice(0);
        let repIndex = distSalesReps.findIndex(dsr => dsr.email === salesRep.value);
        distSalesReps.splice(repIndex, 1);
        setDistributorSalesReps(distSalesReps);
        setDistributorsAssociatedWithRep([])
        setSalesRep({});
        setLoading(false);
        setComplete(true);
        setConfirmOp(false)
        setTimeout(() => {
          setComplete(false);
        }, 1000)
      } catch (err) {
        setLoading(false);
        setComplete(false)
        setError('Failed to update.');
        setConfirmOp(false)
      }
    }
  }

  const XlToPdf = async (e, type) => {
    setLoadingState({ ...loadingState, [type]: true });
    try {
      let file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });

        let result = {};
        let filename;
        workbook.SheetNames.forEach(sheetName => {
          filename = sheetName;
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          result[sheetName] = sheetData;
        });

        result = result[filename];

        const response = await rebateChecksApi.exportDataNotOnPortal({ data: result, type });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const accountmaprelationship = result[0]['Account MAP Relationship']
        const a = document.createElement('a');
        a.href = url;
        a.download = `${accountmaprelationship}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoadingState({ ...loadingState, [type]: false });
        e.target.files = [];
      };

      reader.onerror = function (e) {
        setError('Failed to read file.')
      };

      reader.readAsBinaryString(file);
    } catch (err) {
      setError('Failed to convert Xl file.')
    }
  }

  useEffect(() => {
    getDSRs();
    getDistributors()
  }, []);

  useEffect(() => {
    if (salesRep.value) {
      let exists = distributorSalesReps.find(dsp => dsp.email === salesRep.value);
      getDistributorsAssociatedWithRep();

      if (exists) {
        getDistributorsAssociatedWithRep();
      } else {
        setDistributorsAssociatedWithRep([])
      }
    }
    let salesRepObj = distributorSalesReps.find(dsp => dsp.email === salesRep.value);
    if (salesRepObj && salesRepObj.role) {
      setRole({ label: salesRepObj.role.charAt(0).toUpperCase() + salesRepObj.role.slice(1), value: salesRepObj.role });
    } else {
      setRole({ label: '', value: '' });
    }
    // eslint-disable-next-line
  }, [salesRep]);



  const distributorsOps = sortArrayByKey(distributors.map(d => {
    return {
      label: d.nickname,
      value: d.platform_id
    }
  }), 'label', false);

  const distributorSalesRepsOps = sortArrayByKey(distributorSalesReps.map(d => {
    return {
      label: d.email,
      value: d.email
    }
  }), 'label', false);

  let distributorsAssociatedWithRepOps = sortArrayByKey(distributorsOps.filter(d => {
    return distributorsAssociatedWithRep.includes(d.value);
  }), 'label', false);


  distributorsAssociatedWithRepOps = distributorsAssociatedWithRepOps.filter(o => {
    return o.label.includes(distributorSearch);
  })


  return {
    loadingState,
    setLoadingState,
    showExportOptions,
    setShowExportOptions,
    showSalesRepsForm,
    setShowSalesRepsForm,
    error,
    setError,
    XlToPdf,
    distributorsOps,
    distributorSalesRepsOps,
    // dsrsOnChange,
    setSalesRep,
    salesRep,
    distributorsAssociatedWithRepOps,
    addDistributorToRep,
    removeDistributorFromRep,
    saveDSRChanges,
    loading,
    complete,
    deleteDSR,
    confirmOp,
    setConfirmOp,
    roles,
    role,
    setRole,
    addNewRep,
    setAddNewRep,
    newRepEmail, 
    setNewRepEmail,
    newRepName,
    setNewRepName,
    distributorSearch,
    setDistributorSearch
  }
}

export default useBusinessPanel;