import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import useModal from '../../../../sharedComponents/useModal/useModal';
import { MdCheck } from 'react-icons/md';
import { MdOutlineTag, MdFitnessCenter } from 'react-icons/md';
import { FaPercent } from 'react-icons/fa';
import { IoIosPricetags } from 'react-icons/io'

const SortByModal = ({ id, ...props }) => {
  const { modalProps, headerProps, bodyProps, toggle } = useModal(id, { isMenu: true, isFullscreen: true });
  const [dropdown, showDropdown] = useState(false);

  const setSort = (type) => {
    props.sortBy(type, true);
    toggle();
  }
  const dropDown = () => {
    return (
      <div style={{ whiteSpace: 'nowrap' }} className='bg-white rounded w-100 w-full z-1050 text-size-16'>
        <div onClick={() => setSort('PERCENT_OF_COST')} className='p-3 pl-4 align-items-center d-flex border-top'>
          <FaPercent className='mr-2' size={11} color="#CE3F7C" />
          Percent Of Cost
        </div>
        <div onClick={() => setSort('PERCENT_OF_PRICE')} className='p-3 pl-4 align-items-center d-flex border-top'>
          <IoIosPricetags className='mr-2' size={12} color="#CE3F7C" />
          Percent Of Price
        </div>
        <div onClick={() => setSort('FIXED_AMOUNT_PER_WEIGHT')} className='p-3 pl-4 align-items-center d-flex border-top'>
          <MdFitnessCenter className='mr-2' size={12} color="#CE3F7C" />
          Fixed Amount Per Weight
        </div>
        <div onClick={() => setSort('FIXED_AMOUNT_PER_QTY')} className='p-3 pl-4 align-items-center d-flex border-top'>
          <MdOutlineTag className='mr-2' size={13} color="#CE3F7C" />
          Fixed Amount Per Quantity
        </div>
      </div>
    )
  }
  const mapSort = props.options.map((list, i) => {
    if (list.name.includes('Rebate Type')) {
      return (
        <Row className='mx-0 align-items-center border-bottom' key={`sortmodal-${i}`} onClick={() => {showDropdown(dropdown ? false : true) }}>
          <Col className={'py-3' + (list.isActive ? '  font-weight-bold' : '')}>
            {list.name}
          </Col>
          {dropdown ? dropDown() : false}
          {
            list.isActive &&
            <Col className='text-beplBlue w-fc'><MdCheck /></Col>
          }
        </Row>
      )

    } else {
      return (<Row className='mx-0 align-items-center border-bottom' key={`sortmodal-${i}`} onClick={() => { list.click(); toggle(); }}>
        <Col className={'py-3' + (list.isActive ? '  font-weight-bold' : '')}>
          {list.name}
        </Col>
        {
          list.isActive &&
          <Col className='text-beplBlue w-fc'><MdCheck /></Col>
        }
      </Row>
      )
    }
  });

  bodyProps.className += ' p-0';

  return (
    <Modal {...modalProps}>
      <ModalHeader {...headerProps}>{props.title}</ModalHeader>
      <ModalBody {...bodyProps} >
        {mapSort}
      </ModalBody>
    </Modal>
  )
}

export default SortByModal;