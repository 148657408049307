import React from 'react';
import useModal from '../../../../sharedComponents/useModal/useModal';
import { Modal, ModalBody, ModalFooter, Button, Row } from 'reactstrap';
import { isMobile } from 'react-device-detect';


const ViewInvoiceImageModal = ({ id, imageSrc }) => {
  const { modalProps } = useModal(id, { isMenu: true, isFullscreen: true });
  // const uploadFiles = useSelector(({ invoices }) => invoices.invoiceUploadFiles);


  if(!isMobile) {
    modalProps.className = 'img-view-upload-modal-wrapper';
    modalProps.contentClassName = 'img-view-upload-modal';
  }


  return (
    <Modal  {...modalProps}>
      <ModalBody className={`${isMobile ? 'px-2' : ''} pb-0`} >
        <img alt="" className="w-100" src={imageSrc}></img>
      </ModalBody>
      <ModalFooter className='justify-content-end'>
        <Row className={`m-0 d-flex justify-content-between align-items-center ${isMobile ? 'flex-column mt-2' : 'flex-row mt-3'}`}>
          <Row className={`m-0 d-flex  ${isMobile ? 'w-100 justify-content-end mt-4' : ''}`}>
            <Button color="beplMagenta" onClick={() => modalProps.toggle()} className="btn btn-beplMagenta btn-sm button-label py-2 px-2 align-self-start mr-2">
              Close</Button>

          </Row>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default ViewInvoiceImageModal;