export const setUserData = (data) => {
  return {
    type: 'SET_USER_DATA',
    payload: data
  };
};

export const clearUserData = () => {
  return {
    type: 'CLEAR_USER_DATA',
  };
};

export const setUserRolData = (data) => {
  return {
    type: 'SET_USER_ROL_DATA',
    payload: data
  };
};

export const setInviteAll = (data) => {
  return {
    type: 'SET_INVITE_ALL',
    payload: data
  };
};

export const toggleInviteAll = () => {
  return {
    type: 'TOGGLE_INVITE_ALL'
  };
};

export const clearSearchInviteLocation = () => {
  return {
    type: 'CLEAR_SEARCH_INVITE_LOCATION',
  };
};

export const clearSearchInvitedLocation = () => {
  return {
    type: 'CLEAR_SEARCH_INVITED_LOCATION',
  };
};

export const setSearchInviteLocation = (data) => {
  return {
    type: 'SET_SEARCH_INVITE_LOCATION',
    payload: data
  };
};

export const setSearchInvitedLocation = (data) => {
  return {
    type: 'SET_SEARCH_INVITED_LOCATION',
    payload: data
  };
};

export const setLocationCheckedInit = (data) => {
  return {
    type: 'SET_LOCATION_CHECKED_INIT',
    payload: data
  };
};

export const setLocationChecked = (data) => {
  return {
    type: 'SET_LOCATION_CHECKED',
    payload: data
  };
};

export const setLocationCheckedAll = (data) => {
  return {
    type: 'SET_LOCATION_CHECKED_ALL',
    payload: data
  };
};

export const setLocationUncheckedAll = (data) => {
  return {
    type: 'SET_LOCATION_UNCHECKED_ALL'
  };
};

export const setActiveDistributor = (distributor) => {
  return {
    type: 'SET_ACTIVE_DISTRIBUTOR',
    payload: distributor
  };
};

export const applyActiveDistributor = () => {
  return {
    type: 'APPLY_ACTIVE_DISTRIBUTOR',
  };
};

export const setDistributorsList = (list) => {
  return {
    type: 'SET_DISTRIBUTORS_LIST',
    payload: list
  };
};

export const setMerchantsAll = (data) => {
  return {
    type: 'SET_MERCHANTS_ALL',
	  payload: data
  }    
};

export const setInternalAdmin = (data) => {
  return {
    type: 'SET_INTERNAL_ADMIN',
    payload: data
  }
}

export const editUsersRole = (editUserProps) => {
  return {
    type: 'EDIT_USERS_ROLE',
    payload: editUserProps
  }
}

export const setAdmin = (data) => {
  return {
    type: 'SET_ADMIN',
    payload: data
  }
}

export const setImpersonateAs = (data) => {
  return {
    type: 'SET_IMPERSONATE',
    payload: data
  }
}

export const setImpersonateMerchants = (merchants) => {
  return {
    type: 'SET_IMPERSONATE_MERCHANTS',
    payload: merchants
  }
}

export const clearImpersonate = () => {
  return {
    type: 'CLEAR_IMPERSONATE'
  }
}

export const resetImpersonate = (data) => {
  return {
    type: 'RESET_IMPERSONATE',
    payload: data
  }
}

export const setChoosingUserAsGroupUser = (bool) => {
  return {
    type: 'SET_CHOOSE_USER_VIEW',
    payload: bool
  }
}