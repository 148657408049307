function secondsToReadableFormat(seconds) {
  if (isNaN(seconds) || seconds < 0) {
    return 'Invalid input';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursPart = hours > 0 ? `${hours}h ` : '';
  const minutesPart = minutes > 0 ? `${minutes}m ` : '';
  const secondsPart = `${remainingSeconds}s`;

  return hoursPart + minutesPart + secondsPart;
}

export default secondsToReadableFormat;