import React from 'react';
import { Row, Container } from 'reactstrap';
import useIntelGraph from './useIntelGraph';
import { isMobile } from 'react-device-detect';
import IntelGraphLoadMore from './Subcomponents/IntelGraphLoadMore';

const IntelGraph = () => {
  const { intelMap, loadMoreGraph, showMore } = useIntelGraph.useApi();

  return (
    <Container fluid className={isMobile ? 'p-0 mt-3' : ''}>
      <Row>
        {intelMap}
      </Row>
      { showMore && <IntelGraphLoadMore onClick={loadMoreGraph} /> }
    </Container>
  );
};
  
export default IntelGraph;  