import React, { Fragment, useEffect } from 'react';
import { Container, Card, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import useSettings from './useSettings';
import ChangePassword from './ChangePassword/ChangePassword';
import UsersRoles from './UsersRoles/UsersRoles';
import useUsersRoles from '../Settings/UsersRoles/useUsersRoles';


const Settings = () => {
  const { clickChangePassword, clickUsersRoles, clickTabPassword, tabPassword, clickTabRol, tabRol, logOut } = useSettings.useUser();
  const userRolesProps = useUsersRoles.useApi();

  useEffect(() => {
    userRolesProps.getUsers();
    userRolesProps.getAllMerchants();
    //eslint-disable-next-line
  }, []);

  return (
   <Container fluid className={'flex-grow-1 ' + (isMobile ? 'bg-white' : 'bg-grayBg px-3 py-3')}>
    { isMobile ? 
      <Fragment>
        <Row>
          <Col xs={12} className='py-3 border-bottom' onClick={clickChangePassword}>
            Change Password
          </Col>
          <Col xs={12} className='py-3 border-bottom' onClick={clickUsersRoles}>
            Users and Roles
          </Col>
          <Col xs={12} className='py-3 border-bottom' onClick={logOut}>
            Log Out
          </Col>
        </Row>
      </Fragment>
      : 
      <Card>
        <Row className='p-4 text-uppercase text-size-11 line-height-11 ' >
          <Col className={'w-fc clickable'} onClick={clickTabPassword}>
            <Col className={'py-2' + (tabPassword ? ' text-primary border-primary border-bottom': '')}>
              Change Password
            </Col>
          </Col>
          <Col className='w-fc clickable ' onClick={clickTabRol}>
            <Col className={'py-2' + (tabRol ? ' text-primary border-primary border-bottom': '')}>
              Users and Roles
            </Col>
          </Col>
          <Col>
          </Col>
        </Row>
        { tabPassword &&
          <Col xs={4}>
            <ChangePassword />
          </Col>
        }
        { tabRol &&
          <UsersRoles {...userRolesProps} />
        }
      </Card>
    }
    </Container>
  )
}

export default Settings;
