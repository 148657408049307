import numeral from "numeral";

const useDonutCharts = ({noData, data}) => {

  const chartOptions = {
    chart: {
      height: '100%'
    },
    credits: {
      enabled: false
    },
    title: {
      text: noData ? 'No Data Available' : '',
      floating: true,
      verticalAlign: 'middle',
      style: {
        color: '#202020',
        opacity: 0.5
      }
    },
    xAxis: {
      categories: ['A', 'B', 'C'],
    },
    series: [
      { 
        type: 'pie',
        innerSize: '60%',
        data: noData ? [{y: 100, color: '#F0F0F2'}] : data.map(d => {
          return {
            name: d.name,
            y: d.amount,
            color: d.color
          }
        })
      }
    ],
    tooltip: {
      enabled: noData ? false : true,
      backgroundColor: '#000000',
      borderColor: '#000000',
      borderRadius: 5,
      followTouchMove: false,
      followPointer: false,
      formatter: function() {
        return '<span style="color: #ffffff;">'+this.point.name+': </span><span style="color: #ffffff;">'+numeral(this.y).format('$0,0.00')+'</span>';
      },
      outside: true
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
        states: {
          hover: {
            enabled: noData ? false : true,
          },
          inactive: {
            opacity: noData ? 1 : 0.5
          }
        }
      },
      series: {
        animation: false
      }
    }
  };

  return { chartOptions };
};

export default useDonutCharts;