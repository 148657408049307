const useDashboardLineChart = (data, startDate, logarithmic) => {

  const chartOptions = {
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      itemMarginBottom: 20
    },

    credits: {
      enabled: false
    },

    title: {
      text: ''
    },

    xAxis: {
      type: 'datetime'
    },

    yAxis: {
      title: '',
      type: logarithmic ? 'logarithmic' : 'linear'
    },

    tooltip: {
      valueDecimals: 0,
      valuePrefix: '$'
    },

    series: data,

    plotOptions: {
      line: {
        cursor: 'pointer'
      },

      series: {
        pointStart: startDate,
        pointIntervalUnit: 'month',
        events: {
          // Disable clicking of legend keys to hide/show plot lines
          legendItemClick: function() {return false;}
        }
      }
    }
  };

  return { chartOptions };
};

export default useDashboardLineChart;