import React from 'react';
import { Row } from 'reactstrap';


function Title({ title }) {
  return (
    <Row className="m-0 px-0 py-4 text-size-24">
      <div className='border-beplMagenta mr-2 py-6' style={{ borderWidth: "2px", borderStyle: "solid" }}></div>
      {title}
    </Row>
  );
}

export default Title;