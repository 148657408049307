import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback, Button, FormText, Row, Col } from 'reactstrap';
import useSignUpForm from './useSignUpForm';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import authApi from '../../api/authApi';
import { useDispatch } from 'react-redux';
import { showModal } from '../../reducers/ModalsReducer/ModalsActions';
import AlertModal from '../../sharedComponents/AlertModal/AlertModal';
import { spinner } from '../../reducers/UIReducer/UIActions';

const SignUpForm = () => {
  const {
    validate, platformId, partnerName, contactName,
    email, password, passwordRepeat
  } = useSignUpForm();

  const history = useHistory();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState('');

  const signUp = (e) => {
    e.preventDefault();
    setApiError('');
    const errors = validate();
    if (!errors) {
      const params = {
        partnerPlatformId: platformId.value,
        partnerName: partnerName.value,
        contactName: contactName.value,
        email: email.value,
        password: password.value,
        repeatPassword: passwordRepeat.value,
        siteUrl: window.location.origin
      };
      dispatch(spinner.add());
      authApi.signUp.post(params).then(({data}) => {
        dispatch(spinner.subtract());
        const modalProps = {
          title: `Thank you ${contactName.value}!`,
          message: `our information for ${contactName.value} has been submitted successfully. Once we have processed your information, we will send an activation link to your email that you can use to login.`,
          buttonClick: () => history.push('/login')
        };
        dispatch(showModal(AlertModal, modalProps));
      }).catch(err => {
        dispatch(spinner.subtract());
        let alertMsg = '';
        let fullMsg = '';

        if (err.data) {
          if (err.data.errors) {
            if (err.data.errors.message) {
              alertMsg = err.data.errors.message;
              fullMsg = alertMsg;
            }
            else {
              // There could be multiple validation errors returned.
              // Only show the first such error in the alert box,
              // and put them all in a cleaner format under the form
              alertMsg = err.data.errors[0].message;
              err.data.errors.forEach(validationError => {
                fullMsg = fullMsg + validationError.message + "\n";
              });
            }
          }
        }

        // For other, unknown issues
        if (alertMsg === '') {
          alertMsg = 'Something went wrong';
        }

        const modalProps = {
          title: alertMsg
        };
        dispatch(showModal(AlertModal, modalProps));
        setApiError(fullMsg);
      });
    }
  };

  const goToLogin = () => {
    history.push('login');
  };

  return (
    <Form className="text-size-14" onSubmit={signUp}>
      <FormGroup>
        <Label className='label-text-header' for='platformId'>Partner Platform ID *</Label>
        <InputMask mask='CP-999999' {...platformId}>
          {(inputProps) => <Input {...inputProps} placeholder='CP-123456' />}
        </InputMask>
        <FormFeedback>{platformId.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header' for='partnerName'>Partner Name *</Label>
        <Input {...partnerName} placeholder='Partner Name' />
        <FormFeedback>{partnerName.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header' for='contactName'>Contact Name *</Label>
        <Input {...contactName} placeholder='Contact Name' />
        <FormFeedback>{contactName.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header' for='email'>Email *</Label>
        <Input {...email} placeholder='Email' />
        <FormFeedback>{email.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header' for='password'>Create Password *</Label>
        <Input type='password' {...password} placeholder='Password' />
        <FormFeedback>{password.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className='label-text-header' for='passwordRepeat'>Confirm Password *</Label>
        <Input type='password' {...passwordRepeat} placeholder='Repeat Password' />
        <FormFeedback>{passwordRepeat.message}</FormFeedback>
      </FormGroup>
      <Button type='submit' block color='beplMagenta' className='py-2 button-label'>Sign Up</Button>
      <FormGroup>
        <FormText color='red'>{apiError}</FormText>
      </FormGroup>
      <Row className='mb-5'>
        <Col onClick={goToLogin} className='text-beplMagenta form-label text-center clickable'>Already have an account? Login</Col>
      </Row>
    </Form>
  );
};

export default SignUpForm;