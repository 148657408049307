import React from 'react';
import useModal from '../../../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col} from 'reactstrap';
import { isMobile } from 'react-device-detect';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';
import stringSanitizer from 'string-sanitizer';
const OrderHistoryModal = ({ id, date, orders }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });
  const history = useHistory();
  const showOrderedItems = (event) => {
    modalProps.toggle();
    let merchant = stringSanitizer.sanitize.keepSpace(event.resource.merchant);
    history.push('/competitiveinsights/ordertocompetitor/' + 
    merchant.replace('/', '-') + '/' + event.resource.merchantId + '/' + event.resource.distributorId+'/'+event.resource.nonFormattedDate);
  }


  const ordersMap = orders.map((order, i) => {
    return (
      <Col onClick={() => showOrderedItems(order)} className="mt-2 hover-row p-2 clickable" key={order.distributorId + i.toString()}>
        <div className='d-flex align-items-start'>
          <div className='bg-beplMagenta mt-1' style={{ height: '15px', width: '15px', borderRadius: '15px' }}></div>
          <div className='ml-2'>
            <div className="">{'Placed Order To ' + order.resource.distributor}</div>
            <div>
              {'Volume Estimate: ' + numeral(order.resource.volume).format('$0,0.00')}
            </div>
          </div>
        </div>
      </Col>
    )
  });

  return (
    <Modal {...modalProps}>
      <ModalHeader {...headerProps} >Orders</ModalHeader>
      <ModalBody style={{maxHeight: '80vh', overflowY: 'scroll'}} className={`${isMobile ? 'px-1' : ''}`} >
        {ordersMap}
      </ModalBody>
      <ModalFooter className='justify-content-end'>

      </ModalFooter>
    </Modal>
  )
}

export default OrderHistoryModal;