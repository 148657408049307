import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filtersLoadFailed, setRebateData, setRebateListSortBy, setSearch, clearSearch,
  clearRebateData, clearSearchManufacturer, setSearchManufacturer, setManufacturerChecked, clearSearchCategory,
  setSearchCategory, setManufacturerData, setCategoryData, setCategoryChecked, resetRebateFilters, setRebateView,
  setFiltersLoaded, clearFilters, setAllRebateData, clearAllRebateData, setAllRebateLoadStatus, setRebateLoadStatus, setAllRebatePageCount
} from '../../../reducers/RebateReducer/RebateActions';
import { spinner } from '../../../reducers/UIReducer/UIActions';
import rebateDeviationApi from '../../../api/rebateDeviationApi';
import TableRow from '../Rebates/Subcomponents/TableRow';
import sortArrayByKey from '../../../sharedComponents/Helper/sortArrayByKey';
import { applyActiveDistributor, setUserData } from '../../../reducers/UserReducer/UserActions';
import userApi from '../../../api/userApi';
import { updateModal } from '../../../reducers/ModalsReducer/ModalsActions';
import { useHistory } from 'react-router-dom';
import stringSanitizer from 'string-sanitizer';

// import filterArrayByKey from '../../../sharedComponents/Helper/filterArrayByKey';

const useTop = (props) => {
  const dispatch = useDispatch();
  const { onClickReset } = props;
  const search = useSelector(({ rebate }) => rebate.search);

  const onChange = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const clearValue = () => {
    dispatch(clearSearch());
    onClickReset();
  };

  return {
    search: {
      onChange: onChange,
      value: search,
      clearValue: clearValue
    }
  }
}

const useApi = () => {
  const dispatch = useDispatch();
  const activeDistributor = useSelector(({ user }) => user.activeDistributor);
  const activeDistributorApply = useSelector(({ user }) => user.activeDistributorApply);
  const rebateList = useSelector(({ rebate }) => rebate.rebateList);
  const searchRebate = useSelector(({ rebate }) => rebate.search);
  const manufacturerChecked = useSelector(({ rebate }) => rebate.manufacturerChecked);
  const categoryChecked = useSelector(({ rebate }) => rebate.categoryChecked);
  const rebateLoaded = useSelector(({ rebate }) => rebate.rebateLoaded);
  const rebateView = useSelector(({ rebate }) => rebate.rebateView);
  const sortState = useSelector(({ rebate }) => { return { sortBy: rebate.listOptions.sortBy, sortDesc: rebate.listOptions.sortDesc } });
  const itemMatches = useSelector(({ rebate }) => rebate.itemMatches);
  const matchLoading = useSelector(({ rebate }) => rebate.itemMatchesLoading);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreAllRebates, setHasMoreAllRebates] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const allRebatePageCount = useSelector(({rebate}) => rebate.allRebatePageCount);
  const rebateLoadStatus = useSelector(({rebate}) => rebate.rebateLoadStatus);
  const allRebateLoadStatus = useSelector(({rebate}) => rebate.allRebateLoadStatus)
  const history = useHistory();
  const [filterModal, setFilterModal] = useState(null);
  const allRebatePage = useSelector(({ rebate }) => rebate.allRebatePage);
  const allRebateList = useSelector(({ rebate }) => rebate.allRebateList);
  const rebateViewType = useSelector(({ rebate }) => rebate.rebateViewType);
  const pageSize = 20;

  const getRebates = (manuParam, catParams, search) => {
    const manufacturerArray = manuParam ? manuParam : manufacturerChecked;
    const categoryArray = catParams ? catParams : categoryChecked;
    const params = {
      search: search,
      manufacturers: '[' + manufacturerArray.toString() + ']',
      categories: '[' + categoryArray.toString() + ']'
    };

    // get rebates data from the api and prepare the first page. 
    // if we already have data, instead prepare the next page.
    if (!rebateLoaded) {
      dispatch(setRebateLoadStatus('loading'));
      rebateDeviationApi.get(params).then(res => {
        const sorted = sortArrayByKey(res.data,
          sortState.sortBy,
          sortState.sortDesc)
        dispatch(setRebateData((sorted.map(d => {
          return {
            ...d,
            isReveal: false
          }
        }))));

        dispatch(setRebateLoadStatus('complete'));
      }).catch(err => {
        dispatch(setRebateLoadStatus('failed'));
      });
    }

    if (rebateList.length > 0) {
      // add a page to the available rows to render
      dispatch(setRebateView(rebateList.slice(0, pageCount * pageSize)));
      setPageCount(pageCount + 1);

      // is there anything more to show?
      rebateList.length === rebateView.length ? setHasMore(false) : setHasMore(true);
    }
  }

  const getAllRebates = (manuParam, catParams, search, page) => {
    console.log(page)
    const manufacturerArray = manuParam ? manuParam : manufacturerChecked;
    const categoryArray = catParams ? catParams : categoryChecked;
    page = page !== undefined ? page : allRebatePageCount
    const params = {
      search: search,
      manufacturers: '[' + manufacturerArray.toString() + ']',
      categories: '[' + categoryArray.toString() + ']',
      page
    };

    if (allRebateLoadStatus === 'idle') {
      page = page == null ? 0 : page + 1;
      dispatch(setAllRebatePageCount(page));
      dispatch(setAllRebateLoadStatus('loading'));
      rebateDeviationApi.getAll(params, allRebatePage).then(res => {
        const combinedWithCurrentList = page > 1 ? [...allRebateList, ...res.data] : res.data;
        const sorted = sortArrayByKey(combinedWithCurrentList,
          sortState.sortBy,
          sortState.sortDesc);

        dispatch(setAllRebateData((sorted.map(d => {
          return {
            ...d,
            isReveal: false
          }
        }))));

        dispatch(setAllRebateLoadStatus('idle'));
      }).catch(err => {
        dispatch(setAllRebatePageCount(page - 1));
        setHasMoreAllRebates(false)
        if (allRebateList.length === 0) {
          dispatch(setAllRebateLoadStatus('failed'));
        } else {
          dispatch(setAllRebateLoadStatus('failed-to-load-more'));
        }
      });
    }
  }

  const tryReloadingAllRebates = () => {
    setHasMoreAllRebates(true);
    dispatch(setAllRebateLoadStatus('idle'));
  }
  // let rebateMapFilter = [];
  // const keysSearch = [
  //   {key: 'description', search: search},
  //   {key: 'min', search: search},
  //   {key: 'manufacturer', search: search},
  // ]
  // const keysFilters = [
  //   {key: 'manufacturerId', search: manufacturerChecked },
  //   {key: 'categoryId', search: categoryChecked }
  // ]
  // if(manufacturerChecked.length > 0 || categoryChecked.length > 0 ) {
  //   rebateMapFilter = filterArrayByKey(rebateList , keysFilters);
  // } else {
  //   rebateMapFilter = filterArrayByKey(rebateList , keysSearch);
  // }
  // rebateMapFilter = filterArrayByKey(rebateList , keysSearch);

  const clickReveal = (id) => {
    dispatch(setRebateView(rebateView.map(list => {
      if (list.product_id !== id) {
        return {
          ...list,
          isReveal: false
        };
      }
      return {
        ...list,
        isReveal: !list.isReveal
      }
    })))
  }

  const clickRevealAll = (id) => {
    dispatch(setAllRebateData(allRebateList.map(list => {
      if (list.product_id !== id) {
        return {
          ...list,
          isReveal: false
        };
      }
      return {
        ...list,
        isReveal: !list.isReveal
      }
    })))
  }

  const pickItemForItemMatches = (min, manufacturerPlatformId, merchantPlatformId, itemName) => {
    itemName = stringSanitizer.sanitize.keepSpace(itemName);
    let cleanUrl = '/rebates/matches/' + itemName + '/' + min + '/' + manufacturerPlatformId + '/' + merchantPlatformId;
    history.push(cleanUrl);
  }


  const sortBy = (key, order) => {
    order = order ? order : key === sortState.sortBy ? !sortState.sortDesc : true;
    order = ((key === 'manufacturer' && key !== sortState.sortBy) || (key === 'description' && key !== sortState.sortBy)) ? !order : order;
    dispatch(setRebateListSortBy(key, order));
  };

  const rebateMap = rebateView.map((r, i) => {
    const matchData = itemMatches.find((m) => m.min === r.min && m.manufacturerPlatformId === r.manufacturerPlatformId);
    return (<TableRow
      key={`rebate_${i}`}
      {...r}
      pickItemForItemMatches={pickItemForItemMatches}
      clickReveal={clickReveal}
      matchLoading={matchLoading}
      matchData={matchData}
    />);
  });

  const allRebateMap = allRebateList.map((r, i) => {
    const matchData = itemMatches.find((m) => m.min === r.min && m.manufacturerPlatformId === r.manufacturerPlatformId);
    return (<TableRow
      key={`rebate_${i}`}
      {...r}
      i={i}
      pickItemForItemMatches={pickItemForItemMatches}
      clickReveal={clickRevealAll}
      matchLoading={matchLoading}
      matchData={matchData}
    />);
  });

  useEffect(() => {
    let rebateTypes = [
      'FIXED_AMOUNT_PER_QTY',
      'FIXED_AMOUNT_PER_WEIGHT',
      'PERCENT_OF_PRICE',
      'PERCENT_OF_COST']
    if (rebateViewType === 'yourRebates') {
      if (rebateTypes.includes(sortState.sortBy)) {
        rebateList.forEach((rebate, ind, arr) => {
          if (rebate.rebateType === sortState.sortBy) {
            arr.splice(ind, 1);
            arr.unshift(rebate);
          }
        })
        dispatch(setRebateView(rebateList.slice(0, pageCount * pageSize)));
      } else {
        let sorted = sortArrayByKey(rebateList,
          sortState.sortBy,
          sortState.sortDesc);
        dispatch(setRebateView(sorted.slice(0, pageCount * pageSize)));
      }
    } else {
      if (rebateTypes.includes(sortState.sortBy)) {
        allRebateList.forEach((rebate, ind, arr) => {
          if (rebate.rebateType === sortState.sortBy) {
            arr.splice(ind, 1);
            arr.unshift(rebate);
          }
        })
        dispatch(setAllRebateData(allRebateList));

      } else {
        let sorted = sortArrayByKey(allRebateList,
          sortState.sortBy,
          sortState.sortDesc);
          dispatch(setAllRebateData(sorted));
      }
    }
    // eslint-disable-next-line
  }, [sortState.sortBy, sortState.sortDesc])

  const mobileRebateSortOptions = [
    {
      name: 'DIN (Low to High)',
      isActive: sortState.sortBy === 'din' && !sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('din', false))
      }
    },
    {
      name: 'DIN (High to Low)',
      isActive: sortState.sortBy === 'din' && sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('din', true))
      }
    },
    {
      name: 'MIN (Low to High)',
      isActive: sortState.sortBy === 'min' && !sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('min', false))
      }
    },
    {
      name: 'MIN (High to Low)',
      isActive: sortState.sortBy === 'min' && sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('min', true))
      }
    },
    {
      name: 'Description (A-Z)',
      isActive: sortState.sortBy === 'description' && !sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('description', false))
      }
    },
    {
      name: 'Description (Z-A)',
      isActive: sortState.sortBy === 'description' && sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('description', true))
      }
    },
    {
      name: 'Manufacturer (A-Z)',
      isActive: sortState.sortBy === 'manufacturer' && !sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('manufacturer', false))
      }
    },
    {
      name: 'Manufacturer (Z-A)',
      isActive: sortState.sortBy === 'manufacturer' && sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('manufacturer', true))
      }
    },
    {
      name: 'Rebate Type',
      isActive: sortState.sortBy === 'rebateType' && !sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('rebateType', false))
      }
    },
    // {
    //   name: 'Rebate Type (Z-A)',
    //   isActive: sortState.sortBy === 'rebateType' && sortState.sortDesc,
    //   click: () => {
    //     dispatch(setRebateListSortBy('rebateType', true))
    //   }
    // },
    {
      name: 'Rebate (Low to High)',
      isActive: sortState.sortBy === 'rebateValue' && !sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('rebateValue', false))
      }
    },
    {
      name: 'Rebate (High to Low)',
      isActive: sortState.sortBy === 'rebateValue' && sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('rebateValue', true))
      }
    },
    {
      name: 'Deviated Price (Low to High)',
      isActive: sortState.sortBy === 'deviationValue' && !sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('deviationValue', false))
      }
    },
    {
      name: 'Deviated Price (High to Low)',
      isActive: sortState.sortBy === 'deviationValue' && sortState.sortDesc,
      click: () => {
        dispatch(setRebateListSortBy('deviationValue', true))
      }
    }
  ];

  const sortByName = (data) => {
    if (data) {
      data.sort((a, b) => {
        const aUpper = a.name.toUpperCase();
        const bUpper = b.name.toUpperCase();

        if (aUpper < bUpper) {
          return -1;
        }

        if (aUpper > bUpper) {
          return 1;
        }

        return 0;
      });
    }

    return data;
  }

  const getRebatesFilters = () => {
    // Get new filters
    rebateDeviationApi.getFilters().then(({ data }) => {
      dispatch(setManufacturerData(sortByName(data.manufacturers)));
      dispatch(setCategoryData(sortByName(data.categories)));
      dispatch(setFiltersLoaded(true));
    }).catch(err => {
      dispatch(filtersLoadFailed());
      console.error(err);
    });
  }

  const applySearch = (manChecked, catChecked) => {
    if (rebateViewType === 'yourRebates') {
      dispatch(clearRebateData());
    } else {
      // not meaning all of data for this tab, meaning, rebates that are for this distributor and more
      dispatch(clearAllRebateData());
      dispatch(setAllRebatePageCount(0));
      getAllRebates(manufacturerChecked, categoryChecked, searchRebate, 0);
    }
  };



  const onClickSearch = (manChecked, catChecked) => {
    if (activeDistributor.id !== activeDistributorApply.id) {
      // Clean up any existing filters
      dispatch(resetRebateFilters());
      dispatch(clearFilters());
      dispatch(setFiltersLoaded(false));
      dispatch(spinner.add());
      userApi.activeDistributor(activeDistributor.id).post().then(({ data }) => {
        dispatch(spinner.subtract());
        dispatch(applyActiveDistributor());
        applySearch(manChecked, catChecked);
        const { channelPartner } = data;
        dispatch(setUserData({ channelPartner }));
      }).catch(err => {
        dispatch(spinner.subtract());
        console.error(err);
      });
    } else {
      applySearch(manChecked, catChecked);
    }
  };

  useEffect(() => {
    if (filterModal) {
      dispatch(updateModal(filterModal, { onClickSearch }))
    }
    // eslint-disable-next-line
  }, [activeDistributor]);

  // Get filters on mount, and whenever the active distributor changes
  useEffect(() => {
    if (activeDistributorApply !== null) {
      getRebatesFilters();
    }
    // eslint-disable-next-line
  }, [activeDistributorApply])

  const onClickReset = () => {
    dispatch(resetRebateFilters());
    dispatch(clearRebateData());
  }

  useEffect(() => {
    setHasMore(true);
    setPageCount(1);
    getRebates(manufacturerChecked, categoryChecked, searchRebate);
    // eslint-disable-next-line
  }, [rebateList]);


  return {
    rebateList,
    rebateMap,
    getRebates,
    getAllRebates,
    allRebateMap,
    allRebateList,
    onClickSearch,
    onClickReset,
    hasMore,
    hasMoreAllRebates,
    pageCount,
    searchRebate,
    manufacturerChecked,
    categoryChecked,
    rebateLoadStatus,
    allRebateLoadStatus,
    setFilterModal,
    sortBy,
    sortState,
    mobileRebateSortOptions,
    tryReloadingAllRebates
  }
}

const useFilter = () => {
  const dispatch = useDispatch();
  const searchM = useSelector(({ rebate }) => rebate.searchManufacturer);
  const searchC = useSelector(({ rebate }) => rebate.searchCategory);

  const onChangeM = (e) => {
    dispatch(setSearchManufacturer(e.target.value));
  };
  const clearValueM = () => {
    dispatch(clearSearchManufacturer());
  };
  const onCheckM = (manu) => {
    dispatch(setManufacturerChecked(manu.id));
  };
  const onChangeC = (e) => {
    dispatch(setSearchCategory(e.target.value));
  };
  const clearValueC = () => {
    dispatch(clearSearchCategory());
  };
  const onCheckC = (cat) => {
    dispatch(setCategoryChecked(cat.id));
  };


  return {
    searchManufacturer: {
      onChange: onChangeM,
      value: searchM,
      clearValue: clearValueM
    },
    onCheckManufacturer: onCheckM,
    searchCategory: {
      onChange: onChangeC,
      value: searchC,
      clearValue: clearValueC
    },
    onCheckCategory: onCheckC
  }
}

export default {
  useApi,
  useTop,
  useFilter
}
