export function getQuarter(month) {
  return Math.floor((month+3) / 3);
}

export function calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack(qs) {
  const monthsInGivenQuarters = qs * 3;

  function getQuarter(month) {
    return Math.floor((month+3) / 3);
  }

  function arrangeNumOfMonthsFromAndToLikeCalendar() {
    let date = new Date();
    const monthAndYrPairList = [];
    const staticMonthsListForCalc = [0,1,2,3,4,5,6,7,8,9,10,11];
    for (let i = 0; i < monthsInGivenQuarters; i++) {
      const currentMonth = date.getMonth();
      const currentYear = date.getFullYear();
      monthAndYrPairList.push([staticMonthsListForCalc[currentMonth], currentYear])
      date.setMonth(date.getMonth() - 1);
    } 
    return monthAndYrPairList;
  }

  const calenderArrangedMonths = arrangeNumOfMonthsFromAndToLikeCalendar();
  const quarters = []; // purely to determince unique quaters in qurterOptions arr
  const quarterOptions = [];
 
  calenderArrangedMonths.forEach(mAndYr => {
    let quarter = getQuarter(mAndYr[0]);
    let yrAndQ = mAndYr[1].toString().slice(2,4) +'Q'+ quarter;
    if(!quarters.includes(yrAndQ)) {
      quarters.push(yrAndQ);
      quarterOptions.push({
        label: mAndYr[1] + ' Quarter ' + quarter,
        value: yrAndQ
      })
    }
  })
  return quarterOptions.slice(0, 6)
}