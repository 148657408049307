import React, { useState } from 'react';
import { Col, Row, Tooltip } from 'reactstrap';
import { colors } from '../../../../config/constants';
import numeral from 'numeral';
import { IoIosInformationCircle } from 'react-icons/io';
import { isMobile } from 'react-device-detect';

const PROCESSED_PURCHASES = "Total Processed Purchases";
const COMPETITOR_VOLUME = "Competitor Volume";
const ESTIMATED_REBATES = "Estimated Rebates Savings";

const PROCESSED_PURCHASES_TOOLTIP = "Purchases that we have processed for the selected date range. Includes friendly and competitive data.";
const COMPETITOR_VOLUME_TOOLTIP = "Purchases that we have processed from competitors for the selected date range.";
const ESTIMATED_REBATES_TOOLTIP = "An ESTIMATE of the rebates your customers have accrued in the selected date range. Subject to change, may or may not include rebates that have already been paid.";

const DashboardOverallStats = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState({
    overall: false,
    competitive: false,
    rebates: false
  });

  const toggleTooltipOverall = () => {
    setTooltipOpen({...tooltipOpen, overall: !tooltipOpen.overall});
  }

  const toggleTooltipCompetitive = () => {
    setTooltipOpen({...tooltipOpen, competitive: !tooltipOpen.competitive});
  }
  
  const toggleTooltipRebates = () => {
    setTooltipOpen({...tooltipOpen, rebates: !tooltipOpen.rebates});
  }

  return (
    <Col className='py-4 w-50 border-bottom border-right'>
      <Row>
        <Col>
          <div style={{color: colors.beplMagenta}}>
            {PROCESSED_PURCHASES}  &nbsp;
            <span id='idTooltipOverall'>
              <IoIosInformationCircle color={colors.beplMagenta} />
            </span>
          </div>
          <span className={`${isMobile ? 'text-size-30' :'text-size-36'}`}>
            { numeral(props.totalVolume).format('$0,0') }
          </span>
          <Tooltip placement='top' 
            isOpen={tooltipOpen.overall} 
            target='idTooltipOverall' 
            toggle={toggleTooltipOverall}>
            {PROCESSED_PURCHASES_TOOLTIP}
          </Tooltip>
        </Col>
        <Col>
          <div style={{color: colors.beplMagenta}}>
           {COMPETITOR_VOLUME}  &nbsp;
            <span id='idTooltipCompetitive'>
              <IoIosInformationCircle color={colors.beplMagenta} />
            </span>
          </div>
          <span className={`${isMobile ? 'text-size-30' :'text-size-36'}`}>
            { numeral(props.competitorVolume).format('$0,0') }
          </span>
          <Tooltip placement='top' 
            isOpen={tooltipOpen.competitive} 
            target='idTooltipCompetitive' 
            toggle={toggleTooltipCompetitive}>
            {COMPETITOR_VOLUME_TOOLTIP}
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col className={`${isMobile ? 'pt-3': ''}`}>
          <div style={{color: colors.beplMagenta}}>
            {ESTIMATED_REBATES}  &nbsp;
            <span id='idTooltipRebates'>
              <IoIosInformationCircle color={colors.beplMagenta} />
            </span>
            <Tooltip placement='top' 
              isOpen={tooltipOpen.rebates} 
              target='idTooltipRebates' 
              toggle={toggleTooltipRebates}>
              {ESTIMATED_REBATES_TOOLTIP}
            </Tooltip>
          </div>
          <div>
            <span className={`${isMobile ? 'text-size-30' :'text-size-36'}`}>
              { numeral(props.totalRebates).format('$0,0') }
            </span>
          </div>
          <div className={`${isMobile ? 'pt-3': ''}`}>
            <span className='text-size-24' style={{ color: colors.beplBlue}}>
              ({ numeral(props.annualizedRebates).format('$0,0') } annualized)
            </span>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export default DashboardOverallStats;