import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import { If, Else, Then} from 'react-if';
import { IoMdArrowRoundUp , IoMdArrowRoundDown } from 'react-icons/io';
import useItemMatchesData from './useItemMatchesData';


const RebatesItemMatchesSortHeader = () => {
  const {sortBy, sortState} = useItemMatchesData.useApi();
  const sortableColumn = (shortName, longName) => {
    let colSize = shortName === 'description' ||
    shortName === 'manufacturerName' ? 2 : 1

    return (
      <Col xs={colSize} onClick={() => sortBy(shortName)} 
        className={'clickable' + (sortState.sortBy === shortName ? ' text-primary': '')}
      >
        {longName}
        <If condition={sortState.sortBy === shortName && !sortState.sortDesc}>
         <Then>
          <IoMdArrowRoundUp className='ml-2' />
          </Then>
          <Else>
            <IoMdArrowRoundDown className='ml-2' />
          </Else>
        </If>
      </Col>
    );
  }


  return (
      <CardBody style={{top: '0px', zIndex: 50}} className='py-2 bg-white border-top border-left border-right position-sticky'>
        <Row className='text-muted text-size-10 line-height-11 text-uppercase'>
          {sortableColumn('min', 'Min')}
          {sortableColumn('description', 'Description')}
          {sortableColumn('manufacturerName', 'manufacturer')}
          {sortableColumn('pack', 'Pack')}
          {sortableColumn('inCatalog', 'In Catalog')}
          {sortableColumn('rebateType', 'Rebate Type')}
          {sortableColumn('rebateValue', 'Rebate')}
          {sortableColumn('deviationValue', 'Deviation')}
          {sortableColumn('uom', 'UOM')}
          <Col>Feedback</Col>
        </Row>
      </CardBody>
  );
}

export default RebatesItemMatchesSortHeader;