import React from "react";
import { Calendar } from 'react-big-calendar'
import { Card, Row, Spinner, Alert } from "reactstrap";
import useOrderHistory from "./useOrderHistory";


const CompetitiveInsightOrderHistoryView = () => {
  const { localizer, events, showOrderedItems, showOrderHistoryModal, date, setDate, error, setError, loading } = useOrderHistory.useProcessing();
  return (
    <>
      <Alert className="text-center" color="danger" isOpen={error !== false} toggle={() => setError(false)}>Danger!</Alert>
      {loading === true ? <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row> :
        <Card className="p-2 mt-3">
          <Calendar
            localizer={localizer}
            views={['month']}
            style={{ minHeight: '83vh' }}
            events={events}
            onDrillDown={(e) => { showOrderHistoryModal(e, events) }}
            onSelectEvent={(e) => showOrderedItems(e)}
            date={date}
            onNavigate={(date) => setDate(date)}
          />
        </Card>
      }


    </>
  );


}

export default CompetitiveInsightOrderHistoryView;