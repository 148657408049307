export default function reducer(state = {
  modals: []
}, action) {
  switch (action.type) {
    case 'ADD_MODAL':
      let uiUpdateModal = state.modals.find(m => m.props.type === 'ui-update');
      return {
        ...state,
        modals: !uiUpdateModal ? [
          ...state.modals,
          action.payload
        ] : state.modals
      };
    case 'UPDATE_MODAL':
      return {
        ...state,
        modals: state.modals.map(m => {
          if (m.props.id !== action.payload.id) return m;
          return {
            ...m,
            props: {
              ...m.props,
              ...action.payload.props
            }
          }
        })
      };
    case 'TOGGLE_MODAL':
      return {
        ...state,
        modals: state.modals.map(m => {
          if (m.props.id !== action.payload) return m;
          return {
            ...m,
            props: {
              ...m.props,
              isOpen: !m.props.isOpen
            }
          }
        })
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        modals: state.modals.map(m => {
          if (m.props.id !== action.payload) return m;
          return {
            ...m,
            props: {
              ...m.props,
              isOpen: false
            }
          }
        })
      };
    case 'REMOVE_MODAL':
      return {
        ...state,
        modals: state.modals.filter(m => m.props.id !== action.payload)
      };
    default:
      return state;
  }
};