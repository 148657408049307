import React, {useEffect} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import IntelDetailCompetitive from './IntelDetailCompetitive/IntelDetailCompetitive';
import IntelDetailDistributor from './IntelDetailDistributor/IntelDetailDistributor';
import useIntelDetail from './useIntelDetail';
import BlankContainer from '../../../../sharedComponents/BlankContainer/BlankContainer';

const IntelDetail = ({match}) => {
  const { merchantsLoaded } = useIntelDetail.useIntelDetail(match.params.id);

  useEffect(()=> {
    window.scrollTo({
      top: 0,
    });
  },[]);

  if (!merchantsLoaded) {
    return (
      <BlankContainer />
    );
  }
  return (
    <Switch>
      <Route exact path='/intel/detail/:id/competitive' component={IntelDetailCompetitive} />
      <Route exact path='/intel/detail/:id/distributor' component={IntelDetailDistributor} />
      <Route>
        <Redirect to='/intel/list'/>
      </Route>
    </Switch>
  );
};

export default IntelDetail;