import uniqid from 'uniqid';

export const showNotification = (component, props) => {
  return {
    type: 'ADD_NOTIFICATION',
    payload: {
      component,
      props: {
        ...props,
        id: uniqid('notification-'),
        isOpen: true
      }
    }
  };
};

export const removeNotification = (id) => {
  return {
    type: 'REMOVE_NOTIFICATION',
    payload: id
  };
};