import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, CustomInput } from 'reactstrap';
import sortArrayByKey from '../../../../sharedComponents/Helper/sortArrayByKey';

const InvitedUserLocation = ({ title, showSearch, options, ...props }) => {
  const [listHeight, setListHeight] = useState('0px');

  useEffect(() => {
    let height = window.innerHeight;
    setListHeight(height - 387 +'px')
  }, []);
  const locations = options = sortArrayByKey(options, 'label', false);
  
  const locationsMap = locations.map(o => {
    return (
      <Row key={`${title}_${o.id}`} className='mr-0 pt-3 clickable'>
        <Col>
          <CustomInput id={`${title}_${o.id}`} label={o.label} type='checkbox' checked={true} onChange={() => props.checkLocation(o)} />
        </Col>
      </Row>
    )


  });
  return (
    <Col className='px-0 overflow-auto border text-size-14 line-height-16'>
      <Col className='pr-0' style={isMobile ? {height: '160px'} : { height: listHeight }}>
        {locationsMap}
      </Col>
    </Col>
  )
}

export default InvitedUserLocation;
