import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import useModal from '../../../../sharedComponents/useModal/useModal';
import { MdCheck } from 'react-icons/md';

const SortByModal = ({id, ...props}) => {
  const { modalProps, headerProps, bodyProps, toggle } = useModal(id, {isMenu: true, isFullscreen: true});
  const mapSort = props.options.map((list, i)  => (
    <Row className='mx-0 align-items-center border-bottom' key={`sortmodal-${i}`} onClick={() => { list.click(); toggle();}}>
      <Col className={'py-3' + (list.isActive ? '  font-weight-bold' : '')}>
        {list.name}
      </Col>
      {
        list.isActive &&
        <Col className='text-beplBlue w-fc'><MdCheck /></Col>
      }
    </Row>
  ));

  bodyProps.className += ' p-0';
  
  return (
     <Modal {...modalProps}>
      <ModalHeader {...headerProps}>{props.title}</ModalHeader>
      <ModalBody {...bodyProps} >
        {mapSort}
      </ModalBody>
    </Modal>
  )
}

export default SortByModal;
