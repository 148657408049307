import Axios from "axios";

const url = '/announcement';

const announcementApi = {
  post: (params) => {
    return Axios.post(url, params);
  },
  get: () => {
    return Axios.get(url);
  },
  patch: (id, params) => {
    return Axios.patch(`${url}/${id}`, params);
  },
  delete: (id) => {
    return Axios.delete(`${url}/${id}`,);
  }
};

export default announcementApi;