import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import { Row, Col, Input, Button } from 'reactstrap';
import { selectStyles } from '../../../../config/constants';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaCalendarAlt } from 'react-icons/fa'
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { selectMerchant } from '../../../../reducers/DashboardReducer/DashboardActions';
import AdminUserSelect from './AdminUserSelect';
import AdminUserDetails from './AdminUserDetails';
import testApi from '../../../../api/testApi';
import LocalStorage from '../../../../sharedComponents/LocalStorage/LocalStorage';
const ALL_LOCATIONS = 'All Locations';

const DashboardHeader = (props) => {
  const dispatch = useDispatch();
  const [testRequestsAmount, setTestRequestsAmount] = useState(10);
  const [testRequestsSent, setTestRequestsSent] = useState(false);
  const [admin, setAdmin] = useState(false);
  const user = useSelector(({ user }) => user);

  const dateRange = moment(props.fromDate).format('MMM Do YYYY') + ' - ' + moment(props.toDate).format('MMM Do YYYY');

  const setDateRangeHandler = (startDate, endDate) => {
    props.setDateRange({ fromDate: startDate._d, toDate: endDate._d });
    props.onApplySearch();
  }

  // Merchant names can vary between Salesforce (merchant API) and Savings Estimator (dashboard API)
  // For consistency, keep names we got from merchant and merge with the volume from dashboard
  const merchantOptions = !props.merchants ? [] : props.merchants.map(m => {
    const volume = props.volumeByMerchant.find((vm) => vm.platformId === m.id)?.volume;
    return {
      label: m.merchant,
      value: m.id,
      volume: volume ? volume : 0
    }
  }).sort((a, b) => b.volume - a.volume);

  const onMerchantSelect = (merchant) => {
    dispatch(selectMerchant(merchant));
  }

  // Show More/Less view toggle is disabled for now.
  //const [adminViewLargeCards, setAdminViewLargeCards] = useState(false);

  // const SHOW_MORE = "Show More";
  // const SHOW_LESS = "Show Less";

  // const onToggleLargeCards = () => {
  //   setAdminViewLargeCards(!adminViewLargeCards);
  // }

  let distributorSelect = null;
  if (props.distributorsList && props.distributorsList.length === 1) {
    // Show a single distributor as a label
    distributorSelect =
      <Col xs={isMobile ? 12 : 5} className={isMobile ? 'p-2' : ''}>
        <div className='main-header text-size-20'>{props.distributorsList[0].label}</div>
      </Col>
      ;
  }
  else {
    // Show multiple distributors as a select
    distributorSelect =
      <Col xs={isMobile ? 12 : 5} className={isMobile ? 'p-2' : 'mw-340px'}>
        <Select
          options={props.distributorsList}
          onChange={props.selectActiveDistributor}
          styles={selectStyles}
          value={props.activeDistributor}
        />
      </Col>
      ;
  }

  // Admin View (only shown for certain users)
  const currentUser = props.userList.find((u) => u.id === props.userId);
  const currentUserRole = currentUser?.title;
  let adminControls = null;
  if (currentUserRole === 'Admin') {
    const adminUserSelect = <AdminUserSelect
      userList={props.userList}
      currentUser={currentUser}
      impersonate={props.impersonate}
    />;

    const adminUserDetails = <AdminUserDetails
      userList={props.userList}
      currentUser={currentUser}
      impersonate={props.impersonate}
      showLarge={false /*isMobile ? false : adminViewLargeCards*/}
    />;

    // const adminDetailLevel = isMobile ? null : <Col>
    //   <div className="see-details-label text-beplMagenta clickable" onClick={onToggleLargeCards}>
    //     {adminViewLargeCards ? SHOW_LESS : SHOW_MORE}
    //   </div>
    // </Col>;

    adminControls = <Row className='pb-4 label-text text-size-14 d-flex align-items-end border-bottom'>
      <Col xs={isMobile ? 12 : 3}>
        {adminUserSelect}
      </Col>
      {adminUserDetails}
      {/* adminDetailLevel */}
    </Row>;
  }

  const sendTestRequests = async () => {
    try {
      setTestRequestsSent('Sent!');
      await testApi.dash.speeds({ userId: user.id, adminEmail: admin.email, amountOfReqsToMake: testRequestsAmount });
      setTimeout(() => {
        setTestRequestsSent(false);
      }, 3000)
    } catch (err) {
      setTestRequestsSent('Failed.');
      setTimeout(() => {
        setTestRequestsSent(false);
      }, 3000)
    }
  }

  useEffect(() => {
    let admin = LocalStorage.adminUserData.get();
    setAdmin(admin);
  }, [])

  return (
    <>
      <Row className={`py-4 label-text text-size-14 d-flex align-items-center${adminControls ? null : ' border-bottom'}`}>
        {distributorSelect}
        {admin?.testingEnabled ? <Col xs={isMobile ? 12 : 1} className={`d-flex justify-content-end ${isMobile ? 'mb-2' : 'mx-0 px-0'}`}>
          {!testRequestsSent ? <Input type="number" value={testRequestsAmount} onChange={(e) => setTestRequestsAmount(e.target.value)} className="" style={{ backgroundColor: 'white' }} /> :
            <Col className="main-header text-size-16 d-flex justify-content-center align-items-center">{testRequestsSent}</Col>}
          <Button onClick={sendTestRequests} className='py-2 button-label ml-1' size='sm' color='beplMagenta'>Test</Button>
        </Col> : false}
        <Col xs={isMobile ? 12 : 3}>
          <Select
            options={merchantOptions}
            onChange={onMerchantSelect}
            styles={selectStyles}
            value={props.selectedMerchant}
            placeholder={ALL_LOCATIONS}
            isClearable
          />
        </Col>
        <Col xs={isMobile ? 12 : 3} className={isMobile ? 'mt-2' : ''}>
          <DateRangePicker
            onCallback={setDateRangeHandler}
            initialSettings={{ startDate: props.fromDate, endDate: props.toDate }}
          >
            <div className='position-relative'>
              <Input type="text" readOnly value={dateRange} className="form-control" style={{ paddingLeft: '35px', backgroundColor: 'white' }} />
              <FaCalendarAlt className="position-absolute-center-start" size="15px" />
              <IoMdArrowDropdown className="position-absolute-center-end bg-white" size="25px" />
            </div>
          </DateRangePicker>
        </Col>
      </Row>
      {adminControls}
    </>
  );
}

export default DashboardHeader;