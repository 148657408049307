import React, { useEffect } from 'react';
import LocalStorage from '../../../sharedComponents/LocalStorage/LocalStorage';
import Container from 'reactstrap/lib/Container';
import { useDispatch } from 'react-redux';
import { clearUserData } from '../../../reducers/UserReducer/UserActions';
import { clearAllRebatesData } from '../../../reducers/RebateReducer/RebateActions';
import { clearAllIntelData } from '../../../reducers/IntelReducer/IntelActions';
import { clearAllDashboardData } from '../../../reducers/DashboardReducer/DashboardActions';
import { clearAllUiData } from '../../../reducers/UIReducer/UIActions';
import { clearAllRebateChecksData } from '../../../reducers/RebateChecksReducer/RebateChecksActions';
import userApi from '../../../api/userApi';

const Logout = () => {
  const dispatch = useDispatch();
  const user = LocalStorage.userData.get();
  const switchBackToDefaultDistributor = async () => {
    if (user.isMultiHouse === 1) {
      await userApi.activeDistributor(user.channelPartner.distributor_id).post();
    }
  }

  useEffect(() => {
    switchBackToDefaultDistributor();
    LocalStorage.clear();
    dispatch(clearUserData());
    dispatch(clearAllRebatesData());
    dispatch(clearAllDashboardData());
    dispatch(clearAllIntelData());
    dispatch(clearAllUiData());
    dispatch(clearAllRebateChecksData());
    // eslint-disable-next-line
  }, []);

  return (
    <Container />
  );
};

export default Logout;