import React, {Fragment} from 'react';
import { Row, Col } from 'reactstrap';
import { BsTrash, BsPencil } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';
import { colors } from '../../../../config/constants';

const NON_EDIT_USER = 'You do not have permission to edit or delete accounts. Please contact your company Admin, Owner or Manager.';

const UsersRolesRow = (props) => {
  const { userInvite, userDelete, currentUserRole, isInternalAdmin } = props;
  const user  = props.data;

  const editEnabled = isInternalAdmin || !(currentUserRole === 'Sales Rep' || currentUserRole === 'Read Only');

  return (
    <Row className={'py-2 ' + (isMobile ? ' border-bottom' : 'text-size-14 line-height-16')}>
        <Col className={isMobile ? 'py-1 col-9': 'col-2'}>
          {user.contactName}
        </Col>
        { isMobile && 
          <Col xs={3}>
            <Row>
              <Col className='text-right'>
                <BsPencil 
                  color={editEnabled ? 'inherit' : colors.grayLight}
                  title={editEnabled ? 'Edit User' : NON_EDIT_USER}
                  size={16} 
                  onClick={editEnabled ? ()=> userInvite(user, true) : null} 
                />
              </Col>
              <Col className='w-fc clickable'>
                <BsTrash 
                  color={editEnabled ? 'inherit' : colors.grayLight}
                  title={editEnabled ? 'Delete User' : NON_EDIT_USER}
                  size={16} 
                  onClick={editEnabled ? ()=> userDelete(user) : null} 
                />
              </Col>
            </Row>
          </Col>
        }
        <Col className={isMobile ? 'py-1 col-12': 'col-3'}>
          {user.email}
        </Col>
        <Col className={isMobile ? 'py-1 col-12': 'col-2'}>
          {user.title}
        </Col>
        { !isMobile &&
          <Fragment>
            <Col className='clickable d-flex justify-content-center' xs={1}>
              <BsPencil 
                color={editEnabled ? 'inherit' : colors.grayLight}
                title={editEnabled ? 'Edit User' : NON_EDIT_USER}
                size={16} 
                onClick={editEnabled ? ()=> userInvite(user, false) : null} 
              />
            </Col>
            <Col className='clickable d-flex justify-content-center' xs={1}>
              <BsTrash 
                color={editEnabled ? 'inherit' : colors.grayLight}
                title={editEnabled ? 'Delete User' : NON_EDIT_USER}
                size={16} 
                onClick={editEnabled ? ()=> userDelete(user) : null} 
              />
            </Col>
          </Fragment>
        }
      </Row>
  )
}

export default UsersRolesRow;
