import React, {useEffect, useState} from 'react';
import { Row, Col, Spinner } from 'reactstrap';

const FILTERS_LOADING = 'Loading...'

const RebatesFiltersLoading = () => {
  let [messageStyle, setMessageStyle] = useState('translateY-100% opacity-0');
  useEffect(() => {
    let init = 'translateX-100%';
    let position = 'down';
    let interval = setInterval(() => {
      if(init !== 'translateY-0% opacity-100') {
        init = 'translateY-0% opacity-100';
      } else {
        if(position==='down'){
          position = 'up'
          init = '-translateY-100% opacity-0'
        } else {
          position = 'down'
          init = 'translateY-100% opacity-0'
        }
      }
      setMessageStyle(init);
    }, 4000)
    return () => clearInterval(interval);
  }, []);
  return (
      <Row>
        <Col className='text-center p-5 small-desktop-rebate-filters-loading-style'>
          <Spinner color='primary' />
          <Col className={`mt-3 text-center text-size-20 main-header transition-all ${messageStyle}`}>{FILTERS_LOADING}</Col>
        </Col>
      </Row>
  );
}

export default RebatesFiltersLoading;