import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import IntelLineChartCard from './IntelLineChartCard';
import { useSelector, useDispatch } from 'react-redux';
// import { randomColors } from '../../../../config/constants';
// import numeral from 'numeral';
import sortArrayByKey from '../../../../sharedComponents/Helper/sortArrayByKey';
import { setGridPage } from '../../../../reducers/IntelReducer/IntelActions';
import moment from 'moment';
import { dashboardApi } from '../../../../api/dashboardApi';

const useApi = () => {
  const dispatch = useDispatch();
  const merchantsList = useSelector(({ intel }) => intel.merchants);
  const search = useSelector(({ intel }) => intel.searchApply);
  const allLoaded = useSelector(({ intel }) => intel.allLoaded);
  const page = useSelector(({ intel }) => intel.gridOptions.page);
  const sortState = useSelector(({ intel }) => { return { sortBy: intel.listOptions.sortBy, sortDesc: intel.listOptions.sortDesc } });
  const filterMap = sortArrayByKey(
    merchantsList,
    sortState.sortBy,
    sortState.sortDesc).filter(m => m.merchant.toLowerCase().search(search.toLowerCase()) !== -1);


  const intelMap = filterMap.slice(0, 4 * page).map(mer => (
    <div key={`merchant-${mer.id}-graph`} className='w-50 p-2'>
      <IntelLineChartCard merchantName={mer.merchant} merchantId={mer.id} {...mer} />
    </div>
  ));

  const loadMoreLineChart = () => {
    if (allLoaded) {
      dispatch(setGridPage(page + 1));
    }
  };

  return {
    intelMap, loadMoreLineChart,
    showMore: intelMap.length !== filterMap.length
  };
};

const useLineChart = (data, startDate, logarithmic) => {

  const chartOptions = {
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      itemMarginBottom: 20
    },

    credits: {
      enabled: false
    },

    title: {
      text: ''
    },

    xAxis: {
      type: 'datetime'
    },

    yAxis: {
      title: '',
      type: logarithmic ? 'logarithmic' : 'linear'
    },

    tooltip: {
      valueDecimals: 0,
      valuePrefix: '$'
    },

    series: data,

    plotOptions: {
      line: {
        cursor: 'pointer'
      },

      series: {
        pointStart: startDate,
        pointIntervalUnit: 'month',
        events: {
          // Disable clicking of legend keys to hide/show plot lines
          legendItemClick: function () { return false; }
        }
      }
    }
  };

  return { chartOptions };
};

const useDashboardMonthlyVolume = (merchantId) => {
  // State management for a single volume chart
  const [volumeByMonth, setVolumeByMonth] = useState([]);
  const [volumeLoading, setVolumeLoading] = useState(false);
  const [volumeLoadFailed, setVolumeLoadFailed] = useState(false);
  const [volumeChartPeriod, setVolumeChartPeriod] = useState({ label: '3 months', value: 3 });
  const [logarithmic, setLogarithmic] = useState(true);

  // Event Handlers for chart controls
  const onChangeChartPeriod = (period) => {
    setVolumeChartPeriod(period);
  }

  const onLogarithmicToggle = () => {
    setLogarithmic(!logarithmic);
  }

  // Retrieve data for this chart
  useEffect(() => {
    if (merchantId) {
      setVolumeLoading(true);

      // Build params for Dashboard API
      const params = {
        // set variable chart dates (based on user select)
        startDate: new Date(moment().subtract(volumeChartPeriod.value, 'month').startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')),
        showRebates: 0,
        showDeviations: 0,
        showLineItems: true,
        merchants: [merchantId]
      };

      dashboardApi.post(params).then(({ data }) => {
        setVolumeByMonth(data.volumeByMonth);
        setVolumeLoading(false);
        setVolumeLoadFailed(false);
      }).catch(err => {
        console.error(err);
        setVolumeLoading(false);
        setVolumeLoadFailed(true);
      });
    }
  }, [merchantId, volumeChartPeriod]);

  return {
    volumeByMonth,
    volumeLoading,
    volumeLoadFailed,
    volumeChartPeriod, onChangeChartPeriod,
    logarithmic, onLogarithmicToggle
  }
}
export default {
  useApi,
  useLineChart,
  useDashboardMonthlyVolume
}