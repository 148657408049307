import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import CompetitiveInsightItemView from './Item/CompetitiveInsightItemView';
import CompetitiveInsightMerchantView from './Merchant/CompetitiveInsightMerchantView';
import CompetitiveInsightItemMatchesView from './ItemMatches/CompetitiveInsightItemMatchesView';
import CompetitiveInsightOrderHistoryView from './OrderHistory/CompetitiveInsightOrderHistoryView';
import CompetitiveInsightSameDayOrderView from './SameDateOrderView/CompetitiveInsightSameDayOrderView';
import { useDispatch, useSelector } from 'react-redux';
import { setLastViewedCompInsightUrl } from '../../../../reducers/UIReducer/UIActions';

const CompetitiveInsightResults = ({ categoryDrilldown, itemDrilldown }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const lastViewedUrl = useSelector(({ui}) => ui.compInsightLastViewedUrl);

  useEffect(() => {
    dispatch(setLastViewedCompInsightUrl(location.pathname));
  // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if(lastViewedUrl!=='/competitiveinsights/merchants'){
      history.push(lastViewedUrl)
    }
  // eslint-disable-next-line
  }, [])
  return (
    <Container fluid className='p-0'>
      <Switch>
        <Route exact path='/competitiveinsights/merchants' render={() => <CompetitiveInsightMerchantView
          categoryDrilldown={categoryDrilldown}
          itemDrilldown={itemDrilldown}
        />} />
        <Route exact path='/competitiveinsights/items/:merchant/:merchantId' render={() => <CompetitiveInsightItemView
          categoryDrilldown={categoryDrilldown}
          itemDrilldown={itemDrilldown}
        />} />
        <Route exact path='/competitiveinsights/matches/:merchant/:itemName/:min/:merchantId/:manufacturerPlatId' render={() => <CompetitiveInsightItemMatchesView
        />} />
        <Route exact path='/competitiveinsights/orderhistory/:merchant/:merchantId' render={() => <CompetitiveInsightOrderHistoryView
        />} />
          <Route exact path='/competitiveinsights/ordertocompetitor/:merchant/:merchantId/:distributorId/:date' render={() => <CompetitiveInsightSameDayOrderView
        />} />
 
        <Route>
          <Redirect to='/competitiveinsights/merchants' />
        </Route>
      </Switch>
    </Container>
  );
}

export default CompetitiveInsightResults;