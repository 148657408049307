import React from 'react';
import { Row, Progress, Col } from 'reactstrap';
import { IoMdClose } from 'react-icons/io';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AiFillCheckCircle, AiFillExclamationCircle} from 'react-icons/ai'


const UploadFileListItem = ({ name, size, progress, clearImage, file, uploadImage, failed }) => {
  return (
    <Row className='p-4 w-100 border border-2 m-0 mt-3 rounded'>
      <Row className="d-flex justify-content-between w-100 m-0">
        <div>
          <div className='main-header text-size-14'>
            {name}
          </div>
          <div className='text-gray'>
            {size}
          </div>
        </div>
        <div className='d-flex align-items-start'>
          {progress === 100 && !failed ? <AiFillCheckCircle className="clickable" size={22} color="#0096CE" /> :
          progress === 100 && failed ? <AiFillExclamationCircle className="clickable"  style={{marginTop: '2px'}} size={20} color="#DD3444" /> : 
           false}
            {(progress === 100 && failed) || !progress ? <IoMdClose onClick={() => clearImage(name)} className="clickable ml-3" size={25} color="black" /> : false}
        </div>
      </Row>
      <Col className="pl-0 mt-2">
        <Progress value={progress} color={failed ? 'danger' : progress === 100 ? 'success' : 'info'} />
      </Col>

    </Row>
  );
};

export default UploadFileListItem;