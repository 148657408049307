import React from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Row, Card, Container } from 'reactstrap';
import QuestionListItem from './Subcomponents/QuestionListItem';
import Title from './Subcomponents/Title';
import VideoListItem from './Subcomponents/VideoListItem';
import { BiHelpCircle } from 'react-icons/bi';

const Faq = () => {

 let questions = [
  {
    title: 'Holy Moly.',
    content: " Shishkabob babka tashankki, jaja juji wawawiwa!"
  },
  {
    title: 'Grrrrr said the man in the red coat.',
    content: " Shishkabob babka tashankki, jaja juji wawawiwa!"
  }
 ]
 let videos = [
  {
    title: 'Holy Moly.',
    content: " Shishkabob babka tashankki, jaja juji wawawiwa!",
    url: "https://www.youtube.com/embed/waJKJW_XU90",
    image: "https://th.bing.com/th/id/OIP.8M4VODfnryezcS0hBcBHSAHaE7?pid=ImgDet&rs=1"
  },
  {
    title: 'Grrrrr said the man in the red coat.',
    content: " Shishkabob babka tashankki, jaja juji wawawiwa!",
    url: "https://www.youtube.com/embed/zwGuOm0EWZc",
    image: "https://th.bing.com/th?id=OIF.XZvrxwVOwPwVykUSd%2fbdYg&pid=ImgDet&rs=1"
  }
 ]

 const videosMap = videos.map(videoObj => {
  return <VideoListItem {...videoObj} />
 })
 const questionsMap = questions.map(questionObj => {
  return <Col className="mx-0 my-1 p-0"><QuestionListItem {...questionObj} /></Col>
 })

  return (
    <Container fluid className="p-4 mx-0">
      <Row className="text-size-26 reveal-label m-0 p-2 align-items-center border-bottom mb-3">
        <BiHelpCircle className='mr-2' />
        Get Help
      </Row>
      <Row className="m-0">
        <Col xs={isMobile ? 12 : 6} className={`${isMobile ? 'px-0' : 'pl-0'} m-0`}>
          <Card className="bg-white px-4 pb-4 pt-0">
            <Title title="Frequently Asked Questions" />
            {questionsMap}
          </Card>
        </Col>
        <Col xs={isMobile ? 12 : 6} className={`${isMobile ? 'mt-4 px-0' : 'pr-0'} m-0`}>
          <Card className={`bg-white px-4 pb-4 pt-0`}>
            <Title title="How To Videos" />
            {videosMap}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Faq;