import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import { Row, Col, CustomInput } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import sortArrayByKey from '../../../../sharedComponents/Helper/sortArrayByKey';

const InviteUserLocation = ({title, showSearch, options, ...props}) => {
  const locationChecked = useSelector(({user}) => user.locationChecked);
  let locations = isMobile ? options : options.filter(o => locationChecked.indexOf(o.id) === -1);
  locations = sortArrayByKey(locations, 'label', false);
  const [listHeight, setListHeight] = useState('0px');
  
  useEffect(() => {
    let height = window.innerHeight;
    setListHeight(height - 387 +'px')
  }, []);
  
  const locationsMap = locations.map(o => {
    return (
      <Row key={`${title}_${o.id}`} className='mr-0 pt-3 clickable'>
        <Col>
          <CustomInput id={`${title}_${o.id}`} label={o.label} type='checkbox' checked={locationChecked.some(cc => cc === o.id)} onChange={()=>props.checkLocation(o)} />
        </Col>
      </Row>
    )
  });

  return (
     <Col className='px-0 overflow-auto border text-size-14 line-height-16'>
        <Col className='pr-0' style={isMobile ? {height: '160px'} : { height: listHeight }}>
          {locationsMap}
        </Col>
      </Col>
  )
}

export default InviteUserLocation;
