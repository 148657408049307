import React, { useEffect } from 'react';
import useModal from '../../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import useRebateChecks from '../useRebateChecks';
import { IoMdDownload } from "react-icons/io";
import { useSelector } from 'react-redux';


const RebateCheckModal = ({ id, check }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });
  const { getCheckPic, checkPicUrl } = useRebateChecks.useModal();
  const timeframe = useSelector(({ rebateChecks }) => rebateChecks.timeframe);

  useEffect(() => {
    getCheckPic(check);
    // eslint-disable-next-line
  }, [])


  const handleDownload = () => {
    if (checkPicUrl) {
      const fileName = `${timeframe} - Voucher - ${check.accountname}`;

      const link = document.createElement('a');
      link.href = checkPicUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  };
  if (!isMobile) {
    modalProps.className = 'img-view-upload-modal-wrapper';
    modalProps.contentClassName = 'img-view-upload-modal';
  }


  return (
    <Modal {...modalProps}>
      <ModalHeader {...headerProps}>Rebate Voucher</ModalHeader>
      <ModalBody style={{ maxHeight: '80vh', overflowY: 'scroll' }} className={`${isMobile ? 'px-1' : ''} position-relative`} >
        <iframe title="check-pdf" src={checkPicUrl ? checkPicUrl : undefined} style={{ width: '100%', minHeight: '580px' }} type="application/pdf" />
        <div onClick={handleDownload} className='position-absolute download-hover d-flex justify-content-center align-items-center' style={{ right: 122, top: 39, width: '37px', height: '37px', borderRadius: '100%' }} >
          <IoMdDownload size="22" color="#F1F1F1" className='' />
        </div>
      </ModalBody>
      <ModalFooter className='justify-content-end'>
      </ModalFooter>
    </Modal>
  )
}

export default RebateCheckModal;