import Axios from "axios";

const url = '/invoice';

// post body:
// {
//   imageFile: <file>
//   merchant: "Pavel's Diner"
//   description: "invoice from Sysco"
// }
const invoiceApi = {
  post: (params, config, id = null) => {
    let urlStr = id!==null ? url + '/' + id : url;
    return Axios.post(`${urlStr}`, params, config);
  },
  get: (params, id = null) => {
    let urlStr = id!==null ? url + '/' + id : url;
    return Axios.get(`${urlStr}`, params);
  },
  getImages: (params, locationId, imageId) => {
    let urlStr = url + '/image/' + imageId;
    return Axios.get(`${urlStr}`, params);
  },
  deleteImage: (params, invoiceId, imageId) => {
    let urlStr = url + '/' + invoiceId + '/image/' + imageId;
    return Axios.delete(urlStr, params);
  }, 
  delete: (id) => {
    return Axios.delete(`${url}/${id}`,);
  }
};

export default invoiceApi;