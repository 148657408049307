import React from 'react';
import { useHistory } from 'react-router-dom';
import IntelGraphCard from './IntelGraphCard';
import { useSelector, useDispatch } from 'react-redux';
import { randomColors } from '../../../../config/constants';
import numeral from 'numeral';
import sortArrayByKey from '../../../../sharedComponents/Helper/sortArrayByKey';
import { setGridPage } from '../../../../reducers/IntelReducer/IntelActions';

const useApi = () => {
  const dispatch = useDispatch();
  const merchantsList = useSelector(({intel}) => intel.merchants);
  const search = useSelector(({intel}) => intel.searchApply);
  const allLoaded = useSelector(({intel}) => intel.allLoaded);
  const page = useSelector(({intel}) => intel.gridOptions.page);
  const sortState = useSelector(({intel}) => { return { sortBy: intel.listOptions.sortBy, sortDesc: intel.listOptions.sortDesc }});

  const filterMap = sortArrayByKey(
    merchantsList, 
    sortState.sortBy, 
    sortState.sortDesc).filter(m => m.merchant.toLowerCase().search(search.toLowerCase()) !== -1);


  const intelMap = filterMap.slice(0, 8*page).map(mer => (
    <IntelGraphCard key={`merchant-${mer.id}-graph`} {...mer} name={mer.merchant} />
  ));

  const loadMoreGraph = () => {
    if (allLoaded) {
      dispatch(setGridPage(page+1));
    }
  };

  return {
    intelMap, loadMoreGraph,
    showMore: intelMap.length !== filterMap.length
  };
};

const useCard = ({id, noData, data}) => {
  const history = useHistory();

  const chartOptions = {
    chart: {
      height: '70%'
    },
    credits: {
      enabled: false
    },
    title: {
      text: noData ? 'No Data Available' : '',
      floating: true,
      verticalAlign: 'middle',
      style: {
        color: '#202020',
        opacity: 0.5
      }
    },
    xAxis: {
      categories: ['A', 'B', 'C'],
    },
    series: [
      { 
        type: 'pie',
        data: noData ? [{y: 100, color: '#F0F0F2'}] : data.map(d => {
          return {
            name: d.distributor,
            y: d.volume,
            color: randomColors[d.distributorId % randomColors.length]
          }
        })
      }
    ],
    tooltip: {
      enabled: noData ? false : true,
      backgroundColor: '#000000',
      borderColor: '#000000',
      borderRadius: 5,
      followTouchMove: false,
      followPointer: false,
      formatter: function() {
        return '<div><span style="color: #ffffff;">'+this.point.name+': </span><span style="color: #ffffff;">'+numeral(this.y).format('$0,0.00')+'</span></div>';
      }
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
        states: {
          hover: {
            enabled: noData ? false : true,
          },
          inactive: {
            opacity: noData ? 1 : 0.5
          }
        }
      },
      series: {
        animation: false
      }
    }
  };
    
  const goToSeeDetails = () => {
    history.push(`/intel/detail/${id}/competitive`);
  };

  return { chartOptions, goToSeeDetails }
};

export default {
  useCard, useApi
};