import React, { useState } from 'react';
import { IoMdExit } from 'react-icons/io';
import { AiOutlineUserSwitch } from 'react-icons/ai'
// import { BiUserCircle, BiHelpCircle } from 'react-icons/bi';
import { BiUserCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Row, Col, Tooltip } from 'reactstrap';
import useTopBar from './useTopBar';
import LocalStorage from '../LocalStorage/LocalStorage';
import { withMsal } from '@azure/msal-react';

const TopBarOptions = (props) => {
  // const { goToSettings, logOut, goToSelectDistributor, goToFaq, goToSelectUser } = useTopBar.useLinks();
  const { goToSettings, logOut, goToSelectDistributor, goToSelectUser } = useTopBar.useLinks();
  // const userName = useSelector(({ user }) => user.name);
  // const admin = LocalStorage.adminUserData.get();
  // const adminUsername = admin ? admin.name : null;
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
  const menuExpanded = useSelector(({ui}) => ui.menuExpanded);
  const groupUserActive = LocalStorage.groupUserActive.get();
  const [tooltipOpen, setTooltipOpen] = useState({
    userTooltip: false,
    switchDistTooltip: false,
    switchUserTooltip: false,
    logoutTooltip: false,
    helpTooltip: false
  });
  const toggle = (type) => setTooltipOpen({ ...tooltipOpen, [type]: !tooltipOpen[type] });

  return (
    <Row className='mx-0 px-0 mb-4'>
      <Col id={'tooltip-user-icon'} className={'responsive-sidebar-icon m-0 d-flex justify-content-center '} onClick={goToSettings} >
        <BiUserCircle />
        <Tooltip
          placement={!menuExpanded ? 'right' : 'top'}
          isOpen={tooltipOpen.userTooltip}
          target={'tooltip-user-icon'}
          toggle={() => toggle('userTooltip')}>
          {'User'}
        </Tooltip>
      </Col>
      {isInternalAdmin ? <Col id={'tooltip-switch-dist-icon'} className={'responsive-sidebar-icon m-0 d-flex justify-content-center '} onClick={() => goToSelectDistributor(props.msalContext.instance)}>
        <AiOutlineUserSwitch />
        <Tooltip
          placement={!menuExpanded ? 'right' : 'top'}
          isOpen={tooltipOpen.switchDistTooltip}
          target={'tooltip-switch-dist-icon'}
          toggle={() => toggle('switchDistTooltip')}>
          {'Switch Distributor'}
        </Tooltip> 
      </Col> : false}
      {/* <Col id={'tooltip-help-icon'} className={'responsive-sidebar-icon m-0 d-flex justify-content-center '} onClick={goToFaq}>
        <BiHelpCircle />
        <Tooltip
          placement={!menuExpanded ? 'right' : 'top'}
          isOpen={tooltipOpen.helpTooltip}
          target={'tooltip-help-icon'}
          toggle={() => toggle('helpTooltip')}>
          {'Help'}
        </Tooltip>
      </Col> */}
      {groupUserActive ? <Col id={'tooltip-switch-user-icon'} className={'responsive-sidebar-icon m-0 d-flex justify-content-center '} onClick={() => goToSelectUser()}>
        <AiOutlineUserSwitch />
        <Tooltip
        placement={!menuExpanded ? 'right' : 'top'}
        isOpen={tooltipOpen.switchUserTooltip}
        target={'tooltip-switch-user-icon'}
        toggle={() => toggle('switchUserTooltip')}>
        {'Switch User'}
      </Tooltip>
      </Col> : false}
      <Col id={'tooltip-logout-icon'} className={'responsive-sidebar-icon m-0 d-flex justify-content-center '} onClick={logOut}>
        <IoMdExit />
        <Tooltip
          placement={!menuExpanded ? 'right' : 'top'}
          isOpen={tooltipOpen.logoutTooltip}
          target={'tooltip-logout-icon'}
          toggle={() => toggle('logoutTooltip')}>
          {'Log Out'}
        </Tooltip>
      </Col>
    </Row>
  );
};

export default withMsal(TopBarOptions);
