import React from "react";
import { Card, Row, Spinner, Col, Container, Button } from 'reactstrap';
import { If, Then, Else } from 'react-if'
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import UploadedInvoiceItemsRow from "./Subcomponents/UploadedInvoiceItemsRow";
import UploadedInvoiceItemsSortHeader from "./Subcomponents/UploadedInvoiceItemsSortHeader";
import InputSearch from "../../../sharedComponents/InputSearch/InputSearch";
import { BsSearch } from 'react-icons/bs';
import { colors } from "../../../config/constants";
import sortArrayByKey from "../../../sharedComponents/Helper/sortArrayByKey";
import useInvoice from "./useInvoice";

const UploadedInvoiceItems = () => {
  const { id } = useParams();
  const { isInternalAdmin, error, invoiceItems, invoiceInfo, itemSearch, setItemSearch, sortState, breadCrumbs, sortBy} = useInvoice.useUploadedInvoiceItems(id);
  const filteredInvoiceItems = invoiceItems.filter(item => {
    const brand = item.brand && item.brand !== null ? item.brand.toLowerCase() : '';
    const description = item.description && item.description !== null ? item.description.toLowerCase() : '';
    return description.includes(itemSearch.toLowerCase()) || brand.includes(itemSearch.toLowerCase());
  });
  const sortedAndFilteredInvoiceItems = sortArrayByKey(filteredInvoiceItems, sortState.sortBy, sortState.sortDesc);

  const {exportInvoiceItemsList} = useInvoice.useCsvExport(sortedAndFilteredInvoiceItems)



  const itemsMap = sortedAndFilteredInvoiceItems.map((item, ind) => {
    return <UploadedInvoiceItemsRow key={item.id + ind} {...item} />
  })



  if (invoiceInfo && invoiceInfo.status) {
    return (
      <Container fluid className='bg-grayBg flex-grow-1 p-0 pl-4 px-3'>
        {id ? <Row className={`pl-3 py-3`}>{breadCrumbs()}</Row> : false}
        <Row className={`m-0 mt-2 pl-0 main-header text-size-20 d-flex align-items-center`}>
          {invoiceInfo.description ? invoiceInfo.description : false}
     
        </Row>
        <Row className="mb-3">
          <Col className={`text-size-18 m-0 mt-2 align-items-end justify-content-between ${isMobile ? '' : 'd-flex flex-row'}`}>
            <Col className={`px-0 m-0`} xs={isMobile ? 12 : 3}>
              <InputSearch value={itemSearch} onChange={(e) => setItemSearch(e.target.value)} placeholder="Search Invoice Items" />
            </Col>
            <Col className={`px-0 justify-content-end ${isMobile ? 'mt-2' : 'd-flex'}`}>
                <If condition={isInternalAdmin}>
                  <Button type="button" color="beplMagenta" onClick={exportInvoiceItemsList} className="py-2 px-2 btn btn-beplMagenta btn-sm button-label" style={{ float: 'right' }}>Export Page Details</Button>
                </If>
              </Col>
          </Col>
        </Row>
        {!isMobile &&
          <Col className="mt-3 w-100 p-0"> <UploadedInvoiceItemsSortHeader sortBy={sortBy} sortState={sortState} /></Col>}
        <Card className="mb-5">
          <If condition={invoiceItems.length > 0}>
            <Then>
              {itemsMap}
            </Then>
            <Else>
              <If condition={error !== null}>
                <Then>
                  <Row style={{ position: 'relative' }}>
                    <Col xs={12} className='text-center p-5'>
                      <BsSearch color={colors.beplMagenta} size={45} />
                      <div className='mt-3 main-header text-center'>{error}</div>
                    </Col>
                  </Row>
                </Then>
                <Else>
                  <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>
                </Else>
              </If>
            </Else>
          </If>
        </Card>
      </Container >
    );
  } else {
    return (
      <Row style={{ position: 'relative' }}>
        <Col xs={12} className='text-center p-5'>
          <BsSearch color={colors.beplMagenta} size={45} />
          <div className='mt-3 main-header text-center'>{error}</div>
        </Col>
      </Row>
    )
  }


}

export default UploadedInvoiceItems;