import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { showModal } from "../../reducers/ModalsReducer/ModalsActions";
import AlertModal from "../../sharedComponents/AlertModal/AlertModal";
import announcementApi from "../../api/announcementApi";

const useAnnouncements = () => {
  const dispatch = useDispatch();
  const [announcements, setAnnouncements] = useState([]);
  const [announcementInput, setAnnouncementInput] = useState('');

  const refresh = () => {
    announcementApi.get().then((res) => {
      setAnnouncements(res.data);
    }).catch((error) => {
      console.log(error);
      dispatch(showModal(AlertModal, {title: "Couldn't load announcements - see console for details."}));
    });
  }

  const onCreate = () => {
    announcementApi.post({ text: announcementInput }).then(() => {
      refresh();
      setAnnouncementInput('');
      dispatch(showModal(AlertModal, {title: "Announcement Created!"}));
    }).catch((error) => {
      console.log(error);
      dispatch(showModal(AlertModal, {title: "Couldn't create announcement - see console for details."}));
    });
  }

  const onDelete = (announceId) => {
    announcementApi.delete(announceId).then(() => {
      refresh();
      dispatch(showModal(AlertModal, {title: "Announcement Deleted!"}));
    }).catch((error) => {
      console.log(error);
      dispatch(showModal(AlertModal, {title: "Couldn't delete announcement - see console for details."}));
    });
    refresh();
  }

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  return {
    announcements,
    announcementInput,
    setAnnouncementInput,
    onCreate,
    onDelete
  };
}

export default useAnnouncements;