import React, { useEffect } from 'react';
import useModal from '../../../../sharedComponents/useModal/useModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Input, Label, Alert } from 'reactstrap';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import UploadFileListItem from './UploadFileListItem';

import useUploadModal from './useUploadModal';

const InvoiceUploadModal = ({ id, invoiceId = null, invoiceLocationProp = null, invoiceNameProp = null, reupload }) => {
  const { modalProps, headerProps } = useModal(id, { isMenu: true, isFullscreen: true });
  const mobileDescription = 'Click the "Upload Images" to upload images of your invoice.';
  const desktopDescription = 'Click the "Upload Images" button or drag and drop within dashed area to upload your invoice.'
  // const uploadFiles = useSelector(({ invoices }) => invoices.invoiceUploadFiles);
  const {
    uploadImages,
    uploadFiles,
    formatBytes,
    clearImage,
    clearImages,
    saveFilesToState,
    invoiceName,
    invoiceLocation,
    setInvoiceName,
    setInvoiceLocation,
    merchants,
    uploadStatus,
    setUploadModalStatus,
    uploadProgressPercenteges,
    failedUploads } = useUploadModal();



  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    window.addEventListener("dragover", function (e) {
      e.preventDefault();
    }, false);
    window.addEventListener("drop", function (e) {
      e.preventDefault();
    }, false);
    if (reupload) {
      setInvoiceName(invoiceNameProp);
      setInvoiceLocation(invoiceLocationProp);

    }
    // eslint-disable-next-line
  }, []);

  const visibleValues = merchants.map(val => {
    return { label: val.merchant, value: val.merchant };
  });

  const uploadSingleImage = (image) => {
    uploadImages([image], invoiceLocation, invoiceName);
  }
  const uploadFilesList = uploadFiles.map((file) => {
    let failed = false;
    let exists = failedUploads.findIndex(failedFile => failedFile.name === file.name);
    failed = exists !== -1 ? true : false;
    return <UploadFileListItem failed={failed} uploadImage={uploadSingleImage} file={file} progress={uploadProgressPercenteges[file.name]} key={file.name} clearImage={clearImage} name={file.name} size={formatBytes(file.size, 2)} />
  });
  if(!isMobile) {
    modalProps.className = 'invoice-upload-modal-wrapper';
    modalProps.contentClassName = 'invoice-upload-modal';
  }



  return (
    <Modal  {...modalProps}>
      <ModalHeader {...headerProps} >Invoice Upload</ModalHeader>
      <ModalBody className={`${isMobile ? 'px-2' : ''} pb-0`} >
        <>
          <Row className="m-0 my-3">
            <Col className="p-0 pr-2" xs={6}>
              <Label htmlFor="invoice-name">Invoice Name</Label>
              <Input value={invoiceName} disabled={invoiceLocationProp !== null} onChange={(e) => setInvoiceName(e.target.value)} id="invoice-name"></Input>
            </Col>
            <Col className="p-0 pl-2 position-relative" xs={6}>
              <Label htmlFor="invoice-location">Location</Label>
              <Select id="invoice-location"
                isDisabled={invoiceLocationProp !== null}
                options={visibleValues}
                onChange={(obj) => { setInvoiceLocation(obj) }}
                onInputChange={(input, params) => {
                  if (params.action === 'input-change') {
                    setInvoiceLocation({ value: input, label: input });
                  }
                }
                }
                value={invoiceLocation}
              />
            </Col>
          </Row>
          <div onDrop={saveFilesToState} className={`d-flex justify-content-center border-dashed-three p-5 m-0 ${isMobile ? 'mt-4' : 'mt-2'}`}>
            <Col className="text-center d-flex flex-column align-items-center">
              <Row className="text-size-18 m-0">{isMobile ? mobileDescription : desktopDescription}</Row>
              <Row className="text-gray text-size-12 m-0">Support png, jpg, gif files. Max file size is 10mb.</Row>
              <Input onClick={(e) => e.target.value = ''} onChange={saveFilesToState} multiple id="upload-images-input" type="file" name="Invoice Upload" style={{ display: 'none' }} />
              <Label htmlFor="upload-images-input" color="beplMagenta" className="btn btn-beplMagenta btn-sm button-label mt-5 py-2 px-2">Upload Images</Label>
            </Col>
          </div>

          {uploadStatus !== null
            ? <Alert toggle={() => setUploadModalStatus(null)} color={uploadStatus === 'success' ? 'success' : uploadStatus === 'partial-success' ? 'warning' : 'danger'} className="w-100 d-flex justify-content-center mt-2">
              {uploadStatus === 'success' ? 'Successfully Uploaded Invoice!' : uploadStatus === 'partial-success' ? 'Some Of Your Images Failed to Upload.' : uploadStatus}</Alert>
            : false}
          {uploadFiles.length > 0 ?
            <>

              <Col className="p-0">
                {uploadFilesList}
              </Col>
            </> : false}
        </>
      </ModalBody>
      <ModalFooter className='justify-content-end'>
        <Row className={`m-0 d-flex justify-content-between align-items-center ${isMobile ? 'flex-column mt-2' : 'flex-row mt-3'}`}>
          <Row className={`m-0 d-flex  ${isMobile ? 'w-100 justify-content-end mt-4' : ''}`}>
            {failedUploads.length > 0 ?
              <Button color="beplMagenta" onClick={() => uploadImages([...failedUploads, ...uploadFiles], invoiceLocation.value, invoiceName, invoiceId, reupload)} className="btn btn-beplMagenta btn-sm button-label py-2 px-2 align-self-start mr-2">
                Retry</Button> :
              <Button color="beplMagenta" onClick={() => uploadImages(uploadFiles, invoiceLocation.value, invoiceName, invoiceId, reupload)} className="btn btn-beplMagenta btn-sm button-label py-2 px-2 align-self-start mr-2">
                Send Images</Button>
            }
            <Button color="beplMagenta" onClick={clearImages} className="btn btn-beplMagenta btn-sm button-label py-2 px-2 align-self-start">Clear Images</Button>
            {/*attach delete function to images that were sent successfully if some failed*/}
          </Row>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default InvoiceUploadModal;