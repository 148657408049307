import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import sortArrayByKey from '../../../../sharedComponents/Helper/sortArrayByKey';
import { setItemMatchesSort, setItemMatches, setFilteredSortedItemMatches, setItemMatchesForAllItems, itemMatchesLoading, setScrollYPos, } from '../../../../reducers/RebateReducer/RebateActions';
import matchApi from '../../../../api/matchApi';
import { showModal } from '../../../../reducers/ModalsReducer/ModalsActions';
import MatchFeedbackModal from '../../CompetitiveInsight/Subcomponents/Modals/ItemMatchesFeedback';
import stringify from 'csv-stringify';
import fileDownload from 'js-file-download';
import numeral from 'numeral';


const useApi = () => {
  const fromDate = useSelector(({ intel }) => intel.fromDate);
  const toDate = useSelector(({ intel }) => intel.toDate);
  const itemMatches = useSelector(({ rebate }) => rebate.itemMatches);
  const sortState = useSelector(({ rebate }) => rebate.rebatesItemMatchesSort);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const itemMatchesForAllItems = useSelector(({ rebate }) => rebate.itemMatchesForAllItems);
  const history = useHistory();
  const activeDistributor = useSelector(({ user }) => user.activeDistributor);
  const { min, manPlatId, merchId, itemName } = useParams();
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);

  const showMatchFeedbackModal = () => {
    dispatch(showModal(MatchFeedbackModal, { feedbackType: 'recommendation', min }));
  }

  const saveScrollPosition = () => {
    if (window) {
      dispatch(setScrollYPos(window.pageYOffset))
    }
  }

  const processData = (data) => {
    let filtered = data.filter(item => {
      let min = item.min ? item.min.toLowerCase() : '';
      let description = item.description ? item.description.toLowerCase() : '';
      let manufacturer = item.manufacturerName ? item.manufacturerName.toLowerCase() : '';
      return min.includes(search.toLowerCase()) || description.includes(search.toLowerCase()) ||
        manufacturer.includes(search.toLowerCase());
    });

    let sorted = sortArrayByKey(
      filtered,
      sortState.sortBy,
      sortState.sortDesc
    );
    return sorted;
  }
  const getItemMatches = (min, manufacturerPlatformId, merchantPlatformId) => {
    // if the matches are already shown for this item, hide them.
    // otherwise, fetch the matches and display them.
    saveScrollPosition();
    const savedMatch = itemMatchesForAllItems.find((m) =>
      m.min === min &&
      m.manufacturerPlatformId === manufacturerPlatformId
    );


    if (savedMatch) {
      // no need to fetch item matches again if we already have them for this min
      let filteredAndSorted = processData(savedMatch.matches);
      dispatch(setItemMatches(savedMatch.matches));
      dispatch(setFilteredSortedItemMatches(filteredAndSorted));
    }
    else {
      dispatch(itemMatchesLoading('loading'));
      matchApi.post({
        min,
        manufacturerPlatformId,
        merchantPlatformId,
        startDate: fromDate,
        endDate: toDate
      }).then((res) => {
        dispatch(itemMatchesLoading('complete'));
        let filteredAndSorted = processData(res.data);
        // dispatch(setItemForMatches({min, manufacturerPlatformId, merchantPlatformId}));
        dispatch(setItemMatches(res.data));
        dispatch(setFilteredSortedItemMatches(filteredAndSorted));
        dispatch(setItemMatchesForAllItems(min, manufacturerPlatformId, res.data));
      }).catch(err => {
        dispatch(itemMatchesLoading('failed'));
        console.error(err);
      });
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  }
  const clearValueSearch = () => {
    setSearch('');
  }
  const sortBy = (key) => {
    let order = key === sortState.sortBy ? !sortState.sortDesc : true;
    order = ((key === 'description' && key !== sortState.sortBy) || (key === 'manufacturerName' && key !== sortState.sortBy)) ? !order : order;
    dispatch(setItemMatchesSort(key, order));
  };


  const breadCrumbs = () => {
    return (
      <div className="d-flex text-sm font-weight-bold">
        <div onClick={() => history.push('/rebates/list')} className="pr-1 clickable">{activeDistributor.nickname}</div>
        <div className="px-1 clickable text-beplBlue">{'>'}</div>
        <div className="px-1 clickable text-beplMagenta">{'Similar Items (' + min + ')'}</div>
      </div>
    )
  }

  useEffect(() => {
    let filteredAndSorted = processData(itemMatches);
    dispatch(setFilteredSortedItemMatches(filteredAndSorted));
    // eslint-disable-next-line
  }, [sortState, search]);

  return {
    search,
    clearValueSearch,
    onChangeSearch,
    sortBy,
    sortState,
    getItemMatches,
    breadCrumbs,
    itemName,
    min,
    manPlatId,
    merchId,
    showMatchFeedbackModal,
    isInternalAdmin
  }
}
const useCsvExport = () => {
  const itemMatchesList = useSelector(({ rebate }) => rebate.filteredSortedItemMatches)
  const { min } = useParams();
  const originalMin = min;
  let prepareItemsList = (data) => {
    let csvData = [];
    csvData.push(['originalMin', 'min', 'description', 'manufacturer', 'pack', 'rebateType',
      'rebate', 'deviation', 'uom']);

    data.forEach(({ min, description, manufacturerName, pack,
      rebateType, rebateWithSymbol, deviationDollar, uom }) => {
      csvData.push([
        originalMin, min, description, manufacturerName, pack, rebateType,
        numeral(rebateWithSymbol).format('0,0.00'), numeral(deviationDollar).format('0,0.00'), uom
      ])
    });
    return csvData;
  }

  const exportCsv = (data, fileName) => {
    let preCsvArray = prepareItemsList(data);
    if (preCsvArray.length > 0) {
      stringify(preCsvArray, (err, output) => {
        fileDownload(output, fileName);
      });
    }
  }

  const exportItemMatchesList = () => {
    let data = itemMatchesList;
    if (data.length > 0) {
      exportCsv(data, 'ItemMatches - For internal use only.csv');
    }
  }
  return {
    exportItemMatchesList
  }
}

export default {
  useCsvExport,
  useApi
}