import React from 'react';
import { Card, Container } from 'reactstrap';
import useIntelDetailCompetitive from './useIntelDetailCompetitive';
import IntelDetailCompetitiveHeader from './Subcomponents/IntelDetailCompetitiveHeader';
import { If, Then, Else } from 'react-if';
import { isMobile } from 'react-device-detect';
import NoDataRow from '../../../../../sharedComponents/NoDataRow/NoDataRow';
import LoadingRow from '../../../../../sharedComponents/LoadingRow/LoadingRow';

const IntelDetailCompetitive = ({match}) => {
  const { loading, detailsListMap, ...header } = useIntelDetailCompetitive.useApi(match.params.id);

  return (
    <Container fluid className={isMobile ? 'pb-3 px-0 mt-3' : 'pb-3'}>
      <Card>
        <IntelDetailCompetitiveHeader {...header} />
        <If condition={loading}>
          <Then>
            <LoadingRow />
          </Then>
          <Else>
            <If condition={detailsListMap.length > 0}>
              <Then>
                {detailsListMap}
              </Then>
              <Else>
                <NoDataRow />
              </Else>
            </If>
          </Else>
        </If>
      </Card>
    </Container>
  );
};

export default IntelDetailCompetitive;
