import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from '../src/views/App/App';
import store from '../src/reducers/store';
import './config/scss/combinedScss.scss';
import * as $ from 'jquery';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/msAuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);

window.jQuery = window.$ = $;

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <MsalProvider instance={msalInstance}><App /></MsalProvider>
    </HashRouter>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
