import React, { useState } from 'react';
import { Collapse, CardBody, Card, Col, Row } from 'reactstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';

function QuestionListItem(args) {
  const [isOpen, setIsOpen] = useState(false);
  const { title, content } = args;
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Col className="m-0 p-0">
      <Card>
        <Row className="m-0 p-3 justify-content-between align-items-center reveal-label">
          {title}
          {isOpen ? <FaMinus onClick={toggle} color="beplMagenta" className='clickable text-beplMagenta' /> : <FaPlus onClick={toggle} color="beplMagenta" className='clickable text-beplMagenta' />}
        </Row>
        <Collapse isOpen={isOpen} {...args}>
          <CardBody className="px-3 pt-0 pb-3">
            {content}
          </CardBody>
        </Collapse>
      </Card>
    </Col>
  );
}

export default QuestionListItem;