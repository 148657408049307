export const spinner = {
  add: (showGraphic = true) => {
    return {
      type: 'ADD_SPINNER',
      payload: showGraphic
    }
  },
  subtract: () => {
    return {
      type: 'SUBTRACT_SPINNER',
    }
  },
};

export const toggleBurgerMenu = () => {
  return {
    type: 'TOGGLE_BURGER_MENU'
  }
}

export const setLastViewedCompInsightUrl = (url) => {
  return {
    type: 'SET_LAST_VIEWED_COMP_INSIGHT_URL',
    payload: url
  }
}

export const setLastViewedIntelUrl = (url) => {
  return {
    type: 'SET_LAST_VIEWED_INTEL_URL',
    payload: url
  }
}

export const clearAllUiData = () => {
  return {
    type: 'CLEAR_ALL_UI_DATA'
  }
}

export const setMenuExpanded = (flag) => {
  return {
    type: 'SET_MENU_EXPANDED',
    payload: flag
  }
}