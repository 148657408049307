import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import InvoiceScanning from './InvoiceScanning';
import UploadedInvoice from './UploadedInvoice';
import UploadedInvoiceItems from './UploadInvoiceItems';

const Invoice = () => {

  return (
      <Switch>
        <Route exact path='/invoice/scanning' component={InvoiceScanning} />
        <Route exact path='/invoice/:id' component={UploadedInvoice} />
        <Route exact path='/invoice/:id/items' component={UploadedInvoiceItems} />

        <Route>
          <Redirect to='/invoice/scanning' />
        </Route>
      </Switch>
  );
}

export default Invoice;