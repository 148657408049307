const authKey = {
  get: () => {
    const authKey = localStorage.getItem('authKey') ;
    return authKey ? authKey : null
  },
  set: (value) => {
    localStorage.setItem('authKey', value);
  }
};

const resetKey = {
  get: () => {
    const resetKey = localStorage.getItem('resetKey') ;
    return resetKey ? resetKey : null
  },
  set: (value) => {
    localStorage.setItem('resetKey', value);
  }
};

const userData = {
  get: () => {
    const userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null
  },
  set: (value) => {
    localStorage.setItem('userData', JSON.stringify(value));
  }
};

const adminUserData = {
  get: () => {
    const adminUserData = localStorage.getItem('adminUserData');
    return adminUserData ? JSON.parse(adminUserData) : null
  },
  set: (value) => {
    localStorage.setItem('adminUserData', JSON.stringify(value));
  }
};

const groupUserActive = {
  get: () => {
    const groupUserActive = localStorage.getItem('groupUserActive');
    return groupUserActive ? JSON.parse(groupUserActive) : null
  },
  set: (value) => {
    localStorage.setItem('groupUserActive', JSON.stringify(value));
  },
  delete: () => {
    localStorage.removeItem('groupUserActive');
  }
};
const clear = () => {
  localStorage.removeItem('authKey');
  localStorage.removeItem('resetKey');
  localStorage.removeItem('userData');
  localStorage.removeItem('adminUserData');
  localStorage.removeItem('groupUserActive');

};

const LocalStorage = {
  authKey,
  resetKey,
  userData,
  adminUserData,
  groupUserActive,
  clear
};

export default LocalStorage;